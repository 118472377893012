import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import UsuarioContext from "../../contextos/UsuarioContext";
import { chequearPermisos } from "../../funciones/Funciones";
import { makeStyles } from "@mui/styles";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import RoomPreferencesOutlinedIcon from "@mui/icons-material/RoomPreferencesOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import UserConfig from "./UserConfig";
import ObraContext from "../../contextos/ObraContext";
import EquipoConfig from "./EquipoConfig";
import AlertValidConfig from "../../components/AlertValidConfig";
import InvitacionesConfig from "./InvitacionesConfig";
import { InvitadosProvider } from "../../contextos/InvitadosContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  linkStyle: {
    color: "white",
    textDecoration: "none",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && !other.disabled && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const BotoneraConfig = () => {
  const classes = useStyles();

  const theme = useTheme();

  const { usuario, setUsuario } = useContext(UsuarioContext);

  const { obra, setObra } = useContext(ObraContext);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="icon label tabs example"
      >
        <Tab
          icon={<PersonOutlineOutlinedIcon />}
          label="USUARIO"
          disabled={
            chequearPermisos(usuario.Privilegios, "CONFIGURACION_USUARIO") ===
            true
              ? false
              : true
          }
        />

        <Tab
          icon={<PeopleAltOutlinedIcon />}
          label="EQUIPO"
          disabled={
            chequearPermisos(usuario.Privilegios, "CONFIGURACION_EQUIPO") ===
              true &&
            obra.Privilegios !== undefined &&
            obra.EsAdmin === "1"
              ? false
              : true
          }
        />

        <Tab
          icon={<GroupAddOutlinedIcon />}
          label="INVITACIONES"
          disabled={
            chequearPermisos(
              usuario.Privilegios,
              "CONFIGURACION_INVITACIONES"
            ) === true && obra.Privilegios !== undefined
              ? false
              : true
          }
        />
      </Tabs>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {chequearPermisos(usuario.Privilegios, "CONFIGURACION_USUARIO") ===
        true ? (
          <TabPanel value={value} index={0} dir={theme.direction}>
            <UserConfig />
          </TabPanel>
        ) : (
          <TabPanel value={value} index={0} dir={theme.direction}>
            No tiene permisos para acceder a esta sección.
          </TabPanel>
        )}

        {chequearPermisos(usuario.Privilegios, "CONFIGURACION_EQUIPO") ===
          true && obra.Privilegios !== undefined ? (
          <TabPanel value={value} index={1} dir={theme.direction}>
            <EquipoConfig />
          </TabPanel>
        ) : (
          <TabPanel value={value} index={1} dir={theme.direction}>
            No tiene permisos para acceder a esta sección.
          </TabPanel>
        )}

        {chequearPermisos(usuario.Privilegios, "CONFIGURACION_INVITACIONES") ===
          true && obra.Privilegios !== undefined ? (
          <TabPanel value={value} index={2} dir={theme.direction}>
            <InvitadosProvider>
              <InvitacionesConfig />
            </InvitadosProvider>
          </TabPanel>
        ) : (
          <TabPanel value={value} index={2} dir={theme.direction}>
            No tiene permisos para acceder a esta sección.
          </TabPanel>
        )}
      </SwipeableViews>
    </div>
  );
};

export default BotoneraConfig;
