import { Checkbox } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Titulo from "../../components/Titulo";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HySContext from "../../contextos/HySContext";
import ObraContext from "../../contextos/ObraContext";
import UsuarioContext from "../../contextos/UsuarioContext";
import { desencriptar } from "../../funciones/Funciones";
import PrivilegioContext from "../../contextos/PrivilegiosContext";
import InvitadosContext from "../../contextos/InvitadosContext";

const PrivilegiosInvitadosConfig = (props) => {
  const [mostrarProgress, setMostrarProgress] = useState(false);
  const [secciones, setSecciones] = useState([]);

  const { obra, setObra } = useContext(ObraContext);
  const { usuario, setUsuario } = useContext(UsuarioContext);

  const initConfig = {
    usuario: true,
    obras: false,
    pedidos: false,
    materiales: false,
    volquetes: false,
    presupuestos: false,
    documentacion: false,
  };

  const [config, setConfig] = useState(initConfig);

  const {
    invitado,
    setInvitado,
    nombre,
    setNombre,
    email,
    setEmail,
    permitidos,
    setPermitidos,
  } = useContext(InvitadosContext);

  const {
    privilegios,
    setPrivilegios,
    mostrarAlert,
    setMostrarAlert,
  } = useContext(PrivilegioContext);

  useEffect(() => {
    listar_secciones();

    // if (props.seccion === "OBRAS") {
    //   listar_secciones_permitidas();
    // }
  }, []);

  useEffect(() => {
    if (permitidos.length === 0) {
      listar_secciones_permitidas();
    }
  }, []);

  useEffect(() => {
    setConfig({ ...config, obras: true });
  }, [privilegios]);

  useEffect(() => {
    if (obra.IDObra === undefined) {
      // console.log("PASA POR IDObra");
      let objobra = desencriptar(localStorage.getItem("objobra"));
      setObra(objobra);
      //   console.log(objobra);
      setPrivilegios(objobra.Privilegios);
    } else {
      setPrivilegios(obra.Privilegios);
    }

    // console.log(privilegios);

    if (usuario.IDUsuario === undefined) {
      // console.log("PASA POR IDObra");
      let objusu = desencriptar(localStorage.getItem("objusu"));
      setUsuario(objusu);
    }
  }, []);

  const listar_secciones = async () => {
    // console.log(lista);

    setMostrarProgress(true);

    // console.log("IDObra: " + obra.IDObra);
    // console.log("IDUsuario: " + usuario.IDUsuario);

    const API_ENDPOINT = `https://backend.bimtracker.com/api_config.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_TODOS_LOS_PRIVILEGIOS",
        // idObra: obra.IDObra,
        // idUsuario: usuario.IDUsuario,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.privilegios) {
      //   console.log(respuesta.result.privilegios);

      setSecciones(respuesta.result.privilegios);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN: listar_secciones()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  const listar_secciones_permitidas = async () => {
    // console.log(lista);

    setMostrarProgress(true);

    // console.log("IDObra: " + obra.IDObra);
    // console.log("IDUsuario: " + usuario.IDUsuario);

    const API_ENDPOINT = `https://backend.bimtracker.com/api_config.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_SECCIONES_PERMITIDAS",
        idObra: obra.IDObra,
        esInvitado: true,
        email: invitado.email,
        // idUsuario: props.invitadoElegido.IDUsuario,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.privilegios) {
      // console.log(respuesta.result.privilegios);
      // setAsignaciones(respuesta.result.asignaciones);

      let arrSeccionesPermitidas =
        respuesta.result.privilegios[0].Privilegios !== null
          ? respuesta.result.privilegios[0].Privilegios.split("|")
          : [];

      setPermitidos(arrSeccionesPermitidas);

      //   console.log("1: props.permitidos ******************");
      //   console.log(
      //     respuesta.result.privilegios[0].Privilegios.split("|").filter((fil) =>
      //       fil.includes("OBRAS")
      //     )
      //   );

      //   console.log("2: usuario.Privilegios ******************");
      //   console.log(usuario.Privilegios);

      //   console.log("3: obra.Privilegios ******************");
      //   console.log(obra.Privilegios);

      //   console.log("4: obra ******************");
      //   console.log(obra);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN: listar_secciones_permitidas()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  const handleChange = async (event, item) => {
    // console.log("checked: " + event.target.checked);
    // console.log(item);
    if (event.target.checked) {
      // setPrivilegiosInvitado([...privilegiosInvitado, item.CodPrivilegio]);

      //   console.log(item.CodPrivilegio);
      setPermitidos([...permitidos, item.CodPrivilegio]);
      //   console.log(props.permitidos);
    } else {
      let arrPermitidos = permitidos;
      const index = permitidos.indexOf(item.CodPrivilegio);
      if (index > -1) {
        // only splice array when item is found
        arrPermitidos.splice(index, 1); // 2nd parameter means remove one item only
      }
      //   console.log("PASA");
      //   console.log(arrPermitidos);

      setPermitidos([...arrPermitidos]);
    }

    //   console.log(obra);

    // alert("Lista de precios actualizada.");
  };

  return (
    <div>
      {secciones.filter((filtro) =>
        filtro.CodPrivilegio.includes(props.seccion + "_")
      ).length > 0 ? (
        <div style={{ marginBottom: "30px", fontSize: "20px" }}>
          Seleccione los privilegios de acceso para la obra{" "}
          <strong>{obra.Obra}</strong>:
        </div>
      ) : (
        <div style={{ marginBottom: "30px", fontSize: "20px" }}>
          No hay secciones disponibles.
        </div>
      )}
      {secciones
        .filter((filtro) =>
          filtro.CodPrivilegio.includes(
            props.seccion + "_"
            // obra.IDUsuarioCreador === usuario.IDUsuario ? "OBRAS" : "OBRAS_"
          )
        )
        .map((seccion) => (
          <div key={seccion.IDPrivilegio}>
            <Checkbox
              id={seccion.IDPrivilegio}
              key={seccion.IDPrivilegio}
              icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
              checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
              checked={permitidos
                .filter((fil) => fil.includes(props.seccion))
                .some((sec) => sec === seccion.CodPrivilegio)}
              onChange={(event) => handleChange(event, seccion)}
            />
            {seccion.Privilegio.toUpperCase()}
          </div>
        ))}
    </div>
  );
};

export default PrivilegiosInvitadosConfig;
