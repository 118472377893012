import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import EstilosContext from "../../contextos/EstilosContext";
import ObraContext from "../../contextos/ObraContext";
import UsuarioContext from "../../contextos/UsuarioContext";
import { desencriptar, encriptar } from "../../funciones/Funciones";
import Navegacion from "./Navegacion";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import MensajesContext from "../../contextos/MensajesContext";
import AlertValidConfig from "../../components/AlertValidConfig";
import { useNavigate } from "react-router-dom";

const PageAgregarPrioridad = (props) => {
  const { obra, setObra } = useContext(ObraContext);
  const { usuario, setUsuario } = useContext(UsuarioContext);
  const { screenWidth } = useContext(EstilosContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } = useContext(
    MensajesContext
  );

  const prioridades = { baja: "BAJA", media: "MEDIA", alta: "ALTA" };

  const [prioridad, setPrioridad] = useState(prioridades.baja);
  const [finNavegacion, setFinNavegacion] = useState(false);
  const [ordenesDeServicio, setOrdenesDeServicio] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    props.setTitulo("Agregar prioridad");
    props.setPagina("");
  }, [props.setTitulo]);

  useEffect(() => {
    let objordserv = desencriptar(localStorage.getItem("objordserv"));
    console.log(objordserv);

    setOrdenesDeServicio(objordserv);
  }, []);

  useEffect(() => {
    // setPaso2(desencriptar(localStorage.getItem("objordserv")));

    let objobra = localStorage.getItem("objobra");

    setObra(desencriptar(objobra));

    if (usuario.IDUsuario === undefined) {
      // console.log("PASA POR IDObra");
      let objusu = desencriptar(localStorage.getItem("objusu"));
      setUsuario(objusu);
      // console.log(objusu);
    } else {
      // console.log(usuario);
    }
  }, []);

  useEffect(() => {
    let paso4 = { ...ordenesDeServicio, prioridad: prioridad };
    localStorage.setItem("objordserv", encriptar(paso4));

    // return () => {
    //   let paso3 = desencriptar(localStorage.getItem("objordserv"));
    //   console.log("paso3", paso3);
    //   let paso4 = { ...paso3, prioridad: prioridad };
    //   localStorage.setItem("objordserv", encriptar(paso4));
    // };
  }, [prioridad]);

  const handleClick = (pri) => {
    setPrioridad(pri);
    let paso4 = { ...ordenesDeServicio, prioridad: pri };
    // localStorage.setItem("objordserv", encriptar(paso4));

    setOrdenesDeServicio(paso4);
  };

  useEffect(() => {
    if (finNavegacion) {
      guardar_orden_de_servicio();

      console.log("objordserv", ordenesDeServicio);
    }
  }, [finNavegacion]);

  const guardar_orden_de_servicio = async () => {
    const API_ENDPOINT = `https://backend.bimtracker.com/api_ordenesDeServicio.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "GUARDAR_ORDEN_DE_SERVICIO",
        idObra: obra.IDObra,
        idUsuario: usuario.IDUsuario,
        archivos: ordenesDeServicio.archivos,
        descripcion: ordenesDeServicio.descripcion,
        destinatarios: ordenesDeServicio.destinatarios,
        prioridad: ordenesDeServicio.prioridad,
        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.respuesta === "OK") {
      //   console.log(respuesta.result.respuesta);

      setMensaje({
        text: "La orden de servicio ha sido enviada!",
        tipo: "success",
      });
      setMostrarMensaje(true);

      setTimeout(() => {
        navigate("/dashboardOrdenesDeServicio");
      }, 3000);

      // setPedido(respuesta.result.pedido);
      // setWhatsapp(respuesta.result.pedido[0]["Whatsapp"]);
      // setIdPedido(respuesta.result.pedido[0]["IDPedido"]);
      // setEncabezadoPedido(respuesta.result.pedido[0]);
      // setMaterialesSeleccionados(respuesta.result.pedido);

      // let ped = respuesta.result.pedido[0];

      // let msgEnvio = "";

      // msgEnvio +=
      //   "Qué tal " +
      //   ped.NombreContacto +
      //   "?, \nTe paso un pedido para *" +
      //   ped.Obra +
      //   " (" +
      //   ped.Cuit +
      //   ")*: \n\n";
      // // msgEnvio += "Cant. Volquetes: " + count + "\n\n";
      // msgEnvio +=
      //   "Fecha: " + formatearFecha(ped.FechaProgramada, "texto") + "\n";
      // msgEnvio += "Hora: " + ped.HoraProgramada + "\n\n";
      // // msgEnvio += "Para ver el pedido ingresá al siguiente link: \n";
      // // msgEnvio +=
      // //   "https://plus.bimtracker.com/confirmarPedido/" +
      // //   pedido.codigoPedido +
      // //   "\n\n";

      // msgEnvio += "*Pedido*: \n";

      // respuesta.result.pedido.map(
      //   (item, index) =>
      //     (msgEnvio +=
      //       item.Cantidad + " " + item.Unidad + " de " + item.Material + "\n")
      // );

      // msgEnvio += "\n";
      // msgEnvio += "\n";

      // msgEnvio += "Pedido enviado desde https://plus.bimtracker.com";

      // setMensajeWhatsappEnvio(msgEnvio);

      // let msgReenvio = "";

      // msgReenvio += "Qué tal " + ped.NombreContacto + "?, \n";

      // msgReenvio += "Para la obra *" + ped.Obra + " (" + ped.Cuit + ")*: \n\n";

      // msgReenvio +=
      //   "Quedó pendiente lo siguiente del pedido #" + ped.IDPedido + "\n";
      // msgReenvio +=
      //   "Fecha: " + formatearFecha(ped.FechaProgramada, "texto") + "\n";
      // msgReenvio += "Hora: " + ped.HoraProgramada + "\n\n";

      // // msgReenvio += "Para ver el pedido ingresá al siguiente link: \n";
      // // msgReenvio +=
      // //   "https://plus.bimtracker.com/confirmarPedido/" +
      // //   pedido.codigoPedido +
      // //   "\n\n";

      // msgReenvio += "*Pendiente*: \n";

      // respuesta.result.pedido
      //   .filter((mat) => mat.Estado !== "ENTREGA_COMPLETA")
      //   .map((item, index) =>
      //     item.Estado === "NO_ENTREGO"
      //       ? (msgReenvio +=
      //           item.Cantidad +
      //           " " +
      //           item.Unidad +
      //           " de " +
      //           item.Material +
      //           "\n")
      //       : (msgReenvio +=
      //           item.Cantidad -
      //           item.CantidadRecibida +
      //           " " +
      //           item.Unidad +
      //           " de " +
      //           item.Material +
      //           "\n")
      //   );

      // msgReenvio += "\n";
      // msgReenvio += "\n";

      // msgReenvio += "Pedido enviado desde https://plus.bimtracker.com";

      // setMensajeWhatsappReenvio(msgReenvio);

      // setParamsWhatsapp([
      //   {
      //     whatsapp: ped.Whatsapp,
      //     mensajeWP: msgEnvio,
      //     pedido: respuesta.result.pedido,
      //     setMostrarMensaje: setMostrarMensaje,
      //     setMensaje: setMensaje,
      //     tipo: "ENVIAR_PEDIDO",
      //   },
      //   {
      //     whatsapp: ped.Whatsapp,
      //     mensajeWP: msgReenvio,
      //     pedido: respuesta.result.pedido,
      //     setMostrarMensaje: setMostrarMensaje,
      //     setMensaje: setMensaje,
      //     tipo: "REENVIAR_PEDIDO",
      //   },
      // ]);
    } else {
      console.log("NO TRAE NADA EN guardar_orden_de_servicio()");
      // setState([]);
    }
  };

  return (
    <>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          // border: "1px solid #CCCCCC",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent:
              screenWidth === "md" ||
              screenWidth === "lg" ||
              screenWidth === "xl"
                ? "left"
                : "center",
          }}
        >
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={prioridades.baja}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value={prioridades.baja}
                control={
                  <Radio
                    icon={
                      <RadioButtonUncheckedOutlinedIcon
                        sx={{ fontSize: 40 /*color: "#FF0000"*/ }}
                      />
                    }
                    checkedIcon={
                      <CheckCircleIcon
                        sx={{ fontSize: 40 /*color: "#21AF19"*/ }}
                      />
                    }
                    onClick={() => handleClick(prioridades.baja)}
                  />
                }
                label={prioridades.baja}
              />
              <FormControlLabel
                value={prioridades.media}
                control={
                  <Radio
                    icon={
                      <RadioButtonUncheckedOutlinedIcon
                        sx={{ fontSize: 40 /*color: "#FF0000"*/ }}
                      />
                    }
                    checkedIcon={
                      <CheckCircleIcon
                        sx={{ fontSize: 40 /*color: "#21AF19"*/ }}
                      />
                    }
                    onClick={() => handleClick(prioridades.media)}
                  />
                }
                label={prioridades.media}
              />
              <FormControlLabel
                value={prioridades.alta}
                control={
                  <Radio
                    icon={
                      <RadioButtonUncheckedOutlinedIcon
                        sx={{ fontSize: 40 /*color: "#FF0000"*/ }}
                      />
                    }
                    checkedIcon={
                      <CheckCircleIcon
                        sx={{ fontSize: 40 /*color: "#21AF19"*/ }}
                      />
                    }
                    onClick={() => handleClick(prioridades.alta)}
                  />
                }
                label={prioridades.alta}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent:
              screenWidth === "md" ||
              screenWidth === "lg" ||
              screenWidth === "xl"
                ? "left"
                : "center",
          }}
        >
          <Navegacion
            nroPaso={props.nroPaso}
            setFinNavegacion={setFinNavegacion}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PageAgregarPrioridad;
