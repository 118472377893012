import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useContext, useState } from "react";
import styled from "@emotion/styled";

import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SendIcon from "@mui/icons-material/Send";

import { useNavigate } from "react-router-dom";
import ProductoMercadoPago from "../pagos/ProductoMercadoPago";
import MensajesContext from "../../contextos/MensajesContext";
import { desencriptar } from "../../funciones/Funciones";
import UsuarioContext from "../../contextos/UsuarioContext";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "140px",
}));

const MetodoDePago = (props) => {
  let navigate = useNavigate();

  const [disableButton, setDisableButton] = useState(false);

  const [user, setUser] = useState([]);
  const [modulosActivos, setModulosActivos] = useState([]);

  const { usuario, setUsuario } = useContext(UsuarioContext);

  const initCupon = {
    titulo: "",
    codigo: "",
    servicioBonificado: "",
    duracionEnMeses: 0,
    fechaUso: "",
    fechaVencimiento: "",
    valid: false,
  };

  const [cupon, setCupon] = useState(initCupon);

  useEffect(() => {
    let objusu;
    let arrmods;

    if (props.origen === "REGISTRO") {
      objusu = desencriptar(localStorage.getItem("objregusu"));
      arrmods = desencriptar(localStorage.getItem("arrmods"));
    } else {
      objusu = desencriptar(localStorage.getItem("objusu"));
      arrmods = [
        "ORDENES_DE_SERVICIO",
        "PEDIDO_DE_MATERIALES",
        "MATERIALES",
        "VOLQUETES",
        "PRESUPUESTOS",
        "DOCUMENTACION",
      ];
    }

    setUser(objusu);
    setModulosActivos(arrmods);

    // console.log("PASA POR USEEFFECT");
    // console.log(objregusu);
  }, []);

  // const {
  //   mostrarMensaje,
  //   setMostrarMensaje,
  //   mensaje,
  //   setMensaje,
  //   mostrarModal,
  //   setMostrarModal,
  // } = useContext(MensajesContext);

  // useEffect(() => {
  //   console.log(props.cupon);
  // }, [props.cupon]);

  const handleChangeCupon = (event) => {
    let text = event.target.value;

    setCupon({ ...cupon, codigo: text });
  };

  const solicitar_invitacion = async () => {
    try {
      const API_ENDPOINT = `https://backend.bimtracker.com/api_usuarios.php`;

      // var base64 = require("base-64");

      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

      // console.log("idUsuario: " + user.IDUsuario);
      // console.log("nombre: " + user.Nombre);
      // console.log("apellido: " + user.Apellido);
      // console.log("usuario: " + user.Usuario);
      // console.log("email: " + user.Email);
      // console.log("modulosActivos: " + modulosActivos);

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "SOLICITAR_INVITACION",
          idUsuario: user.IDUsuario,
          nombre: user.Nombre,
          apellido: user.Apellido,
          usuario: user.Usuario,
          email: user.Email,
          modulosActivos: modulosActivos,
        }),

        // headers: headers,
        //credentials: 'user:passwd'
      });

      const usuarios = await datos.json();

      // console.log(usuarios.result.usuario);

      if (usuarios.result.invitacion == "INVITACION_OK") {
        // console.log(usuarios.result.usuario);

        setDisableButton(true);

        props.setMensaje({
          text: "La solicitud ha sido enviada con éxito",
          tipo: "success",
        });

        props.setMostrarMensaje(true);

        localStorage.removeItem("objobra");
        localStorage.removeItem("arrmods");
        localStorage.removeItem("objregusu");

        setTimeout(() => {
          props.setMostrar("FORMULARIO_LOGIN");
          navigate("/");
        }, 4000);
      } else {
        props.setMensaje({
          text: "Error al enviar la invitación",
          tipo: "error",
        });

        props.setMostrarMensaje(true);
      }

      return usuarios.result;
    } catch (error) {
      console.error(error);
    }

    return "ERROR";
  };

  const chequear_cupon_ingresado_en_registro = async () => {
    try {
      if (modulosActivos.length > 0) {
        const API_ENDPOINT = `https://backend.bimtracker.com/api_usuarios.php`;

        // var base64 = require("base-64");

        // let headers = new Headers();
        // // headers.append('Content-Type', 'text/json');
        // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

        const datos = await fetch(API_ENDPOINT, {
          method: "POST",
          // headers: {
          //   Accept: "application/json",
          //   "Content-type": "application/json",
          // },
          body: JSON.stringify({
            operacion: "CHEQUEAR_CUPON_INGRESADO",
            idUsuario: user.IDUsuario,
            codigoCupon: cupon.codigo,
            modulosActivos: modulosActivos,
          }),

          // headers: headers,
          //credentials: 'user:passwd'
        });

        const cupones = await datos.json();

        // console.log(usuarios.result.usuario);

        if (cupones.result.cuponValido) {
          setCupon({ ...cupon, valid: true });

          props.setMensaje({
            text: "Tu registro se completó con éxito.",
            tipo: "success",
          });
          props.setMostrarMensaje(true);

          localStorage.removeItem("objobra");
          localStorage.removeItem("arrmods");
          localStorage.removeItem("objregusu");

          setTimeout(() => {
            props.setMostrar("FORMULARIO_LOGIN");
            navigate("/");
          }, 4000);
        } else {
          props.setMensaje({ text: cupones.result.error, tipo: "error" });
          props.setMostrarMensaje(true);

          setCupon({ ...cupon, valid: false });
        }

        return true;
      } else {
        props.setMensaje({
          text: "Seleccione al menos un módulo",
          tipo: "error",
        });
        props.setMostrarMensaje(true);
      }
    } catch (error) {
      setCupon({ ...cupon, valid: false });
      console.error(error);
    }

    return "ERROR";
  };

  const chequear_cupon_ingresado_en_app = async () => {
    try {
      const API_ENDPOINT = `https://backend.bimtracker.com/api_usuarios.php`;

      // var base64 = require("base-64");

      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "CHEQUEAR_CUPON_INGRESADO",
          idUsuario: user.IDUsuario,
          codigoCupon: cupon.codigo,
          modulosActivos: modulosActivos,
        }),

        // headers: headers,
        //credentials: 'user:passwd'
      });

      const cupones = await datos.json();

      // console.log(usuarios.result.usuario);

      if (cupones.result.cuponValido) {
        setCupon({ ...cupon, valid: true });
        setUsuario({
          ...usuario.Privilegios,
          Privilegios: cupones.result.privilegios,
        });

        props.setMensaje({
          text: "El cupón se procesó con éxito.",
          tipo: "success",
        });
        props.setMostrarMensaje(true);

        setTimeout(() => {
          props.setMostrar("FORMULARIO_LOGIN");
          navigate("/");
        }, 4000);
      } else {
        props.setMensaje({ text: cupones.result.error, tipo: "error" });
        props.setMostrarMensaje(true);

        setCupon({ ...cupon, valid: false });
      }

      return true;

      props.setMostrarMensaje(true);
    } catch (error) {
      setCupon({ ...cupon, valid: false });
      console.error(error);
    }

    return "ERROR";
  };

  // const pagoConMercadoPago = () => {
  //   // props.setMostrar("FORMULARIO_REGISTRO");
  //   // navigate("/pago/123");

  //   // buscar_plan_de_suscripcion();

  //   var myHeaders = new Headers();
  //   myHeaders.append(
  //     "Authorization",
  //     "Bearer TEST-1870023888363469-102420-4f22b596f78e9a16abdbfdfac4fdc2d6-694714670"
  //   );

  //   var requestOptions = {
  //     method: "GET",
  //     mode: "no-cors",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     "https://api.mercadopago.com/preapproval_plan/2c9380848383e02f01840c435f3557ce",
  //     requestOptions
  //   )
  //     .then((response) => response.text())
  //     .then((result) => console.log(result))
  //     .catch((error) => console.log("error", error));
  // };

  // const buscar_plan_de_suscripcion = async () => {
  //   const API_ENDPOINT = `https://api.mercadopago.com/preapproval_plan/2c9380848383e02f01840c435f3557ce`;

  //   // var base64 = require("base-64");

  //   // let headers = new Headers();
  //   // // headers.append('Content-Type', 'text/json');
  //   // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "GET",
  //     mode: "no-cors",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-type": "application/json",
  //       Authorization:
  //         "Bearer TEST-1870023888363469-102420-4f22b596f78e9a16abdbfdfac4fdc2d6-694714670",
  //     },
  //     // body: JSON.stringify({
  //     //   preapproval_plan_id: "2c9380848383e02f01840c435f3557ce",
  //     //   // reason: "Licencia HyS",
  //     //   // external_reference: "HYS-1234",
  //     //   // payer_email: "test_user@testuser.com",
  //     //   // card_token_id: "e3ed6f098462036dd2cbabe314b9de2a",
  //     //   // auto_recurring: {
  //     //   //   frequency: 1,
  //     //   //   frequency_type: "months",
  //     //   //   start_date: "2022-10-25T13:07:14.260Z",
  //     //   //   end_date: "2022-11-25T15:59:52.581Z",
  //     //   //   transaction_amount: 10,
  //     //   //   currency_id: "ARS",
  //     //   // },
  //     //   // back_url: "https://www.mercadopago.com.ar",
  //     //   // status: "authorized",
  //     // }),

  //     // headers: headers,
  //     //credentials: 'user:passwd'
  //   });

  //   const resp = await datos.json();

  //   console.log(resp.results);

  //   if (resp.results.status === "active") {
  //     setMensaje({
  //       text: "Plan ID: " + resp.results.id,
  //       tipo: "success",
  //     });
  //     setMostrarMensaje(true);

  //     //   setTimeout(() => {
  //     //     props.setMostrar("FORMULARIO_LOGIN");
  //     //     navigate("/");
  //     //   }, 4000);
  //   } else {
  //     setMensaje({ text: resp.results, tipo: "error" });
  //     setMostrarMensaje(true);
  //   }
  // };

  // const crear_suscripcion = async () => {
  //   const API_ENDPOINT = `https://api.mercadopago.com/preapproval`;

  //   // var base64 = require("base-64");

  //   // let headers = new Headers();
  //   // // headers.append('Content-Type', 'text/json');
  //   // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       Authorization:
  //         "Bearer TEST-1870023888363469-102420-4f22b596f78e9a16abdbfdfac4fdc2d6-694714670",
  //       "Content-type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       preapproval_plan_id: "2c9380848383e02f01840c435f3557ce",
  //       reason: "Licencia HyS",
  //       external_reference: "HYS-1234",
  //       payer_email: "test_user@testuser.com",
  //       card_token_id: "e3ed6f098462036dd2cbabe314b9de2a",
  //       auto_recurring: {
  //         frequency: 1,
  //         frequency_type: "months",
  //         start_date: "2022-10-25T13:07:14.260Z",
  //         end_date: "2022-11-25T15:59:52.581Z",
  //         transaction_amount: 10,
  //         currency_id: "ARS",
  //       },
  //       back_url: "https://www.mercadopago.com.ar",
  //       status: "authorized",
  //     }),

  //     // headers: headers,
  //     //credentials: 'user:passwd'
  //   });

  //   const resp = await datos.json();

  //   // console.log(usuarios.result.usuario);

  //   if (resp.result.status === "authorized") {
  //     setMensaje({
  //       text: "Pago correcto.",
  //       tipo: "success",
  //     });
  //     setMostrarMensaje(true);

  //     //   setTimeout(() => {
  //     //     props.setMostrar("FORMULARIO_LOGIN");
  //     //     navigate("/");
  //     //   }, 4000);
  //   } else {
  //     setMensaje({ text: resp.result.error, tipo: "error" });
  //     setMostrarMensaje(true);
  //   }
  // };

  return (
    <Grid item xs={12} sm={8} md={4} lg={3} key={1}>
      <Item
      //   onClick={() => moduloClick(props.codigo)}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          // border={1}
        >
          {props.metodo === "CUPON" ? (
            <>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                //   border={1}
              >
                <Grid item style={{ alignItems: "center" }}>
                  TENGO UN CUPON
                </Grid>
                <Grid item>
                  <LocalActivityOutlinedIcon
                    style={{ paddingTop: "5px" }}
                    fontSize="medium"
                    // onClick={(event) => handleInfo(event, props.codigo)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                //   justifyContent="space-between"
                alignItems="center"
                //   border={1}
              >
                <Grid item xs={6}>
                  <TextField
                    id="standard-basic"
                    label="Cupón"
                    variant="standard"
                    // style={{ marginTop: 0 }}
                    onChange={handleChangeCupon}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<SendIcon />}
                    style={{ marginTop: 20 }}
                    onClick={() =>
                      props.origen === "REGISTRO"
                        ? chequear_cupon_ingresado_en_registro()
                        : chequear_cupon_ingresado_en_app()
                    }
                  >
                    Confirmar
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : props.metodo === "SOLICITAR_INVITACION" ? (
            <>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"

                // border={1}
              >
                <Grid item>
                  <Grid item style={{ alignItems: "center" }}>
                    SOLICITAR INVITACIÓN
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid item>
                    <LockOpenOutlinedIcon
                      fontSize="medium"
                      // onClick={(event) => handleInfo(event, props.codigo)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item style={{ paddingTop: "10px" }}>
                    Te enviaremos un correo electrónico.
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ justifyContent: "center" }}>
                  <Button
                    onClick={() => solicitar_invitacion()}
                    variant="contained"
                    size="small"
                    disabled={disableButton}
                    style={{ marginTop: 23 }}
                    endIcon={<SendIcon />}
                  >
                    Solicitar
                  </Button>
                </Grid>
              </Grid>

              {/* <div class="cho-container"></div> */}
              {/* <ProductoMercadoPago /> */}
            </>
          ) : props.metodo === "MERCADO_PAGO" ? (
            <>
              {/* <Button onClick={() => pagoConMercadoPago()} variant="contained">
                Pagar
              </Button> */}

              {/* <ProductoMercadoPago /> */}
            </>
          ) : (
            ""
          )}
        </Grid>
      </Item>
    </Grid>
  );
};

export default MetodoDePago;
