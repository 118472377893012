import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Titulo from "../../components/Titulo";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import Select from "react-select";
import ObraContext from "../../contextos/ObraContext";
import { desencriptar } from "../../funciones/Funciones";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "auto",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

const PageAltaDocumento = () => {
  // const initialSociedad = {
  //   razonSocial: "",
  //   cuit: "",
  //   email: "",
  //   rubro: "",
  // };

  // const [sociedad, setSociedad] = useState(initialSociedad);

  const { obra, setObra } = useContext(ObraContext);

  const [documento, setDocumento] = useState({
    text: "",
    valid: false,
    error: "",
  });

  const [listaFiltrada, setListaFiltrada] = useState([]);
  const [lista, setLista] = useState([]);
  const [mensaje, setMensaje] = useState({ text: "", error: false });

  const cargarLista = async () => {
    const API_ENDPOINT = `https://backend.bimtracker.com/api_hys.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_DOCUMENTOS",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.documentos) {
      // console.log(respuesta.result.documentos);
      setLista(respuesta.result.documentos);
      setListaFiltrada(respuesta.result.documentos);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN cargarLista()");
      // setState([]);
    }
  };

  useEffect(() => {
    let objobra = localStorage.getItem("objobra");

    setObra(desencriptar(objobra));
    // console.log(desencriptar(objobra));

    cargarLista();
  }, []);

  const validDocumento = (event) => {
    let text = event.target.value;

    setMensaje({ text: "", error: false });

    let docs = lista.filter(function(item) {
      //   console.log(item.Documento);
      return (
        item.Documento === "" ||
        item.Documento.toUpperCase().includes(text.toUpperCase())
      );
    });

    setListaFiltrada(docs);

    if (text.length > 2) {
      setDocumento({ text: text, valid: true, error: "" });

      //   setListaFiltrada(
      //     lista.filter(function(item) {
      //       return item.Documento === documento.text;
      //     })
      //   );
    } else {
      setDocumento({
        text: text,
        valid: false,
        error: "Nombre del documento demasiado corto.",
      });
    }
  };

  const handleGuardar = async () => {
    let existe = listaFiltrada.map((item) => {
      return item.Documento.toUpperCase() === documento.text.toUpperCase();
    });

    // console.log(existe);

    if (existe.includes(true)) {
      setMensaje({
        text: "Ya existe un documento con ese nombre.",
        error: true,
      });
    } else {
      if (documento.valid) {
        const API_ENDPOINT = `https://backend.bimtracker.com/api_hys.php`;
        let formSoc = {
          operacion: "ALTA_DOCUMENTO_EN_HYS",
          codDocumento: "",
          documento: documento.text,
        };
        const datos = await fetch(API_ENDPOINT, {
          method: "POST",
          // headers: {
          //   Accept: "application/json",
          //   "Content-Type": "application/json",
          // },
          body: JSON.stringify(formSoc),
        });
        const respuesta = await datos.json();
        // console.log(respuesta);
        if (respuesta.result.respuesta === "ALTA_OK") {
          //   console.log(respuesta.result.respuesta);

          setDocumento({ text: "", valid: false, error: "" });

          setMensaje({
            text: "El documento ha sido agregado con éxito!",
            error: false,
          });

          setTimeout(() => {
            setMensaje({
              text: "",
              error: true,
            });

            cargarLista();
          }, 3000);
        } else {
          setMensaje({ text: "Error al agregar el documento.", error: true });
          console.log("NO TRAE NADA EN handleGuardar()");
          // setState([]);
        }
      } else {
        setMensaje({ text: "Complete todos los campos.", error: true });
      }
    }
  };

  const styles = {
    container: {
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    contenedor: {
      // position: "absolute",
      // top: "50%",
      // left: "50%",
      // transform: "translate(-50%, -50%)",
      display: "flex",
      justifyContent: "center",
      width: "auto",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 2,
    },
    exito: {
      color: "#13b90b",
      marginBottom: 10,
      alignItems: "flex-start",
      width: "70%",
      // borderWidth: 1,
      // borderColor: '#FFFFFF',
    },
    error: {
      color: "#FF0000",
      marginBottom: 10,
      alignItems: "flex-start",
      width: "70%",
      // borderWidth: 1,
      // borderColor: '#FFFFFF',
    },
  };

  const style = {
    width: "100%",
    maxWidth: 360,
    bgcolor: "background.paper",
  };

  return (
    <>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Titulo
            texto="Agregar documento"
            botones={{
              seccion: "",
              alta: false,
              baja: false,
              modif: false,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={8} lg={6} style={{ paddingTop: "35px" }}>
          <Box
            component="form"
            sx={styles.contenedor}
            noValidate
            autoComplete="off"
          >
            {/* <TextField
            id="standard-basic"
            label="Standard"
            helperText="hola"
            variant="standard"
          /> */}

            {/* <FormControl sx={{ m: 1, minWidth: 80 }}> */}
            <FormControl variant="standard" sx={{ m: 1, width: "auto" }}>
              <Typography variant="h5" component="h5">
                AGREGAR DOCUMENTO
              </Typography>

              <div>&nbsp;</div>

              <Grid
                item
                xs={12}
                style={mensaje.error ? styles.error : styles.exito}
              >
                <Typography variant="h6" component="h6">
                  {mensaje.text}
                </Typography>
              </Grid>

              {/* <Typography variant="h6" component="h6">
              Nombre
            </Typography> */}

              <div>&nbsp;</div>

              <TextField
                required
                id="standard-required"
                label="Documento"
                // defaultValue=""
                variant="standard"
                style={{ width: "80%" }}
                onChange={validDocumento}
                value={documento.text}
              />
              <div style={styles.error}>{documento.error}</div>

              <div>&nbsp;</div>

              <Button
                variant="contained"
                endIcon={<CheckOutlinedIcon />}
                onClick={handleGuardar}
              >
                Guardar
              </Button>

              <div>&nbsp;</div>

              <List sx={style} component="nav" aria-label="mailbox folders">
                {listaFiltrada.map(function(item) {
                  return (
                    <ListItem button divider key={item.IDDocumentacion}>
                      <ListItemText primary={item.Documento} />
                    </ListItem>
                  );
                })}
              </List>

              <div>&nbsp;</div>

              <div>&nbsp;</div>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12}></Grid>
      </Grid>
    </>
  );
};

export default PageAltaDocumento;
