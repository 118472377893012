import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import { DragDropContext } from "react-beautiful-dnd";
import Boton from "../../components/Boton";
// import styled from "styled-components";

import Column from "./Column";

const PageSupervisarPedido = (props) => {
  props.setTitulo("Supervisar pedidos");
  props.setPagina("");

  // const initialData = {
  //   materiales: {
  //     "1": { idMaterial: "1", nombre: "Cemento (x bolsa)", cantidad: 10 },
  //     "2": { idMaterial: "2", nombre: "Cal (x pallet)", cantidad: 2 },
  //     "3": { idMaterial: "3", nombre: "Yeso (x pallet)", cantidad: 4 },
  //     "4": { idMaterial: "4", nombre: "Arena (x camión)", cantidad: 1 },
  //   },
  //   proveedores: {
  //     "0": {
  //       id: "0",
  //       titulo: "PEDIDO",
  //       matIds: ["1", "2", "3", "4"],
  //     },
  //     "1": {
  //       id: "1",
  //       titulo: "Corralón Mortola",
  //       matIds: [],
  //     },
  //     "2": {
  //       id: "2",
  //       titulo: "Pinturarías M&M",
  //       matIds: [],
  //     },
  //   },
  //   // Facilitate reordering of the proveedores
  //   proveedorOrder: ["0", "1", "2"],
  // };

  // const initialData = {
  //   materiales: {
  //     "12": { idMaterial: "12", nombre: "Arena (en bolsón)", cantidad: 2 },
  //     "17": {
  //       idMaterial: "17",
  //       nombre: "Ceresita (balde 20 lts)",
  //       cantidad: 3,
  //     },
  //     "11": {
  //       idMaterial: "11",
  //       nombre: "Cantoneras armadas para yeso (por unidad)",
  //       cantidad: 8,
  //     },
  //   },
  //   proveedores: {
  //     "0": {
  //       id: "0",
  //       titulo: "PEDIDO",
  //       matIds: ["12", "17", "11"],
  //     },
  //     "1": {
  //       id: "1",
  //       titulo: "Corralón Mortola",
  //       matIds: [],
  //     },
  //     "2": {
  //       id: "2",
  //       titulo: "Paraná 201",
  //       matIds: [],
  //     },
  //     "3": {
  //       id: "3",
  //       titulo: "Pinturería M&M",
  //       matIds: [],
  //     },
  //   },
  //   proveedorOrder: ["0", "1", "2", "3"],
  // };

  const initial = {
    materiales: {
      "12": { idMaterial: "12", nombre: "Arena (en bolsón)", cantidad: 2 },
      "17": {
        idMaterial: "17",
        nombre: "Ceresita (balde 20 lts)",
        cantidad: 3,
      },
      "11": {
        idMaterial: "11",
        nombre: "Cantoneras armadas para yeso (por unidad)",
        cantidad: 8,
      },
    },
    proveedores: {
      "0": { id: "0", titulo: "PEDIDO", matIds: ["12", "17", "11"] },
      "1": { id: "1", titulo: "Corralón Mortola", matIds: [] },
      "2": { id: "2", titulo: "Paraná 201", matIds: [] },
      "3": { id: "3", titulo: "Pinturería M&M", matIds: [] },
    },
    proveedorOrder: ["0", "1", "2", "3"],
  };

  const initialData = {
    materiales: {},
    proveedores: {
      "0": { id: "0", titulo: "PEDIDO", matIds: [] },
    },
    proveedorOrder: ["0"],
  };

  // const [state, setState] = useState([]);
  const [state, setState] = useState(initialData);

  const obtenerPedidos = async () => {
    const API_ENDPOINT = `https://materiales.bimtracker.com/backend/api_pedidos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        // operacion: "POST_PEDIDOS_PENDIENTES",
        operacion: "POST_MOSTRAR_PEDIDOS_AGRUPADOS",
        idUsuario: 2,
        idObra: 1,
        idPedido: 2,
      }),
    });
    const respuesta = await datos.json();

    if (respuesta.result.pedidos) {
      // console.log("TTT: " + respuesta.result.pedidos.proveedorOrder);

      setState(respuesta.result.pedidos);
    } else {
      setState([]);
    }
  };

  useEffect(() => {
    obtenerPedidos();
    // console.log(state.proveedorOrder);
  }, []);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = state.proveedores[source.droppableId];
    const finish = state.proveedores[destination.droppableId];

    if (start === finish) {
      const newMatIds = Array.from(start.matIds);
      newMatIds.splice(source.index, 1);
      newMatIds.splice(destination.index, 0, draggableId);

      const newProveedor = {
        ...start,
        matIds: newMatIds,
      };

      const newState = {
        ...state,
        proveedores: {
          ...state.proveedores,
          [newProveedor.id]: newProveedor,
        },
      };

      setState(newState);
      return;
    }

    // Moving from one list to another
    const startMatIds = Array.from(start.matIds);
    startMatIds.splice(source.index, 1);
    const newStart = {
      ...start,
      matIds: startMatIds,
    };

    const finishMatIds = Array.from(finish.matIds);
    finishMatIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      matIds: finishMatIds,
    };

    // console.log(newStart);
    // console.log(newFinish);

    const newState = {
      ...state,
      proveedores: {
        ...state.proveedores,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    setState(newState);
  };

  return (
    <Grid item xs={12}>
      <Grid item xs={10}>
        <DragDropContext onDragEnd={onDragEnd}>
          <div style={{ display: "flex" }}>
            {state.proveedorOrder.map((proveedorId, index) => {
              const proveedor = state.proveedores[proveedorId];

              // console.log(state.materiales);

              const materiales = proveedor.matIds.map(
                (matId) => state.materiales[matId]
              );

              // console.log(materiales);

              return (
                <Column
                  index={index}
                  key={proveedor.id}
                  proveedor={proveedor}
                  materiales={materiales}
                />
              );
            })}
          </div>
        </DragDropContext>
      </Grid>
    </Grid>
  );
};

export default PageSupervisarPedido;
