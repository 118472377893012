import React, { useContext, useEffect } from "react";
import ObraContext from "../../contextos/ObraContext";
import PrivilegioContext from "../../contextos/PrivilegiosContext";
import PagePresupuestoActual from "../presupuestos/PagePresupuestoActual";

const PageDashboardPresupuestos = (props) => {
  const { obra, setObra } = useContext(ObraContext);
  const { privilegios, setPrivilegios } = useContext(PrivilegioContext);

  useEffect(() => {
    props.setTitulo("Dashboard Presupuestos");
    props.setPagina("");

    // console.log("A");
  }, [props.setTitulo]);

  // useEffect(() => {
  //   console.log("PresupuestoActual 1: ");
  //   console.log(obra.Privilegios);
  //   console.log("PresupuestoActual 2: ");
  //   console.log(privilegios);
  // }, []);

  return (
    <div>
      <PagePresupuestoActual
        setTitulo={props.setTitulo}
        setPagina={props.setPagina}
      />
    </div>
  );
};

export default PageDashboardPresupuestos;
