import { Typography } from "@mui/material";
import React from "react";
import BotoneraABM from "./BotoneraABM";

const Titulo = (props) => {
  // console.log(props.estilos.tituloEncabezado);
  let estilos = props.estilos !== undefined ? props.estilos : "";

  return (
    <Typography variant="h4" style={estilos.tituloEncabezado}>
      {/* <h1> */}
      {props.texto}
      {"   "}
      {props.botones ? <BotoneraABM botones={props.botones} /> : ""}
      {/* </h1> */}
    </Typography>
  );
};

export default Titulo;
