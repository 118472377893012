import styled from "@emotion/styled";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertValidConfig from "../../components/AlertValidConfig";
import EncabezadoItems from "../../components/EncabezadoItems";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import SendIcon from "@mui/icons-material/Send";

import MensajesContext from "../../contextos/MensajesContext";
import PedidoContext from "../../contextos/PedidoContext";
import { desencriptar, formatearFecha } from "../../funciones/Funciones";
import EncabezadoWeb from "./EncabezadoWeb";
import EstilosContext from "../../contextos/EstilosContext";
import EncabezadoSmartphone from "./EncabezadoSmartphone";
import Botonera from "../general/Botonera";
import WhatsappEnviarMensaje from "../../components/WhatsappEnviarMensaje";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  linkStyle: {
    color: "white",
    textDecoration: "none",
  },
}));

const PageVerificarPedido = (props) => {
  let navigate = useNavigate();

  const classes = useStyles();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    // display: "block",
    // overflow: "auto",
  }));

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } = useContext(
    MensajesContext
  );

  const { codigoPedido, setCodigoPedido } = useContext(PedidoContext);

  const { screenWidth } = useContext(EstilosContext);

  const [entregaParcial, setEntregaParcial] = useState([]);
  const [cantidadRecibida, setCantidadRecibida] = useState([]);

  const [activeEntregaParcial, setActiveEntregaParcial] = useState([]);

  const [idPedido, setIdPedido] = useState(0);
  const [encabezadoPedido, setEncabezadoPedido] = useState({});
  const [pedido, setPedido] = useState([]);
  const [pedidoActual, setPedidoActual] = useState([]);
  const [materialesSeleccionados, setMaterialesSeleccionados] = useState([]);

  const [openEntregaParcial, setOpenEntregaParcial] = useState(false);

  const [whatsapp, setWhatsapp] = useState("");
  const [mensajeWhatsappEnvio, setMensajeWhatsappEnvio] = useState("");
  const [mensajeWhatsappReenvio, setMensajeWhatsappReenvio] = useState("");
  const [paramsWhatsapp, setParamsWhatsapp] = useState([]);
  const [paramsWhatsappEnvio, setParamsWhatsappEnvio] = useState({});
  const [paramsWhatsappReenvio, setParamsWhatsappReenvio] = useState({});
  const [modoWhatsapp, setModoWhatsapp] = useState("");

  let botonesInit = [
    {
      titulo: "Cerrar pedido",
      tipoBoton: "BOTON",
      paramsWhatsapp: {
        whatsapp: "",
        mensajeWP: "",
        pedido: "",
        setMostrarMensaje: "",
        setMensaje: "",
        tipo: "CERRAR_PEDIDO",
      },
      funcion: "handleCerrarPedido",
    },
    {
      titulo: "Enviar pedido",
      tipoBoton: "WHATSAPP",
      paramsWhatsapp: {
        whatsapp: whatsapp,
        mensajeWP: mensajeWhatsappEnvio,
        pedido: pedido,
        setMostrarMensaje: setMostrarMensaje,
        setMensaje: setMensaje,
        tipo: "ENVIAR_PEDIDO",
      },
      funcion: () => handleEnviarPedido("ENVIO"),
    },
    {
      titulo: "Reenviar pedido",
      tipoBoton: "WHATSAPP",
      paramsWhatsapp: {
        whatsapp: whatsapp,
        mensajeWP: mensajeWhatsappReenvio,
        pedido: pedido,
        setMostrarMensaje: setMostrarMensaje,
        setMensaje: setMensaje,
        tipo: "REENVIAR_PEDIDO",
      },
      funcion: () => handleEnviarPedido("REENVIO"),
    },
  ];

  const [botones, setBotones] = useState(botonesInit);

  useEffect(() => {
    props.setTitulo("Verificar pedido");
    props.setPagina("");

    // console.log("A");
  }, [props.setTitulo]);

  useEffect(() => {
    let pedActual = desencriptar(localStorage.getItem("objped"));
    // if (ped !== undefined) {
    //   setPedido(ped);
    // console.log("PEDIDO: ");
    // console.log(pedActual);
    //   // localStorage.removeItem("objped");
    // } else {

    setPedidoActual(pedActual);

    // ******************

    let msgEnvio = "";

    msgEnvio +=
      "Qué tal " +
      pedActual.proveedorNombreContacto +
      "?, \nTe paso un pedido para *" +
      pedActual.obra +
      " (" +
      pedActual.sociedadCuit +
      ")*: \n\n";
    // msgEnvio += "Cant. Volquetes: " + count + "\n\n";
    msgEnvio +=
      "Fecha: " + formatearFecha(pedActual.fechaProgramada, "texto") + "\n";
    msgEnvio += "Hora: " + pedActual.horaProgramada + "\n\n";
    // msgEnvio += "Para ver el pedido ingresá al siguiente link: \n";
    // msgEnvio +=
    //   "https://plus.bimtracker.com/confirmarPedido/" +
    //   pedido.codigoPedido +
    //   "\n\n";

    msgEnvio += "*Pedido*: \n";

    pedActual.lista.map(
      (item, index) =>
        (msgEnvio +=
          item.cantidad + " " + item.unidad + " de " + item.material + "\n")
    );

    msgEnvio += "\n";
    msgEnvio += "\n";

    msgEnvio += "Pedido enviado desde https://plus.bimtracker.com";

    setMensajeWhatsappEnvio(msgEnvio);

    let msgReenvio = "";

    msgReenvio += "Qué tal " + pedActual.proveedorNombreContacto + "?, \n";

    msgReenvio +=
      "Para la obra *" +
      pedActual.obra +
      " (" +
      pedActual.sociedadCuit +
      ")*: \n\n";

    msgReenvio +=
      "Quedó pendiente lo siguiente del pedido #" + pedActual.idPedido + "\n";
    msgReenvio +=
      "Fecha: " + formatearFecha(pedActual.fechaProgramada, "texto") + "\n";
    msgReenvio += "Hora: " + pedActual.horaProgramada + "\n\n";

    // msgReenvio += "Para ver el pedido ingresá al siguiente link: \n";
    // msgReenvio +=
    //   "https://plus.bimtracker.com/confirmarPedido/" +
    //   pedido.codigoPedido +
    //   "\n\n";

    msgReenvio += "*Pendiente*: \n";

    pedActual.lista
      .filter((mat) => mat.estado !== "ENTREGA_COMPLETA")
      .map((item, index) =>
        item.estado === "NO_ENTREGO"
          ? (msgReenvio +=
              item.cantidad + " " + item.unidad + " de " + item.material + "\n")
          : (msgReenvio +=
              item.cantidad -
              item.cantidadRecibida +
              " " +
              item.unidad +
              " de " +
              item.material +
              "\n")
      );

    msgReenvio += "\n";
    msgReenvio += "\n";

    msgReenvio += "Pedido enviado desde https://plus.bimtracker.com";

    setMensajeWhatsappReenvio(msgReenvio);

    // console.log(ped.proveedorWhatsapp);

    setParamsWhatsapp(
      {
        whatsapp: pedActual.proveedorWhatsapp,
        mensajeWP: msgEnvio,
        pedido: pedActual,
        setMostrarMensaje: setMostrarMensaje,
        setMensaje: setMensaje,
        tipo: "ENVIAR_PEDIDO",
      },
      {
        whatsapp: pedActual.proveedorWhatsapp,
        mensajeWP: msgReenvio,
        pedido: pedActual,
        setMostrarMensaje: setMostrarMensaje,
        setMensaje: setMensaje,
        tipo: "REENVIAR_PEDIDO",
      }
    );

    // setBotones([
    //   ...botones,
    //   (botones.filter((bot) => bot.paramsWhatsapp.tipo === "ENVIAR_PEDIDO")
    //     .paramsWhatsapp.mensajeWP: msgEnvio),
    // ]);

    // botones.filter(
    //   (bot) => bot.paramsWhatsapp.tipo === "ENVIAR_PEDIDO"
    // ).paramsWhatsapp.mensajeWP = "HOLA";

    // console.log(
    //   botones.filter((bot) => bot.paramsWhatsapp.tipo === "ENVIAR_PEDIDO")
    //     .paramsWhatsapp.mensajeWP
    // );

    // ****************

    traer_pedido_detalle();
    // }
  }, []);

  useEffect(() => {
    // console.log("cant: " + cantidadRecibida);

    console.log(screenWidth);
    // console.log(pedido);
    // console.log(pedido[0]);
  });

  const filtrar_materiales = (item) => {
    let material = materialesSeleccionados.filter(
      (mat) => mat.IDMaterial === item.IDMaterial
    );

    let ret = material.length > 0 ? material[0] : [];

    // console.log("ret: ");
    // console.log(ret);

    return ret;
  };

  const traer_pedido_detalle = async () => {
    const API_ENDPOINT = `https://backend.bimtracker.com/api_pedidos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_UN_PEDIDO_PARA_CONFIRMAR",
        codigoPedido: codigoPedido,
        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.pedido) {
      // console.log(respuesta.result.pedido);
      setPedido(respuesta.result.pedido);
      setWhatsapp(respuesta.result.pedido[0]["Whatsapp"]);
      setIdPedido(respuesta.result.pedido[0]["IDPedido"]);
      setEncabezadoPedido(respuesta.result.pedido[0]);
      setMaterialesSeleccionados(respuesta.result.pedido);

      let ped = respuesta.result.pedido[0];

      // let msgEnvio = "";

      // msgEnvio +=
      //   "Qué tal " +
      //   ped.NombreContacto +
      //   "?, \nTe paso un pedido para *" +
      //   ped.Obra +
      //   " (" +
      //   ped.Cuit +
      //   ")*: \n\n";
      // // msgEnvio += "Cant. Volquetes: " + count + "\n\n";
      // msgEnvio +=
      //   "Fecha: " + formatearFecha(ped.FechaProgramada, "texto") + "\n";
      // msgEnvio += "Hora: " + ped.HoraProgramada + "\n\n";
      // // msgEnvio += "Para ver el pedido ingresá al siguiente link: \n";
      // // msgEnvio +=
      // //   "https://plus.bimtracker.com/confirmarPedido/" +
      // //   pedido.codigoPedido +
      // //   "\n\n";

      // msgEnvio += "*Pedido*: \n";

      // respuesta.result.pedido.map(
      //   (item, index) =>
      //     (msgEnvio +=
      //       item.Cantidad + " " + item.Unidad + " de " + item.Material + "\n")
      // );

      // msgEnvio += "\n";
      // msgEnvio += "\n";

      // msgEnvio += "Pedido enviado desde https://plus.bimtracker.com";

      // setMensajeWhatsappEnvio(msgEnvio);

      // let msgReenvio = "";

      // msgReenvio += "Qué tal " + ped.NombreContacto + "?, \n";

      // msgReenvio += "Para la obra *" + ped.Obra + " (" + ped.Cuit + ")*: \n\n";

      // msgReenvio +=
      //   "Quedó pendiente lo siguiente del pedido #" + ped.IDPedido + "\n";
      // msgReenvio +=
      //   "Fecha: " + formatearFecha(ped.FechaProgramada, "texto") + "\n";
      // msgReenvio += "Hora: " + ped.HoraProgramada + "\n\n";

      // // msgReenvio += "Para ver el pedido ingresá al siguiente link: \n";
      // // msgReenvio +=
      // //   "https://plus.bimtracker.com/confirmarPedido/" +
      // //   pedido.codigoPedido +
      // //   "\n\n";

      // msgReenvio += "*Pendiente*: \n";

      // respuesta.result.pedido
      //   .filter((mat) => mat.Estado !== "ENTREGA_COMPLETA")
      //   .map((item, index) =>
      //     item.Estado === "NO_ENTREGO"
      //       ? (msgReenvio +=
      //           item.Cantidad +
      //           " " +
      //           item.Unidad +
      //           " de " +
      //           item.Material +
      //           "\n")
      //       : (msgReenvio +=
      //           item.Cantidad -
      //           item.CantidadRecibida +
      //           " " +
      //           item.Unidad +
      //           " de " +
      //           item.Material +
      //           "\n")
      //   );

      // msgReenvio += "\n";
      // msgReenvio += "\n";

      // msgReenvio += "Pedido enviado desde https://plus.bimtracker.com";

      // setMensajeWhatsappReenvio(msgReenvio);

      // setParamsWhatsapp([
      //   {
      //     whatsapp: ped.Whatsapp,
      //     mensajeWP: msgEnvio,
      //     pedido: respuesta.result.pedido,
      //     setMostrarMensaje: setMostrarMensaje,
      //     setMensaje: setMensaje,
      //     tipo: "ENVIAR_PEDIDO",
      //   },
      //   {
      //     whatsapp: ped.Whatsapp,
      //     mensajeWP: msgReenvio,
      //     pedido: respuesta.result.pedido,
      //     setMostrarMensaje: setMostrarMensaje,
      //     setMensaje: setMensaje,
      //     tipo: "REENVIAR_PEDIDO",
      //   },
      // ]);
    } else {
      console.log("NO TRAE NADA EN traer_pedido()");
      // setState([]);
    }
  };

  const handleClose = (mat) => {
    setOpenEntregaParcial(false);

    setMaterialesSeleccionados([
      ...materialesSeleccionados.filter(
        (item) => item.IDMaterial !== mat.IDMaterial
      ),
    ]);
  };

  const handleConfirmCantidad = (mat) => {
    setMaterialesSeleccionados([
      ...materialesSeleccionados.filter(
        (item) => item.IDMaterial !== mat.IDMaterial
      ),
      {
        IDMaterial: mat.IDMaterial,
        Material: mat.Material,
        Unidad: mat.Unidad,
        Cantidad: mat.Cantidad,
        CantidadRecibida: document.getElementById(mat.IDMaterial).value,
        Estado: "ENTREGA_PARCIAL",
      },
    ]);

    setOpenEntregaParcial(false);
  };

  const handleCheck = (mat, estadoNuevo) => {
    // console.log(estado);
    // console.log(mat.Estado);

    // SI HACE CLICK EN ENTREGA PARCIAL Y EL MATERIAL NO ESTÁ SELECCIONADO, ABRO EL POPUP PARA PONER LA CANTIDAD, SINO LO CIERRO.
    estadoNuevo === "ENTREGA_PARCIAL" &&
    !entregaParcial.some((val) => val.IDMaterial == mat.IDMaterial)
      ? setOpenEntregaParcial(true)
      : estadoNuevo === "ENTREGA_PARCIAL" &&
        entregaParcial.some(
          (val) =>
            val.IDMaterial == mat.IDMaterial && val.Estado !== "ENTREGA_PARCIAL"
        )
      ? setOpenEntregaParcial(true)
      : setOpenEntregaParcial(false);

    // SI HACE CLICK EN ENTREGA PARCIAL SACO EL MATERIAL SI ES QUE EXISTE EN EL ARRAY, SI NO EXISTE AGREGO EL MATERIAL AL ARRAY.
    estadoNuevo === "ENTREGA_PARCIAL"
      ? setEntregaParcial([
          ...entregaParcial.filter(
            (item) => item.IDMaterial !== mat.IDMaterial
          ),
        ])
      : setEntregaParcial([
          ...entregaParcial.filter(
            (item) => item.IDMaterial !== mat.IDMaterial
          ),
          ,
          { IDMaterial: mat.IDMaterial, CantidadRecibida: cantidadRecibida },
        ]);

    // FILTRO EL ESTADO ACTUAL
    let estadoActual = materialesSeleccionados
      .filter((item) => item.IDMaterial == mat.IDMaterial)
      .map((value) => value.Estado)[0];

    // SI EL ESTADO ACTUAL ES EL MISMO QUE EL NUEVO SACO EL MATERIAL DEL ARRAY, SINO ES IGUAL LO AGREGO.
    estadoActual === estadoNuevo
      ? setMaterialesSeleccionados([
          ...materialesSeleccionados.filter(
            (item) => item.IDMaterial !== mat.IDMaterial
          ),
        ])
      : setMaterialesSeleccionados([
          ...materialesSeleccionados.filter(
            (item) => item.IDMaterial !== mat.IDMaterial
          ),
          {
            IDMaterial: mat.IDMaterial,
            Material: mat.Material,
            Unidad: mat.Unidad,
            Cantidad: mat.Cantidad,
            CantidadRecibida:
              estadoNuevo == "ENTREGA_PARCIAL"
                ? "X"
                : estadoNuevo == "NO_ENTREGO"
                ? "0"
                : mat.Cantidad,
            Estado: estadoNuevo,
          },
        ]);
  };

  // const validarCantidad = (event) => {
  //   let cant = event.target.value;
  //   // console.log(cant);

  //   setCantidadRecibida(cant);
  // };

  const validarFormulario = () => {
    if (pedido.length !== materialesSeleccionados.length) {
      setMensaje({ tipo: "error", text: "Complete el formulario." });
      setMostrarMensaje(true);
    } else {
      actualizar_pedido();
    }
  };

  const actualizar_pedido = async () => {
    const API_ENDPOINT = `https://backend.bimtracker.com/api_pedidos.php`;

    // console.log(codigoPedido);

    let sEstado = materialesSeleccionados.some(
      (material) =>
        material.CantidadRecibida == 0 ||
        material.Cantidad > material.CantidadRecibida
    )
      ? "PENDIENTE"
      : "ENTREGADO";

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ACTUALIZAR_PEDIDO",
        idPedido: pedido[0]["IDPedido"],
        estado: sEstado,
        materialesSeleccionados: materialesSeleccionados,
        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.resultado === "OK") {
      //   console.log(respuesta.result.pedido);
      // setPedido(respuesta.result.pedido);

      setMensaje({ tipo: "success", text: "Verificación exitosa!" });
      setMostrarMensaje(true);

      setTimeout(() => {
        navigate("/dashboardPedidos");
      }, 1500);
    } else {
      console.log("NO TRAE NADA EN actualizar_pedido()");
      // setState([]);
    }
  };

  const handleCerrarPedido = () => {
    alert("CERRAR PEDIDO");
  };

  const handleEnviarPedido = async (modo) => {
    // console.log(pedido);

    // let msg = "";

    // if (modo === "ENVIO") {
    //   msg +=
    //     "Qué tal " +
    //     pedido[0].NombreContacto +
    //     "?, \nTe paso un pedido para *" +
    //     pedido[0].Obra +
    //     " (" +
    //     pedido[0].Cuit +
    //     ")*: \n\n";
    //   // msg += "Cant. Volquetes: " + count + "\n\n";
    //   msg +=
    //     "Fecha: " + formatearFecha(pedido[0].FechaProgramada, "texto") + "\n";
    //   msg += "Hora: " + pedido[0].HoraProgramada + "\n\n";
    //   // msg += "Para ver el pedido ingresá al siguiente link: \n";
    //   // msg +=
    //   //   "https://plus.bimtracker.com/confirmarPedido/" +
    //   //   pedido.codigoPedido +
    //   //   "\n\n";

    //   msg += "*Pedido*: \n";

    //   pedido.map(
    //     (item, index) =>
    //       (msg +=
    //         item.Cantidad + " " + item.Unidad + " de " + item.Material + "\n")
    //   );

    //   msg += "\n";
    //   msg += "\n";

    //   msg += "Pedido enviado desde https://plus.bimtracker.com";
    // } else if (modo === "REENVIO") {
    //   msg += "Qué tal " + pedido[0].NombreContacto + "?, \n";

    //   msg +=
    //     "Para la obra *" + pedido[0].Obra + " (" + pedido[0].Cuit + ")*: \n\n";

    //   msg +=
    //     "Quedó pendiente lo siguiente del pedido #" + pedido[0].IDPedido + "\n";
    //   msg +=
    //     "Fecha: " + formatearFecha(pedido[0].FechaProgramada, "texto") + "\n";
    //   msg += "Hora: " + pedido[0].HoraProgramada + "\n\n";

    //   // msg += "Para ver el pedido ingresá al siguiente link: \n";
    //   // msg +=
    //   //   "https://plus.bimtracker.com/confirmarPedido/" +
    //   //   pedido.codigoPedido +
    //   //   "\n\n";

    //   msg += "*Pendiente*: \n";

    //   pedido
    //     .filter((mat) => mat.Estado !== "ENTREGA_COMPLETA")
    //     .map((item, index) =>
    //       item.Estado === "NO_ENTREGO"
    //         ? (msg +=
    //             item.Cantidad +
    //             " " +
    //             item.Unidad +
    //             " de " +
    //             item.Material +
    //             "\n")
    //         : (msg +=
    //             item.Cantidad -
    //             item.CantidadRecibida +
    //             " " +
    //             item.Unidad +
    //             " de " +
    //             item.Material +
    //             "\n")
    //     );

    //   msg += "\n";
    //   msg += "\n";

    //   msg += "Pedido enviado desde https://plus.bimtracker.com";
    // }

    // setWhatsapp(pedido[0].Whatsapp);
    // setMensajeWP(msg);

    // setParamsWhatsappEnvio({
    //   whatsapp: whatsapp,
    //   mensajeWP: mensajeWhatsappEnvio,
    //   pedido: pedido,
    //   setMostrarMensaje: setMostrarMensaje,
    //   setMensaje: setMensaje,
    //   tipo: "ENVIAR_PEDIDO",
    // });

    // setParamsWhatsappReenvio({
    //   whatsapp: whatsapp,
    //   mensajeWP: mensajeWhatsappReenvio,
    //   pedido: pedido,
    //   setMostrarMensaje: setMostrarMensaje,
    //   setMensaje: setMensaje,
    //   tipo: "REENVIAR_PEDIDO",
    // // });

    // console.log("whatsapp: " + whatsapp);
    // console.log("mensajeWhatsappEnvio: " + mensajeWhatsappEnvio);
    // console.log("pedido: " + JSON.stringify(pedido));

    // modo === "ENVIO"
    //   ? setParamsWhatsapp({
    //       whatsapp: whatsapp,
    //       mensajeWP: mensajeWhatsappEnvio,
    //       pedido: pedido,
    //       setMostrarMensaje: setMostrarMensaje,
    //       setMensaje: setMensaje,
    //       tipo: "ENVIAR_PEDIDO",
    //     })
    //   : setParamsWhatsapp({
    //       whatsapp: whatsapp,
    //       mensajeWP: mensajeWhatsappReenvio,
    //       pedido: pedido,
    //       setMostrarMensaje: setMostrarMensaje,
    //       setMensaje: setMensaje,
    //       tipo: "REENVIAR_PEDIDO",
    //     });

    setModoWhatsapp(modo);

    // console.log(msg);

    setMensaje({ tipo: "success", text: "Pedido enviado con éxito!" });
    setMostrarMensaje(true);

    setTimeout(() => {
      navigate("/dashboardPedidos");
    }, 1500);
  };

  // const botones = [
  //   {
  //     titulo: "Cerrar pedido",
  //     tipo: "BOTON",
  //     funcion: () => handleCerrarPedido(),
  //   },
  //   {
  //     titulo: "Enviar pedido",
  //     tipo: "WHATSAPP",
  //     paramsWhatsapp: paramsWhatsappEnvio,
  //     funcion: () => handleEnviarPedido("ENVIO"),
  //   },
  //   {
  //     titulo: "Reenviar pedido",
  //     tipoBoton: "WHATSAPP",
  //     paramsWhatsapp: paramsWhatsappReenvio,
  //     funcion: () => handleEnviarPedido("REENVIO"),
  //   },
  // ];

  return (
    <>
      <div>
        <AlertValidConfig
          mostrarMensaje={mostrarMensaje}
          mensaje={mensaje.text}
          tipo={mensaje.tipo}
        />

        <Grid container columns={{ xs: 12 }} border={0}>
          <Grid item xs={10} border={0}>
            <Typography variant="h6">PEDIDO #{idPedido}</Typography>
          </Grid>

          {screenWidth === "xl" ||
          screenWidth === "lg" ||
          screenWidth === "md" ? (
            <>
              <EncabezadoWeb encabezadoPedido={encabezadoPedido} />
              {/* {console.log("hola: ")} */}
              {/* {console.log(paramsWhatsapp)} */}

              {/* {console.log("botones: ", botones)} */}
              {paramsWhatsapp !== [] ? (
                <Botonera
                  botonera={botones}
                  paramsWhatsapp={
                    paramsWhatsapp

                    // paramsWhatsapp.filter(
                    //   (param) => param.tipo === "ENVIAR_PEDIDO"
                    // )

                    // modoWhatsapp === "ENVIO"
                    //   ? paramsWhatsappEnvio
                    //   : paramsWhatsappReenvio
                  }
                />
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <EncabezadoSmartphone encabezadoPedido={encabezadoPedido} />

              <Botonera
                botonera={botones}
                paramsWhatsapp={
                  paramsWhatsapp

                  // paramsWhatsapp.filter(
                  //   (param) => param.tipo === "ENVIAR_PEDIDO"
                  // )

                  // modoWhatsapp === "ENVIO"
                  //   ? paramsWhatsappEnvio
                  //   : paramsWhatsappReenvio
                }
              />
            </>
          )}

          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                // flexWrap: "wrap",
                alignContent: "center",
                justifyContent: "center",
                p: 1,
                m: 1,
                mt: 2,
                // border: 1,
                bgcolor: "background.paper",
                // maxWidth: 380,
                // width: { xs: 300, md: 400 },
                height: "auto",
                borderRadius: 1,
                // border: "1px solid #FF0000",
              }}
            >
              <Box
                component="form"
                sx={{
                  height: 40,
                  width: "100%",
                  // border: 1,
                  marginTop: 2,
                  justifyContent: "center",
                  alignItems: "center",
                  // border: "1px solid #FF0000",
                }}
              >
                {pedido.map((item, index) => (
                  <div
                    key={item.IDPedidoMaterial}
                    style={{
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                      // border: "1px solid #FF0000",
                    }}
                  >
                    {
                      //  <Checkbox
                      //   id={item.IDMaterial}
                      //   key={item.IDMaterial}
                      //   icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
                      //   checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                      // // checked={seleccionados.some(
                      // //   (item) => item.IDDocumentacion == docs.IDDocumentacion
                      // // )}
                      //   onChange={(event) => handleChange(event, item)}
                      // />
                    }

                    <Item
                      // sx={{ width: { xs: 300, md: 500 } }}
                      sx={{ width: { xs: "90%", textAlign: "center" } }}
                      style={{
                        marginBottom: "15px",
                        padding: "15px",
                        //   paddingRight: "5px",

                        fontSize: "22px",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <Grid
                        container
                        style={{
                          // border: "1px solid #000000",
                          marginBottom: "10px",
                          justifyContent: "center",
                          // border: "1px solid #FF0000",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          style={{
                            padding: "15px",
                            paddingRight: "5px",
                            fontSize: "22px",
                            textAlign: "left",
                          }}
                        >
                          {item.Cantidad +
                            " " +
                            item.Unidad +
                            " de " +
                            item.Material}
                          <br />
                          <span
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            style={{
                              // padding: "15px",
                              paddingRight: "5px",
                              fontSize: "16px",
                              textAlign: "left",

                              // color: "green",
                            }}
                          >
                            {materialesSeleccionados.length > 0 &&
                            materialesSeleccionados.some(
                              (mat) =>
                                mat.IDMaterial == item.IDMaterial &&
                                mat.CantidadRecibida !== "X"
                            )
                              ? filtrar_materiales(item).CantidadRecibida +
                                " recibidos"
                              : "Seleccione una opción"}
                          </span>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={10}
                          md={4}
                          lg={4}
                          xl={4}
                          style={{
                            padding: "15px",
                            paddingRight: "5px",
                          }}
                        >
                          <Chip
                            variant="outlined"
                            color="success"
                            icon={
                              materialesSeleccionados
                                .filter(
                                  (material) =>
                                    material.IDMaterial === item.IDMaterial
                                )
                                .map(
                                  (value) => value.Estado == "ENTREGA_COMPLETA"
                                )[0] ? (
                                <>
                                  <CheckCircleOutlinedIcon
                                    sx={{ fontSize: 35 }}
                                  />
                                </>
                              ) : (
                                <>
                                  <RadioButtonUncheckedIcon
                                    sx={{ fontSize: 35 }}
                                  />
                                </>
                              )
                            }
                            // iconChecked={
                            //   <CheckCircleOutlinedIcon sx={{ fontSize: 35 }} />
                            // }
                            label="Entrega completa"
                            onClick={() =>
                              handleCheck(item, "ENTREGA_COMPLETA")
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          style={{
                            padding: "15px",
                            paddingRight: "5px",
                          }}
                        >
                          <Chip
                            variant="outlined"
                            color="warning"
                            icon={
                              materialesSeleccionados
                                .filter(
                                  (material) =>
                                    material.IDMaterial === item.IDMaterial
                                )
                                .map(
                                  (value) => value.Estado == "ENTREGA_PARCIAL"
                                )[0] ? (
                                <CheckCircleOutlinedIcon
                                  sx={{ fontSize: 35 }}
                                />
                              ) : (
                                <RadioButtonUncheckedIcon
                                  sx={{ fontSize: 35 }}
                                />
                              )
                            }
                            label="Entrega parcial"
                            onClick={() => handleCheck(item, "ENTREGA_PARCIAL")}
                          />

                          {materialesSeleccionados.some(
                            (mat) =>
                              mat.IDMaterial == item.IDMaterial &&
                              mat.Estado === "ENTREGA_PARCIAL"
                          ) ? (
                            <Dialog open={openEntregaParcial}>
                              <DialogTitle>
                                {item.Unidad + " " + item.Material}
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText>
                                  Ingrese la cantidad recibida en obra
                                </DialogContentText>
                                <br />
                                <TextField
                                  autoFocus
                                  margin="dense"
                                  id={item.IDMaterial}
                                  label="Cantidad recibida"
                                  type="number"
                                  fullWidth
                                  variant="standard"
                                  // onChange={validarCantidad}
                                />
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={() => handleClose(item)}>
                                  Cancelar
                                </Button>
                                <Button
                                  onClick={() => handleConfirmCantidad(item)}
                                >
                                  Confirmar
                                </Button>
                              </DialogActions>
                            </Dialog>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          style={{
                            padding: "15px",
                            paddingRight: "5px",
                          }}
                        >
                          <Chip
                            variant="outlined"
                            color="error"
                            icon={
                              materialesSeleccionados
                                .filter(
                                  (material) =>
                                    material.IDMaterial === item.IDMaterial
                                )
                                .map(
                                  (value) => value.Estado == "NO_ENTREGO"
                                )[0] ? (
                                <CheckCircleOutlinedIcon
                                  sx={{ fontSize: 35 }}
                                />
                              ) : (
                                <RadioButtonUncheckedIcon
                                  sx={{ fontSize: 35 }}
                                />
                              )
                            }
                            label="No entregó"
                            onClick={() => handleCheck(item, "NO_ENTREGO")}
                          />
                        </Grid>
                      </Grid>
                    </Item>
                  </div>

                  // <Grid item xs={9} sm={9} md={9} lg={10}>
                  //   <Stack spacing={1} sx={{ width: "95%" }}>
                  //     {item.Material}
                  //   </Stack>
                  // </Grid>
                ))}

                {pedido.length > 0 ? (
                  <>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        // flexWrap: "wrap",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={() => validarFormulario()}
                      >
                        Confirmar
                      </Button>
                    </div>
                    <br />
                    <br />
                  </>
                ) : (
                  // navigate("/verificarPedido")
                  ""
                )}
              </Box>
              {/* </Item> */}
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PageVerificarPedido;
