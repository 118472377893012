import { Dashboard } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import DashboardContext from "../../contextos/DashboardContext";
import ObraContext from "../../contextos/ObraContext";
import { desencriptar } from "../../funciones/Funciones";
import BoxContador from "../general/BoxContador";

const PageDashboardVolquetes = (props) => {
  let navigate = useNavigate();

  const { dashboard, setDashboard } = useContext(DashboardContext);
  const { obra, setObra } = useContext(ObraContext);

  const [volquetes, setVolquetes] = useState([]);

  useEffect(() => {
    props.setTitulo("Administración de volquetes");
    props.setPagina("altaVolquete");
  }, [props.setTitulo]);

  useEffect(() => {
    // console.log(dashboard);

    let dashTotales;
    let dashPendientes;
    let dashConfirmados;

    if (dashboard.listaVolquetes == undefined) {
      let objdashboard = desencriptar(localStorage.getItem("objdashboard"));

      setDashboard(objdashboard);

      dashTotales = objdashboard.listaVolquetes.filter(
        (obr) => obr.IDObra == obra.IDObra
      );
    } else {
      dashTotales = dashboard.listaVolquetes.filter(
        (obr) => obr.IDObra == obra.IDObra
      );
    }

    dashPendientes = dashTotales.filter((obr) => obr.Recibido == 0);
    dashConfirmados = dashTotales.filter((obr) => obr.Confirmacion == 1);

    let cantVolquetesTotales = 0;
    let cantVolquetesPendientes = 0;
    let cantVolquetesConfirmados = 0;

    dashTotales.map((vol) => {
      cantVolquetesTotales =
        parseInt(cantVolquetesTotales) + parseInt(vol.Cantidad);
    });

    dashPendientes.map((vol) => {
      cantVolquetesPendientes =
        parseInt(cantVolquetesPendientes) + parseInt(vol.Cantidad);
    });

    dashConfirmados.map((vol) => {
      cantVolquetesConfirmados =
        parseInt(cantVolquetesConfirmados) + parseInt(vol.Cantidad);
    });

    console.log(dashTotales);

    setVolquetes(...volquetes, {
      cantidadVolquetesTotales: cantVolquetesTotales,
      cantidadVolquetesPendientes: cantVolquetesPendientes,
      cantidadVolquetesConfirmados: cantVolquetesConfirmados,
    });
  }, []);

  useEffect(() => {
    console.log(volquetes);
  }, [volquetes]);

  const handleAltaVolquete = () => {
    navigate("/altaVolquete");
  };

  return (
    <>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12,
        }}
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <BoxContador
          titulo="Totales"
          imagen="volquete.png"
          cantidad={volquetes.cantidadVolquetesTotales}
        />
        <BoxContador
          titulo="Confirmados"
          imagen="volquete.png"
          cantidad={volquetes.cantidadVolquetesConfirmados}
        />
        <BoxContador
          titulo="Pendientes"
          imagen="volquete.png"
          cantidad={volquetes.cantidadVolquetesPendientes}
        />
      </Grid>
    </>
  );
};

export default PageDashboardVolquetes;
