import React, { useContext, useEffect, useState } from "react";
import { Box, Button, TextField, Paper, Grid, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";

import MensajesContext from "../../contextos/MensajesContext";
import AlertValidConfig from "../../components/AlertValidConfig";
import { useNavigate } from "react-router-dom";
import EncabezadoItems from "../../components/EncabezadoItems";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const PageOlvideClave = (props) => {
  const [email, setEmail] = useState({ text: "", valid: false, error: "" });

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } = useContext(
    MensajesContext
  );

  let navigate = useNavigate();

  const styles = {
    container: {
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    error: {
      color: "#FF0000",
      marginBottom: 10,
      alignItems: "flex-start",
      width: "70%",
      // borderWidth: 1,
      // borderColor: '#FFFFFF',
    },
  };

  useEffect(() => {
    props.setTitulo("Recuperar contraseña");
    props.setPagina("");

    // console.log("A");
  }, [props.setTitulo]);

  const validarFormulario = () => {
    if (email.valid) {
      recuperar_clave();
    } else {
      setMensaje({ text: "Datos incorrectos", tipo: "error" });
      setMostrarMensaje(true);
    }
  };

  const validarEmail = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;
    // console.log(text);

    // VALIDACIÓN DE USUARIO
    // if (text.length > 2) {
    //   setEmail({ text: text, valid: true, error: "" });
    // } else {
    //   setEmail({
    //     text: text,
    //     valid: false,
    //     error: "Email o usuario incorrecto",
    //   });
    // }

    // VALIDACIÓN DE MAIL
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      if (text.length == 0) {
        // Email vacío
        setEmail({ text: text, valid: false, error: "" });
      } else {
        setEmail({ text: text, valid: false, error: "Email incorrecto" });
      }

      return false;
    } else {
      // console.log(text.length);

      // console.log("Email is Correct");
      setEmail({ text: text, valid: true, error: "" });
    }
  };

  const recuperar_clave = async () => {
    try {
      const API_ENDPOINT = `https://backend.bimtracker.com/api_usuarios.php`;
      // var base64 = require("base-64");
      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));
      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "RECUPERAR_CLAVE",
          email: email.text,
        }),
        // headers: headers,
        //credentials: 'user:passwd'
      });
      const usuarios = await datos.json();

      if (usuarios.result.respuesta === "USUARIO_OK") {
        setMensaje({
          text: "Te hemos enviado un E-mail para recuperar tu contraseña.",
          tipo: "success",
        });
        setMostrarMensaje(true);

        setTimeout(() => {
          props.setMostrar("FORMULARIO_LOGIN");
          navigate("/");
        }, 3000);
      } else {
        setMensaje({
          text: "El E-mail ingresado no corresponde a un usuario registrado.",
          tipo: "error",
        });
        setMostrarMensaje(true);
      }
      return usuarios.result;
    } catch (error) {
      console.error(error);
    }

    return "ERROR";
  };

  const handleBack = () => {
    props.setMostrar("FORMULARIO_LOGIN");
    navigate("/");
  };

  return (
    <div>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <Box
        sx={{
          display: "flex",
          // flexWrap: "wrap",
          alignContent: "center",
          justifyContent: "center",
          p: 1,
          m: 1,
          mt: 20,
          // border: 1,
          bgcolor: "background.paper",
          // maxWidth: 380,
          // width: { xs: 300, md: 400 },
          height: 300,
          borderRadius: 1,
        }}
      >
        <Item sx={{ width: { xs: 350, md: 350 } }}>
          {/* <Grid container xs={12}>
            <Grid item xs={1}>
              <IconButton
                color="inherit"
                // onClick={handleAltaPedido}
                aria-label="volver"
                size="large"
              >
                <ArrowBackIcon
                  // fontSize="large"
                  fontSize="inherit"
                  // sx={{ color: "#FFFFFF" }}
                  onClick={() => handleBack()}
                />
              </IconButton>
            </Grid>
            <Grid item xs={10}>
              <Box
                component="img"
                sx={{
                  // border: "1px solid #000000",
                  height: 48,
                  width: 226,
                  maxHeight: { xs: 48, md: 72 },
                  maxWidth: { xs: 226, md: 339 },
                  mt: 1,
                }}
                alt="Bimtracker"
                src="bimtracker.png"
              />
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
          <div style={{ fontWeight: "bold", fontSize: "18px" }}>
            RECUPERAR CONTRASEÑA
          </div> */}

          <EncabezadoItems
            setMostrar={props.setMostrar}
            titulo="OLVIDÉ MI CONTRASEÑA"
            pageBack="FORMULARIO_LOGIN"
          />
          <Box
            component="form"
            sx={{
              height: 40,
              width: "100%",
              // border: 1,
              marginTop: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              required
              id="standard-required"
              label="E-mail"
              defaultValue=""
              variant="standard"
              style={{ width: "80%" }}
              onChange={validarEmail}
            />
            <div style={styles.error}>{email.error}</div>
            <br />
            <br />
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              onClick={() => validarFormulario()}
              // sx={{ "--IconButton-size": "96px" }}
            >
              Recuperar clave
            </Button>
          </Box>
        </Item>
      </Box>
    </div>
  );
};

export default PageOlvideClave;
