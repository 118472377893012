import { desencriptar } from "./Funciones";

const eliminar_sociedad = async () => {
  let objobra = desencriptar(localStorage.getItem("objobra"));
  let objsoc = desencriptar(localStorage.getItem("objsoc"));

  const API_ENDPOINT = `https://backend.bimtracker.com/api_hys.php`;

  const datos = await fetch(API_ENDPOINT, {
    method: "POST",
    // headers: {
    //   Accept: "application/json",
    //   "Content-Type": "application/json",
    // },
    body: JSON.stringify({
      operacion: "BAJA_SOCIEDAD_EN_HYS",
      idObra: objobra.IDObra,
      idSubcontrato: objsoc.IDSubcontrato,

      // operacion: "LISTAR_PRESUPUESTO",
      //  idObra: obra.IDObra,
      // lista:
      //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
    }),
  });
  const respuesta = await datos.json();

  // console.log(respuesta);

  if (respuesta.result.respuesta === "BAJA_OK") {
    // console.log(respuesta.result.respuesta);

    localStorage.removeItem("objsoc");

    // setAsignaciones(respuesta.result.asignaciones);
    // setSolicitudes(respuesta.result.solicitudes);
  } else {
    console.log("NO TRAE NADA EN eliminar_sociedad()");
  }
};

const eliminar_documento = async () => {
  let objobra = desencriptar(localStorage.getItem("objobra"));
  let objdocs = desencriptar(localStorage.getItem("objdocs"));

  const API_ENDPOINT = `https://backend.bimtracker.com/api_hys.php`;

  const datos = await fetch(API_ENDPOINT, {
    method: "POST",
    // headers: {
    //   Accept: "application/json",
    //   "Content-Type": "application/json",
    // },
    body: JSON.stringify({
      operacion: "BAJA_DOCUMENTO_EN_HYS",
      idObra: objobra.IDObra,
      idDocumentacion: objdocs.IDDocumentacion,

      // operacion: "LISTAR_PRESUPUESTO",
      //  idObra: obra.IDObra,
      // lista:
      //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
    }),
  });
  const respuesta = await datos.json();

  // console.log(respuesta);

  if (respuesta.result.respuesta === "BAJA_OK") {
    // console.log(respuesta.result.respuesta);

    localStorage.removeItem("objdocs");

    // setAsignaciones(respuesta.result.asignaciones);
    // setSolicitudes(respuesta.result.solicitudes);
  } else {
    console.log("NO TRAE NADA EN eliminar_documento()");
  }
};

const eliminar_invitado = async (invitado) => {
  let objobra = desencriptar(localStorage.getItem("objobra"));
  // let objsoc = desencriptar(localStorage.getItem("objsoc"));

  const API_ENDPOINT = `https://backend.bimtracker.com/api_usuarios.php`;

  const datos = await fetch(API_ENDPOINT, {
    method: "POST",
    // headers: {
    //   Accept: "application/json",
    //   "Content-Type": "application/json",
    // },
    body: JSON.stringify({
      operacion: "BAJA_INVITADO_DE_OBRA",
      idObra: objobra.IDObra,
      idUsuario: invitado.IDUsuario,

      // operacion: "LISTAR_PRESUPUESTO",
      //  idObra: obra.IDObra,
      // lista:
      //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
    }),
  });
  const respuesta = await datos.json();

  // console.log(respuesta);

  if (respuesta.result.respuesta === "BAJA_OK") {
    // console.log(respuesta.result.respuesta);
    // localStorage.removeItem("objsoc");
    // setAsignaciones(respuesta.result.asignaciones);
    // setSolicitudes(respuesta.result.solicitudes);
  } else {
    console.log("NO TRAE NADA EN eliminar_invitado()");
  }
};

export { eliminar_sociedad, eliminar_documento, eliminar_invitado };
