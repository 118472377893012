import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, ButtonGroup, Checkbox } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import HySContext from "../../contextos/HySContext";
import ObraContext from "../../contextos/ObraContext";
import Titulo from "../../components/Titulo";
import { desencriptar } from "../../funciones/Funciones";
import BotoneraABM from "../../components/BotoneraABM";

const PageSettingsDocumento = (props) => {
  const [mostrarProgress, setMostrarProgress] = useState(false);
  const [seleccionados, setSeleccionados] = useState([]);

  const {
    documentos,
    setDocumentos,
    asignaciones,
    setAsignaciones,
    modificar,
    setModificar,
  } = useContext(HySContext);

  const { obra, setObra } = useContext(ObraContext);

  useEffect(() => {
    props.setTitulo("Sociedades y subcontratos");
    props.setPagina("");

    // console.log(obra.IDObra);
    // console.log(modificar.IDSubcontrato);

    if (documentos.IDDocumentacion === undefined) {
      // console.log("PASA POR IDDocumentacion");
      let objdocs = desencriptar(localStorage.getItem("objdocs"));
      setDocumentos(objdocs);
      setModificar(objdocs);
    }

    if (obra.IDObra === undefined) {
      // console.log("PASA POR IDObra");
      let objobra = desencriptar(localStorage.getItem("objobra"));
      setObra(objobra);
    }

    // if (modificar.IDSubcontrato === undefined) {
    //   // console.log("PASA POR IDSubcontrato");
    //   let objsoc = desencriptar(localStorage.getItem("objsoc"));
    //   setModificar(objsoc);
    // }

    // if (modificar.IDDocumentacion === undefined) {
    //   let objdocs = desencriptar(localStorage.getItem("objdocs"));
    //   setModificar(objdocs);
    // }
  }, []);

  // useEffect(() => {
  //   listar_asignaciones();
  // }, [documentos, obra, modificar]);

  // const listar_asignaciones = async () => {
  //   // console.log(lista);

  //   setMostrarProgress(true);

  //   // console.log("IDObra: " + obra.IDObra);
  //   // console.log("IDSubcontrato: " + modificar.IDSubcontrato);

  //   const API_ENDPOINT = `https://backend.bimtracker.com/api_hys.php`;

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       operacion: "LISTAR_ASIGNACIONES",
  //       idObra: obra.IDObra,
  //       idSubcontrato: modificar.IDSubcontrato,
  //     }),
  //   });
  //   const respuesta = await datos.json();

  //   // console.log(respuesta);

  //   if (respuesta.result.asignaciones) {
  //     // console.log(respuesta.result.asignaciones);
  //     // setAsignaciones(respuesta.result.asignaciones);
  //     setSeleccionados(respuesta.result.asignaciones);

  //     // alert("Lista de precios actualizada.");
  //   } else {
  //     console.log("NO TRAE NADA EN: listar_asignaciones()");
  //     // setState([]);
  //   }

  //   setMostrarProgress(false);
  // };

  // const handleChange = async (event, item) => {
  //   // console.log(item);

  //   // console.log("IDObra: " + obra.IDObra);
  //   // console.log("IDSubcontrato: " + modificar.IDSubcontrato);
  //   // console.log("IDDocumentacion: " + item.IDDocumentacion);
  //   // console.log("checked: " + event.target.checked);

  //   const API_ENDPOINT = `https://backend.bimtracker.com/api_hys.php`;

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       operacion: event.target.checked ? "ALTA_ASIGNACION" : "BAJA_ASIGNACION",
  //       idObra: obra.IDObra,
  //       idSubcontrato: modificar.IDSubcontrato,
  //       idDocumentacion: item.IDDocumentacion,
  //       fechaImpreso: "",
  //       fechaVencimiento: "",
  //     }),
  //   });
  //   const respuesta = await datos.json();

  //   // console.log("PASA POR 1");
  //   // console.log(respuesta.result.asignaciones);
  //   // console.log("PASA POR 2");

  //   if (respuesta.result.asignaciones) {
  //     // console.log(respuesta.result.asignaciones);
  //     setAsignaciones(respuesta.result.asignaciones);
  //     setSeleccionados(respuesta.result.asignaciones);

  //     // alert("Lista de precios actualizada.");
  //   } else {
  //     setAsignaciones([]);
  //     setSeleccionados([]);
  //     console.log("NO TRAE NADA EN: handeChange()");
  //     // setState([]);
  //   }
  // };

  // console.log(documentos);
  // console.log(asignaciones);

  return (
    <div>
      <Titulo
        texto={modificar.Documento}
        botones={{
          seccion: "SETTINGS-DOCUMENTO",
          alta: false,
          baja: true,
          modif: true,
        }}
      />
    </div>
  );
};

export default PageSettingsDocumento;
