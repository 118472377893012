const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function randomColor() {
  return `hsl(${Math.floor(Math.random() * 360)}, 95%, 90%)`;
}

const chequearPermisos = (privilegios, permiso) => {
  var encontrado;

  // console.log(privilegios);
  if (privilegios === undefined || privilegios === null) {
    encontrado = false;
  } else {
    var filterData = privilegios.split("|");

    encontrado = false;

    filterData.map((item) => {
      // console.log("|" + item + "|");
      if (item === permiso || item === "TODOS") {
        encontrado = true;
      }
    });
  }

  return encontrado;
};

const encriptar = (toEncode) => {
  // "toEncode" puede ser un texto o un objeto

  let text =
    toEncode != null && toEncode !== "" ? btoa(JSON.stringify(toEncode)) : "";

  return text;
};

const desencriptar = (toDecode) => {
  // "toDecode" puede ser un texto o un objeto
  // console.log(toDecode != null && toDecode !== "");
  let text =
    toDecode != null && toDecode !== "" ? JSON.parse(atob(toDecode)) : "";

  return text;
};

const getLocalItem = (item) => {
  return desencriptar(localStorage.getItem(item));
};

const formatearFecha = (date, format = "yyyy-MM-dd") => {
  // console.log("date: " + date);

  // if (date) {
  //   // FORMATO 2023-01-20

  //   let day = date.substring(8);
  //   let month = date.substring(6, 7);
  //   let year = date.substring(0, 4);

  // } else {
  let dateNew = new Date(date);

  // console.log("dateNew: " + dateNew);

  let day = dateNew.getDate();
  let month = dateNew.getMonth() + 1;
  let year = dateNew.getFullYear();
  // }
  let mesSeleccionado = month * 1;

  // // console.log(date + " | " + day);

  if (day < 10) {
    day = "0" + day;
  }

  if (month < 10) {
    month = "0" + month;
  }

  // console.log(`${day}/${month}/${year}`);

  let fecha = "";

  if (format == "dd/MM/yyyy") {
    fecha = `${day}/${month}/${year}`;
  } else if (format == "texto") {
    let mes = [
      "",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    fecha = `${day} de ${mes[mesSeleccionado]} de ${year}`;
  } else {
    fecha = `${year}-${month}-${day}`;
  }

  return fecha;
};

const formatearHora = (date, format = "HH:mm") => {
  // let date = new Date();

  let hours = date.getHours();
  let minutes = date.getMinutes();

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes === 0 ? "00" : minutes;

  return hours + ":" + minutes;
};

// const { usuario, setUsuario } = useContext(UsuarioContext);

export {
  numberWithCommas,
  randomColor,
  chequearPermisos,
  encriptar,
  desencriptar,
  formatearFecha,
  formatearHora,
  getLocalItem,
};
