import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { DragDropContext } from "react-beautiful-dnd";
import Boton from "../../components/Boton";
// import styled from "styled-components";

import Column from "./Column";

// import { OutTable, ExcelRenderer } from "react-excel-renderer";
import SendIcon from "@mui/icons-material/Send";
import Botonera from "../../components/Botonera";
import ObraContext from "../../contextos/ObraContext";
import { Navigate, useNavigate } from "react-router-dom";
import Encabezado from "./Encabezado";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ModalAsignTarea from "./ModalAsignTarea";
import CuadroImportarTareas from "./CuadroImportarTareas";
import {
  chequearPermisos,
  desencriptar,
  numberWithCommas,
} from "../../funciones/Funciones";
import BoxResumenPresupuesto from "./BoxResumenPresupuesto";
import UsuarioContext from "../../contextos/UsuarioContext";

const PagePresupuestoActual = (props) => {
  const { obra, setObra } = useContext(ObraContext);
  const { usuario, setUsuario } = useContext(UsuarioContext);

  const [refresh, setRefresh] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    // console.log("pasa por useEffect");
    props.setTitulo("Presupuesto al día de hoy");
    props.setPagina("");

    if (obra.IDObra === undefined) {
      navigate("/presupuestos");
    }

    listar_presupuesto();
  }, []);

  useEffect(() => {
    // console.log("PASA");
    listar_presupuesto();

    // return () => {

    // }
  }, [refresh]);

  const [items, setItems] = useState([]);
  const [subtotalMateriales, setSubtotalMateriales] = useState(0);
  const [subtotalTrabajadores, setSubtotalTrabajadores] = useState(0);
  const [total, setTotal] = useState(0);

  //   const handleChange = (event) => {
  //     let textoFormateado = event.target.value
  //       .replaceAll("\n", ";;\n")
  //       .replaceAll("	", ";")
  //       .replaceAll("$ ", "")
  //       // .replaceAll(/"/g, "''");
  //       // .replaceAll(/"/g, "&lsquo;");
  //       .replace(/"/g, "&quot;");
  //     setItems(textoFormateado);
  //     // console.log(event.target.value);
  //   };

  const listar_presupuesto = async () => {
    // console.log(texto);
    // console.log("listo presupuesto");

    setMostrarProgress(true);

    const API_ENDPOINT = `https://backend.bimtracker.com/api_presupuestos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_PRESUPUESTO",
        idObra: obra.IDObra,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.items) {
      // console.log("items: " + respuesta.result.items);
      //   setTexto("");

      setMostrarPresupuesto(true);
      setItems(respuesta.result.items);
      setSubtotalMateriales(respuesta.result.subtotalMateriales);
      setSubtotalTrabajadores(respuesta.result.subtotalTrabajadores);
      setTotal(respuesta.result.total);

      const arrObra = {
        IDObra: obra.IDObra,
        Obra: obra.Obra,
        Privilegios: obra.Privilegios,
        SubtotalMateriales: respuesta.result.subtotalMateriales,
        SubtotalTrabajadores: respuesta.result.subtotalTrabajadores,
        Total: respuesta.result.total,
      };

      // console.log(arrObra);
      // let objobra = desencriptar(localStorage.getItem("objobra"));

      setObra(arrObra);

      // console.log(respuesta.result.subtotalMateriales);
      // console.log(respuesta.result.subtotalTrabajadores);
      // console.log(respuesta.result.total);
      //   alert("Tareas actualizadas.");
    } else {
      setMostrarPresupuesto(false);
      console.log("NO TRAE NADA EN listar_presupuesto()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  //   const guardarPresupuesto = async () => {
  //     console.log(items);

  //     const API_ENDPOINT = `https://backend.bimtracker.com/api_presupuestos.php`;

  //     const datos = await fetch(API_ENDPOINT, {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         operacion: "LISTAR_CUANTIFICACIONES",
  //         lista: items,
  //         // lista:
  //         //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
  //       }),
  //     });
  //     const respuesta = await datos.json();

  //     // console.log(respuesta);

  //     if (respuesta.result.cantidadInsertada) {
  //       console.log("cantidadInsertada: " + respuesta.result.cantidadInsertada);
  //       setItems("");
  //       alert("Tareas actualizadas.");
  //     } else {
  //       console.log("NO TRAE NADA.");
  //       // setState([]);
  //     }
  //   };

  const fechaHoy = () => {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (day < 10) {
      day = "0" + day;
    }

    if (month < 10) {
      month = "0" + month;
    }

    // console.log(`${day}/${month}/${year}`);

    let fecha = `${day}/${month}/${year}`;

    return fecha;
  };

  // const [codTarea, setCodTarea] = useState();
  const [tarea, setTarea] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [estado, setEstado] = useState("ALTA");
  const [mostrarPresupuesto, setMostrarPresupuesto] = useState(false);
  const [mostrarProgress, setMostrarProgress] = useState(false);

  const handleOpenAsign = (rowTarea) => {
    // setCodManoDeObra(codigoMO);
    setTarea(rowTarea);

    setOpenModal(true);
  };

  // function numberWithCommas(x) {
  //   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }

  return (
    <div>
      <Encabezado />
      <br />
      <br />
      {mostrarPresupuesto ? (
        <Grid container columns={{ xs: 12 }} border={0}>
          <Grid item xs={10} border={0}>
            <Typography variant="h6">PRESUPUESTO {fechaHoy()}</Typography>
          </Grid>

          <Grid item xs={12} border={0} mb={2}>
            <BoxResumenPresupuesto />
          </Grid>

          <Grid item xs={12} border={1}>
            {/* {items.map((row) => ( */}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Cod. Tarea</TableCell>
                    <TableCell align="left">Tarea</TableCell>
                    <TableCell align="left">Cantidad</TableCell>
                    <TableCell align="center">Costo Materiales</TableCell>
                    <TableCell align="center">Costo Mano de Obra</TableCell>
                    <TableCell align="center">Subtotal</TableCell>
                    <TableCell align="center">Total</TableCell>
                    <TableCell align="center">Asignar Recursos</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((row) => (
                    <TableRow
                      key={row.IDTarea}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.CodTarea}
                      </TableCell>
                      <TableCell align="left">
                        {row.Tarea}{" "}
                        {row.Cantidad > 0 ? "" : "[" + row.Unidad + "]"}
                      </TableCell>
                      <TableCell align="left">
                        {row.Cantidad > 0
                          ? numberWithCommas(
                              Math.round(row.Cantidad * 100) / 100
                            )
                          : ""}{" "}
                        {row.Cantidad > 0 ? "[" + row.Unidad + "]" : ""}
                      </TableCell>

                      <TableCell align="center">
                        {row.CostoMateriales > 0
                          ? "$ " +
                            numberWithCommas(
                              Math.round(row.CostoMateriales * 100) / 100
                            )
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        {row.CostoTrabajadores > 0
                          ? "$ " +
                            numberWithCommas(
                              Math.round(row.CostoTrabajadores * 100) / 100
                            )
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        {row.CostoMateriales > 0 || row.CostoTrabajadores > 0
                          ? numberWithCommas(
                              "$ " +
                                Math.round(
                                  (+row.CostoMateriales +
                                    +row.CostoTrabajadores) *
                                    100
                                ) /
                                  100
                            )
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        {(row.CostoMateriales > 0 ||
                          row.CostoTrabajadores > 0) &&
                        row.Cantidad > 0
                          ? numberWithCommas(
                              "$ " +
                                Math.round(
                                  (+row.CostoMateriales +
                                    +row.CostoTrabajadores) *
                                    row.Cantidad *
                                    100
                                ) /
                                  100
                            )
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        {row.NoBorrar === null || row.NoBorrar === "1" ? (
                          chequearPermisos(
                            usuario.Privilegios,
                            "PRESUPUESTOS_ASIGNAR_CUANTIFICACION"
                          ) === true ? (
                            <IconButton aria-label="delete" size="medium">
                              <EditOutlinedIcon
                                fontSize="inherit"
                                onClick={() => handleOpenAsign(row)}
                              />
                            </IconButton>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} border={0}></Grid>
          <Grid item xs={12} border={1}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  <TableRow
                    key="SubtotalMateriales"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="right">
                      {" "}
                      Subtotal Materiales:
                      {numberWithCommas(
                        " $ " + Math.round(subtotalMateriales * 100) / 100
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="SubtotalTrabajadores"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="right">
                      {" "}
                      Subtotal Trabajadores:
                      {numberWithCommas(
                        " $ " + Math.round(subtotalTrabajadores * 100) / 100
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="Total"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="right">
                      {" "}
                      Total:
                      {numberWithCommas(" $ " + Math.round(total * 100) / 100)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} border={0}></Grid>

          <Grid item xs={12} border={0} mt={2}>
            <BoxResumenPresupuesto />
          </Grid>

          <Grid item xs={10} border={0}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              {/* <Grid item xs={12}>
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                onClick={() => guardarPresupuesto()}
              >
                Guardar
              </Button>
            </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      ) : mostrarProgress ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <CuadroImportarTareas />
      )}

      {openModal ? (
        <ModalAsignTarea
          openModal={openModal}
          setOpenModal={setOpenModal}
          tarea={tarea}
          estado={estado}
          setEstado={setEstado}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default PagePresupuestoActual;
