import React, { useContext, useEffect, useState } from "react";
import UsuarioContext from "../../contextos/UsuarioContext";
import Navbar from "../../components/Navbar";
import PageFormularioLogin from "./PageFormularioLogin";
import { Box, CircularProgress } from "@mui/material";
import { desencriptar, encriptar } from "../../funciones/Funciones";
import PageRegistro from "../general/PageRegistro";
import PageOlvideClave from "../general/PageOlvideClave";
import PageRecuperarClave from "../general/PageRecuperarClave";
import PageSeleccionarModulos from "../general/PageSeleccionarModulos";
import PagePago from "../general/PagePago";
import PageConfirmarPedido from "../proveedores/PageConfirmarPedido";

const PageLogin = () => {
  const { usuario, setUsuario, tokenValido, setTokenValido } = useContext(
    UsuarioContext
  );

  const [mostrarProgress, setMostrarProgress] = useState(false);

  const [mostrar, setMostrar] = useState("FORMULARIO_LOGIN");
  const [params, setParams] = useState("");
  const [titulo, setTitulo] = useState("");
  const [pagina, setPagina] = useState("");

  // const [tokenValido, setTokenValido] = useState(false);

  useEffect(() => {
    let arrURL = window.location.href.split("/");

    if (arrURL.length > 3) {
      if (arrURL[3] === "confirmarPedido") {
        setMostrar("FORMULARIO_CONFIRMAR_PEDIDO");
        setParams(arrURL[4]);
      }
    }

    if (arrURL.length > 3) {
      if (
        arrURL[3] !== "registro" &&
        arrURL[3] !== "seleccionarModulos" &&
        arrURL[3] !== "pago"
      ) {
        localStorage.removeItem("arrmods");
        localStorage.removeItem("objregusu");
      }
    }

    if (arrURL.length > 3) {
      if (arrURL[3] === "recuperarClave") {
        setMostrar("FORMULARIO_RECUPERAR_CLAVE");
        setParams(arrURL[4]);
      }
    }

    if (arrURL.length > 3) {
      if (arrURL[3] === "seleccionarModulos") {
        setMostrar("FORMULARIO_SELECCIONAR_MODULOS");
        // setParams("");
      }
    }

    if (arrURL.length > 3) {
      if (arrURL[3] === "pago") {
        setMostrar("FORMULARIO_DE_PAGO");
        // setParams("");
      }
    }

    validarToken();
  }, []);

  const validarToken = async () => {
    try {
      setMostrarProgress(true);

      let objusu;

      // if (window.ReactNativeWebView) {
      // // SI ESTOY CON LA APP DESDE EL WEBVIEW
      // objusu = desencriptar(usuario);
      // } else {

      objusu = desencriptar(localStorage.getItem("objusu"));
      // }

      // let tok1 = localStorage.getItem("tok1");
      // let tok2 = localStorage.getItem("tok2");

      // console.log(objusu);

      let tok1 = objusu !== "" ? objusu.Tok1 : "";
      let tok2 = objusu !== "" ? objusu.Tok2 : "";

      // if (tok1 != undefined && tok2 != undefined) {
      const API_ENDPOINT = `https://backend.bimtracker.com/api_usuarios.php`;

      // var base64 = require("base-64");

      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // mode: "no-cors",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "CHEQUEAR_TOKEN_VALIDO",
          tok1: tok1,
          tok2: tok2,
        }),

        // headers: headers,
        //credentials: 'user:passwd'
      });

      // alert(datos.result.usuario);

      const usuarios = await datos.json();

      // console.log(usuarios.result.usuario);

      if (usuarios.result.usuario != undefined) {
        // signIn(usuarios.result.usuario.email, usuarios.result.usuario.clave);

        setUsuario(usuarios.result.usuario[0]);

        // if (window.ReactNativeWebView) {
        // // SI ESTOY CON LA APP DESDE EL WEBVIEW
        // } else {
        localStorage.setItem("objusu", encriptar(usuarios.result.usuario[0]));
        // }

        // localStorage.setItem("tok1", usuarios.result.usuario[0].Tok1);
        // localStorage.setItem("tok2", usuarios.result.usuario[0].Tok2);
        // localStorage.setItem("fechaTok", usuarios.result.usuario[0].FechaTok);

        // console.log(usuarios.result.usuario[0]);

        // alert(usuario.Logueado);
        // navigate("/dashboard");

        setTokenValido(true);

        setMostrarProgress(false);

        return true;
      } else {
        console.log("Token inválido.");

        setTokenValido(false);

        setMostrarProgress(false);
        return false;
      }
      // } else {
      //   console.log("Token inválido.");
      //   alert("PASA POR 6B");

      //   setTokenValido(false);

      //   setMostrarProgress(false);
      //   return false;
      // }

      // return usuarios.result;

      // if (tok1 != null && tok2 != null) {
      //   return true;
      // } else {
      //   return false;
      // }
    } catch (error) {
      setMostrarProgress(false);
      alert(error);
      console.log(error);
    }

    return "ERROR";
  };

  return (
    <div>
      {/* USUARIO |{usuario.Logueado}| TODO |{usuario.Email}| */}
      {/* {console.log("Logueado: " + usuario.Logueado)} */}
      {/* {console.log(tokenValido())} */}

      {mostrarProgress ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          {usuario.Logueado === "SI" /*|| tokenValido() === true*/ ? (
            <>
              {/* {console.log("PASA POR Logueado")} */}
              <Navbar />
            </>
          ) : tokenValido ? (
            <>
              {/* {console.log("PASA POR tokenValido()")} */}
              <Navbar />
            </>
          ) : (
            <>
              {mostrar === "FORMULARIO_CONFIRMAR_PEDIDO" ? (
                <>
                  {/* {console.log("PASA POR PageFormularioLogin")} */}
                  <PageConfirmarPedido
                    mostrar={mostrar}
                    setMostrar={setMostrar}
                    codigoPedido={params}
                  />
                </>
              ) : mostrar === "FORMULARIO_LOGIN" ? (
                <>
                  {/* {console.log("PASA POR PageFormularioLogin")} */}
                  <PageFormularioLogin
                    mostrar={mostrar}
                    setMostrar={setMostrar}
                  />
                </>
              ) : mostrar === "FORMULARIO_REGISTRO" ? (
                <>
                  {/* {console.log("PASA POR PageRegistro")} */}
                  <PageRegistro
                    setTitulo={setTitulo}
                    setPagina={setPagina}
                    setMostrar={setMostrar}
                  />
                </>
              ) : mostrar === "FORMULARIO_OLVIDE_CLAVE" ? (
                <>
                  {/* {console.log("PASA POR PageOlvideClave")} */}
                  <PageOlvideClave
                    setTitulo={setTitulo}
                    setPagina={setPagina}
                    setMostrar={setMostrar}
                  />
                </>
              ) : mostrar === "FORMULARIO_RECUPERAR_CLAVE" ? (
                <>
                  {/* {console.log("PASA POR PageRecuperarClave")} */}
                  <PageRecuperarClave
                    setTitulo={setTitulo}
                    setPagina={setPagina}
                    setMostrar={setMostrar}
                    params={params}
                    setParams={setParams}
                  />
                </>
              ) : mostrar === "FORMULARIO_SELECCIONAR_MODULOS" ? (
                <>
                  {/* {console.log("PASA POR PageSeleccionarModulos")} */}
                  <PageSeleccionarModulos
                    setTitulo={setTitulo}
                    setPagina={setPagina}
                    setMostrar={setMostrar}
                  />
                </>
              ) : mostrar === "FORMULARIO_DE_PAGO" ? (
                <>
                  {/* {console.log("PASA POR PagePago")} */}
                  <PagePago
                    setTitulo={setTitulo}
                    setPagina={setPagina}
                    setMostrar={setMostrar}
                  />
                </>
              ) : (
                ""
              )}
            </>
          )}
        </>
      )}

      {/* {usuario.Logueado === "SI" ? (
        <>
          // {console.log("PASA POR Logueado SI")}
          <Navbar />
        </>
      ) : tokenValido() === true ? (
        <>
          // {console.log("PASA POR tokenValido()")}
          <Navbar />
        </>
      ) : (
        <>
          // {console.log("PASA POR PageFormularioLogin")}

          <PageFormularioLogin />
        </>
      )} */}
    </div>
  );
};

export default PageLogin;
