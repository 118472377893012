import React, { useContext, useEffect, useState } from "react";

import { Button, Grid, IconButton, Typography } from "@mui/material";

import { DragDropContext } from "react-beautiful-dnd";
import Boton from "../../components/Boton";
// import styled from "styled-components";

import Column from "./Column";

// import { OutTable, ExcelRenderer } from "react-excel-renderer";
import SendIcon from "@mui/icons-material/Send";
import Botonera from "../../components/Botonera";
import { useNavigate } from "react-router-dom";
import ObraContext from "../../contextos/ObraContext";
import Encabezado from "./Encabezado";

const PageImportarCuantificacion = (props) => {
  const { obra, setObra } = useContext(ObraContext);

  let navigate = useNavigate();

  useEffect(() => {
    props.setTitulo("Importar cuantificaciones");
    props.setPagina("");

    if (obra.IDObra === undefined) {
      navigate("/presupuestos");
    }
  }, [props.setTitulo]);

  const [texto, setTexto] = useState("");

  const handleChange = (event) => {
    let textoFormateado = event.target.value
      .replaceAll("\n", ";;\n")
      .replaceAll("	", "|*|")
      .replaceAll("$ ", "")
      // .replaceAll(/"/g, "''");
      // .replaceAll(/"/g, "&lsquo;");
      .replace(/"/g, "&quot;");
    setTexto(textoFormateado);
    // console.log(event.target.value);
  };

  const enviarListaDeCuantificaciones = async () => {
    // console.log(texto);

    const API_ENDPOINT = `https://backend.bimtracker.com/api_presupuestos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "IMPORTAR_CUANTIFICACIONES",
        lista: texto,
        idObra: 1,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.cantidadInsertada) {
      // console.log("cantidadInsertada: " + respuesta.result.cantidadInsertada);
      setTexto("");
      alert("Cuantificaciones actualizadas.");
    } else {
      console.log("NO TRAE NADA.");
      // setState([]);
    }
  };

  return (
    <div>
      <Encabezado />
      <br />
      <br />
      <Grid container columns={{ xs: 12 }} border={0}>
        <Grid item xs={10} border={0}>
          <textarea
            name="message"
            style={{ width: "100%" }}
            rows="20"
            cols="200"
            placeholder="Copie y pegue la lista de cuantificaciones (exportadas desde Revit)."
            onChange={handleChange}
            value={texto}
            // defaultValue={texto}
            // disabled
          ></textarea>
        </Grid>

        <Grid item xs={12} border={0}></Grid>

        <Grid item xs={10} border={0}>
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item xs={12}>
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                onClick={() => enviarListaDeCuantificaciones()}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PageImportarCuantificacion;
