import { Alert, AlertTitle, Snackbar, Stack } from "@mui/material";
import React from "react";

const AlertValidForms = (props) => {
  return (
    <>
      {props.mostrarMensaje ? (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={props.mostrarMensaje}
          // open={true}
          //   severity="error"
          //   onClose={handleClose}
          //   message="No tiene permisos para acceder a esta sección."
          //   key={vertical + horizontal}
        >
          <Alert severity={props.tipo} sx={{ width: "100%" }}>
            {/* Seleccione el invitado antes de continuar. */}
            {props.mensaje}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
    </>
  );
};

export default AlertValidForms;
