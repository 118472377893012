import { Button, Grid, Paper, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UsuarioContext from "../contextos/UsuarioContext";
import { desencriptar } from "../funciones/Funciones";

import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import SendIcon from "@mui/icons-material/Send";
import styled from "@emotion/styled";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "140px",
}));

const BoxCupon = (props) => {
  const { setUsuario, usuario } = useContext(UsuarioContext);

  let navigate = useNavigate();

  const initCupon = {
    titulo: "",
    codigo: "",
    servicioBonificado: "",
    duracionEnMeses: 0,
    fechaUso: "",
    fechaVencimiento: "",
    valid: false,
  };

  const [cupon, setCupon] = useState(initCupon);

  useEffect(() => {
    let objusu = desencriptar(localStorage.getItem("objusu"));
    setUsuario(objusu);

    // console.log("PASA POR USEEFFECT");
    // console.log(objregusu);
  }, []);

  const handleChangeCupon = (event) => {
    let text = event.target.value;

    setCupon({ ...cupon, codigo: text });
  };

  const chequear_cupon_ingresado = async () => {
    try {
      const API_ENDPOINT = `https://backend.bimtracker.com/api_usuarios.php`;

      // var base64 = require("base-64");

      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "CHEQUEAR_CUPON_INGRESADO",
          idUsuario: usuario.IDUsuario,
          codigoCupon: cupon.codigo,
          modulosActivos: [
            "ORDENES_DE_SERVICIO",
            "PEDIDO_DE_MATERIALES",
            "MATERIALES",
            "VOLQUETES",
            "PRESUPUESTOS",
            "DOCUMENTACION",
          ],
        }),

        // headers: headers,
        //credentials: 'user:passwd'
      });

      const cupones = await datos.json();

      // console.log(usuarios.result.usuario);

      if (cupones.result.cuponValido) {
        setCupon({ ...cupon, valid: true });

        props.setMensaje({
          text: "Cupón aceptado!",
          tipo: "success",
        });
        props.setMostrarMensaje(true);

        setTimeout(() => {
          props.setMostrar("DASHBOARD");
          navigate("/dashboard");
        }, 4000);
      } else {
        props.setMensaje({ text: cupones.result.error, tipo: "error" });
        props.setMostrarMensaje(true);

        setCupon({ ...cupon, valid: false });
      }

      return true;
    } catch (error) {
      setCupon({ ...cupon, valid: false });
      console.error(error);
    }

    return "ERROR";
  };

  return (
    <>
      <Grid item xs={8} sm={8} md={4}>
        <Item
        //   onClick={() => moduloClick(props.codigo)}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            // border={1}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              //   border={1}
            >
              <Grid item style={{ alignItems: "center" }}>
                TENGO UN CUPON
              </Grid>
              <Grid item>
                <LocalActivityOutlinedIcon
                  style={{ paddingTop: "5px" }}
                  fontSize="medium"
                  // onClick={(event) => handleInfo(event, props.codigo)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              //   justifyContent="space-between"
              alignItems="center"
              //   border={1}
            >
              <Grid item xs={6}>
                <TextField
                  id="standard-basic"
                  label="Cupón"
                  variant="standard"
                  // style={{ marginTop: 0 }}
                  onChange={handleChangeCupon}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="small"
                  endIcon={<SendIcon />}
                  style={{ marginTop: 20 }}
                  onClick={() => chequear_cupon_ingresado()}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Item>
      </Grid>
    </>
  );
};

export default BoxCupon;
