import React, { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ListaInvitadosConfig from "./ListaInvitadosConfig";
import MensajesContext from "../../contextos/MensajesContext";
import PrivilegiosConfig from "./PrivilegiosConfig";
import InvitadoConfig from "./InvitadoConfig";
import PrivilegiosInvitadosConfig from "./PrivilegiosInvitadosConfig";
import ObraContext from "../../contextos/ObraContext";
import { desencriptar } from "../../funciones/Funciones";
import InvitadosContext from "../../contextos/InvitadosContext";
import UsuarioContext from "../../contextos/UsuarioContext";

const InvitacionesConfig = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const {
    invitado,
    setInvitado,
    nombre,
    setNombre,
    email,
    setEmail,
    permitidos,
    setPermitidos,
  } = useContext(InvitadosContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } = useContext(
    MensajesContext
  );

  const { usuario, setUsuario } = useContext(UsuarioContext);

  const { obra, setObra } = useContext(ObraContext);

  useEffect(() => {
    if (obra.IDObra === undefined) {
      // console.log("PASA POR IDObra");
      let objobra = desencriptar(localStorage.getItem("objobra"));
      setObra(objobra);
      // console.log(objobra);
      //   setPrivilegios(objobra.Privilegios);
    } else {
      //   setPrivilegios(obra.Privilegios);
      // console.log(obra);
    }

    if (usuario.IDUsuario === undefined) {
      // console.log("PASA POR IDObra");
      let objusu = desencriptar(localStorage.getItem("objusu"));
      setUsuario(objusu);
      // console.log(objusu);
    } else {
      // console.log(usuario);
    }
  }, []);

  const steps = [
    {
      label: "INVITADO: " + invitado.nya.toUpperCase(),
      description: <InvitadoConfig />,
    },
    {
      label: "OBRAS",
      description: (
        <PrivilegiosInvitadosConfig seccion="OBRAS" />
        // <ObrasConfig
        //   invitadoElegido={invitadoElegido}
        //   setInvitadoElegido={setInvitadoElegido}
        // />
      ),
    },
    {
      label: "PEDIDOS",
      description: <PrivilegiosInvitadosConfig seccion="PEDIDOS" />,
    },
    {
      label: "MATERIALES",
      description: <PrivilegiosInvitadosConfig seccion="MATERIALES" />,
    },
    {
      label: "VOLQUETES",
      description: <PrivilegiosInvitadosConfig seccion="VOLQUETES" />,
    },
    {
      label: "PRESUPUESTOS",
      description: <PrivilegiosInvitadosConfig seccion="PRESUPUESTOS" />,
    },
    {
      label: "DOCUMENTOS",
      description: <PrivilegiosInvitadosConfig seccion="DOCUMENTACION" />,
    },
  ];

  const handleNext = () => {
    if (nombre.valid && email.valid) {
      if (!invitado.valid) {
        setInvitado({
          nya: nombre.text,
          email: email.text,
          valid: true,
        });
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setMensaje({ text: nombre.error || email.error, tipo: "error" });
      setMostrarMensaje(true);
      setInvitado({ nya: "", email: "", valid: false });
    }

    // if (invitado.valid) {
    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // } else {
    //   setMensaje({
    //     text: "Ingrese el invitado antes de continuar",
    //     tipo: "error",
    //   });
    //   setMostrarMensaje(true);
    // }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);

    enviar_invitacion();
  };

  const handleSend = () => {
    if (nombre.valid && email.valid) {
      //   enviarInvitacion();
      setInvitado({
        nya: nombre.text,
        email: email.text,
        valid: true,
      });
    } else {
      setMensaje({ text: nombre.error || email.error, tipo: "error" });
      setMostrarMensaje(true);
      setInvitado({ nya: "", email: "", valid: false });
    }
  };

  const enviar_invitacion = async () => {
    const API_ENDPOINT = `https://backend.bimtracker.com/api_usuarios.php`;

    // alert(obra.Obra);
    // alert(usuario.Nombre + " " + usuario.Apellido);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "INVITAR_USUARIO_A_OBRA",
        idObra: obra.IDObra,
        obra: obra.Obra,
        idUsuarioQueInvita: usuario.IDUsuario,
        usuarioInvita: usuario.Nombre + " " + usuario.Apellido,
        nya: nombre.text,
        email: email.text,
        privilegios: permitidos,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (
      respuesta.result.respuesta === "ALTA_OK" ||
      respuesta.result.respuesta === "MODIF_OK"
    ) {
      //   console.log(respuesta.result.invitados);

      // console.log(respuesta.result.respuesta);
      // console.log(respuesta.result.retEmail);
      // alert(respuesta.result.retEmail);

      setPermitidos([]);

      setMensaje({
        text: "La invitación ha sido enviada con éxito",
        tipo: "success",
      });
      setMostrarMensaje(true);

      setInvitado({
        nya: nombre.text,
        email: email.text,
        valid: true,
      });
    } else {
      setInvitado({ nya: "", email: "", valid: false });
      //   setPrivilegiosInvitado([])

      console.log("NO TRAE NADA EN listar_usuarios_invitados()");
      // setState([]);
    }
  };

  return (
    <>
      <Box sx={{ maxWidth: 600 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
              // optional={
              //   index === 2 ? (
              //     <Typography variant="caption">último paso</Typography>
              //   ) : null
              // }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={
                        index === steps.length - 1 ? handleReset : handleNext
                      }
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === steps.length - 1 ? "Finalizar" : "Continuar"}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Volver
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </Box>
    </>
  );
};

export default InvitacionesConfig;
