import { Box, Button, TextField, Paper, Grid, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";

import MensajesContext from "../../contextos/MensajesContext";
import AlertValidConfig from "../../components/AlertValidConfig";
import { useNavigate } from "react-router-dom";
import EncabezadoItems from "../../components/EncabezadoItems";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const PageRecuperarClave = (props) => {
  const [clave1, setClave1] = useState({ text: "", valid: false, error: "" });
  const [clave2, setClave2] = useState({ text: "", valid: false, error: "" });

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } = useContext(
    MensajesContext
  );

  let navigate = useNavigate();

  const styles = {
    container: {
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    error: {
      color: "#FF0000",
      marginBottom: 10,
      alignItems: "flex-start",
      width: "70%",
      // borderWidth: 1,
      // borderColor: '#FFFFFF',
    },
  };

  useEffect(() => {}, []);

  useEffect(() => {
    props.setTitulo("Recuperar contraseña");
    props.setPagina("");

    // console.log("A");
  }, [props.setTitulo]);

  const validarFormulario = () => {
    if (clave1.valid && clave2.valid) {
      if (clave1.text === clave2.text) {
        cambiar_clave();
      } else {
        setMensaje({ text: "Las contraseñas no coinciden", tipo: "error" });
        setMostrarMensaje(true);
      }
    } else {
      setMensaje({ text: "Datos incorrectos", tipo: "error" });
      setMostrarMensaje(true);
    }
  };

  const validarClave1 = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;
    // console.log(text);

    // VALIDACIÓN DE CLAVE
    if (text.length > 2) {
      setClave1({ text: text, valid: true, error: "" });
    } else {
      setClave1({
        text: text,
        valid: false,
        error: "La contraseña es muy corta",
      });
    }
  };

  const validarClave2 = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;
    // console.log(text);

    // VALIDACIÓN DE CLAVE
    if (text.length > 2) {
      setClave2({ text: text, valid: true, error: "" });
    } else {
      setClave2({
        text: text,
        valid: false,
        error: "La contraseña es muy corta",
      });
    }
  };

  const cambiar_clave = async () => {
    try {
      const API_ENDPOINT = `https://backend.bimtracker.com/api_usuarios.php`;
      // var base64 = require("base-64");
      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));
      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "CAMBIAR_CLAVE",
          clave: clave1.text,
          token: props.params,
        }),
        // headers: headers,
        //credentials: 'user:passwd'
      });
      const usuarios = await datos.json();

      if (usuarios.result.respuesta === "CAMBIO_OK") {
        setMensaje({
          text: "La contraseña ha sido actualizada correctamente",
          tipo: "success",
        });
        setMostrarMensaje(true);

        setTimeout(() => {
          props.setMostrar("FORMULARIO_LOGIN");

          navigate("/");
        }, 3000);
      } else {
        setMensaje({
          text: "Error al cambiar la contraseña",
          tipo: "error",
        });
        setMostrarMensaje(true);
      }
      return usuarios.result;
    } catch (error) {
      console.error(error);
    }

    return "ERROR";
  };

  const handleBack = () => {
    props.setMostrar("FORMULARIO_LOGIN");
    navigate("/");
  };

  return (
    <div>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <Box
        sx={{
          display: "flex",
          // flexWrap: "wrap",
          alignContent: "center",
          justifyContent: "center",
          p: 1,
          m: 1,
          mt: 20,
          // border: 1,
          bgcolor: "background.paper",
          // maxWidth: 380,
          // width: { xs: 300, md: 400 },
          height: 300,
          borderRadius: 1,
        }}
      >
        <Item sx={{ width: { xs: 300, md: 350 } }}>
          <EncabezadoItems
            setMostrar={props.setMostrar}
            titulo="CAMBIAR CONTRASEÑA"
            pageBack="FORMULARIO_LOGIN"
          />
          <Box
            component="form"
            sx={{
              height: 40,
              width: "100%",
              // border: 1,
              marginTop: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              required
              id="standard-password-input"
              label="Contraseña nueva"
              type="password"
              inputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
              variant="standard"
              style={{ width: "80%" }}
              onChange={validarClave1}
            />
            <div style={styles.error}>{clave1.error}</div>
            {/* <br /> */}
            <TextField
              required
              id="standard-password-input"
              label="Repita la contraseña"
              type="password"
              inputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
              variant="standard"
              style={{ width: "80%" }}
              onChange={validarClave2}
            />
            <div style={styles.error}>{clave2.error}</div>

            <br />
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              onClick={() => validarFormulario()}
            >
              Cambiar contraseña
            </Button>
          </Box>
        </Item>
      </Box>
    </div>
  );
};

export default PageRecuperarClave;
