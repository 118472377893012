import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import UsuarioContext from "../../contextos/UsuarioContext";
import { desencriptar } from "../../funciones/Funciones";

const PageDashboardOrdenesDeServicio = (props) => {
  const { usuario, setUsuario } = useContext(UsuarioContext);

  useEffect(() => {
    props.setTitulo("Dashboard Ordenes de servicio");
    props.setPagina("altaOrdenDeServicio");
  }, [props.setTitulo]);

  useEffect(() => {
    localStorage.removeItem("objordserv");
  }, []);

  const tokenValido = async (props) => {
    try {
      const API_ENDPOINT = `https://backend.bimtracker.com/api_usuarios.php`;

      // var base64 = require("base-64");

      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

      let objusu = desencriptar(localStorage.getItem("objusu"));

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "CHEQUEAR_TOKEN_VALIDO",
          tok1: objusu.Tok1,
          tok2: objusu.Tok2,
          // tok1: localStorage.getItem("tok1"),
          // tok2: localStorage.getItem("tok2"),
        }),

        // headers: headers,
        //credentials: 'user:passwd'
      });

      const usuarios = await datos.json();

      // console.log(usuarios.result.usuario);

      if (usuarios.result.usuario != undefined) {
        // signIn(usuarios.result.usuario.email, usuarios.result.usuario.clave);

        props.setUsuario(usuarios.result.usuario[0]);

        // localStorage.setItem("tok1", usuarios.result.usuario[0].Tok1);
        // localStorage.setItem("tok2", usuarios.result.usuario[0].Tok2);
        // localStorage.setItem("fechaTok", usuarios.result.usuario[0].FechaTok);

        // console.log(usuarios.result.usuario[0]);

        // alert(usuario.Logueado);
        // navigate("/dashboard");
      } else {
        console.log("Token inválido.");
      }

      // return usuarios.result;
      return true;
    } catch (error) {
      console.error(error);
    }

    return "ERROR";
  };

  return (
    <div>
      {usuario.Logueado === "SI" || tokenValido() === true ? (
        <>
          {/* <PedidosLista estado="PENDIENTE" texto="Pedidos pendientes" /> */}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default PageDashboardOrdenesDeServicio;
