import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import ModalRecursos from "./ModalRecursos";
import ModalConfirm from "./ModalConfirm";
import ObraContext from "../../contextos/ObraContext";
import CuadroImportarTareas from "./CuadroImportarTareas";
import { fontSize } from "@mui/system";
import { chequearPermisos, numberWithCommas } from "../../funciones/Funciones";
import { CircularProgress } from "@mui/material";
import UsuarioContext from "../../contextos/UsuarioContext";

// function createData(
//   codTarea,
//   tarea,
//   categoria,
//   precioM2,
//   subtotalMateriales,
//   subtotalManoDeObra,
//   arrDetalle,
//   arrDetalleMO
// ) {
//   return {
//     codTarea,
//     tarea,
//     categoria,
//     precioM2,
//     subtotalMateriales,
//     subtotalManoDeObra,
//     detalle: arrDetalle,
//     detalleMO: arrDetalleMO,
//   };
// }

let subtotalMateriales = 0;

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const [tipoDeRecurso, setTipoDeRecurso] = useState("Material");
  const [estado, setEstado] = useState("ALTA");
  const [codTarea, setCodTarea] = useState();
  //   const [codMaterial, setCodMaterial] = useState("");
  //   const [codManoDeObra, setCodManoDeObra] = useState("");
  const [codRecurso, setCodRecurso] = useState("");
  const [recurso, setRecurso] = useState("");
  const [consumo, setConsumo] = useState("");
  const [unidadDelRecurso, setUnidadDelRecurso] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const { usuario, setUsuario } = useContext(UsuarioContext);

  const handleOpenAltaMat = (codigoTarea) => {
    if (
      chequearPermisos(usuario.Privilegios, "PRESUPUESTOS_ALTA_RECURSO") ===
      true
    ) {
      setEstado("ALTA");
      setCodTarea(codigoTarea);
      setTipoDeRecurso("Material");
      setConsumo("");
      setOpenModal(true);

      // console.log(codigoTarea);
    } else {
      alert("No tiene permisos para agregar un recurso.");
    }
  };

  const handleOpenAltaMO = (codigoTarea) => {
    if (
      chequearPermisos(usuario.Privilegios, "PRESUPUESTOS_ALTA_RECURSO") ===
      true
    ) {
      setEstado("ALTA");
      setCodTarea(codigoTarea);
      setTipoDeRecurso("Trabajador");
      setConsumo("");
      setOpenModal(true);
    } else {
      alert("No tiene permisos para agregar un recurso.");
    }
  };

  const handleOpenModifMat = (
    codigoTarea,
    codigoRecurso,
    nombreRecurso,
    cantidadConsumo,
    unidadDelRecurso
  ) => {
    if (
      chequearPermisos(
        usuario.Privilegios,
        "PRESUPUESTOS_MODIFICACION_RECURSO"
      ) === true
    ) {
      setEstado("MODIF");
      // setCodManoDeObra(codigoMat);
      setCodRecurso(codigoRecurso);
      setCodTarea(codigoTarea);
      setTipoDeRecurso("Material");
      setRecurso(nombreRecurso);
      setConsumo(cantidadConsumo);
      setUnidadDelRecurso(unidadDelRecurso);
      setOpenModal(true);
    } else {
      alert("No tiene permisos para modificar un recurso.");
    }
  };

  const handleOpenModifMO = (
    codigoTarea,
    codigoRecurso,
    nombreRecurso,
    cantidadConsumo,
    unidadDelRecurso
  ) => {
    if (
      chequearPermisos(
        usuario.Privilegios,
        "PRESUPUESTOS_MODIFICACION_RECURSO"
      ) === true
    ) {
      setEstado("MODIF");
      // setCodManoDeObra(codigoMO);
      setCodTarea(codigoTarea);
      setCodRecurso(codigoRecurso);
      setRecurso(nombreRecurso);
      setConsumo(cantidadConsumo);
      setUnidadDelRecurso(unidadDelRecurso);
      setTipoDeRecurso("Trabajador");
      setOpenModal(true);
    } else {
      alert("No tiene permisos para modificar un recurso.");
    }
  };

  const handleDelete = (codigoTarea, codigoRecurso) => {
    if (
      chequearPermisos(usuario.Privilegios, "PRESUPUESTOS_BAJA_RECURSO") ===
      true
    ) {
      setEstado("ELIMINAR");
      setCodTarea(codigoTarea);
      setCodRecurso(codigoRecurso);
      setOpenModalConfirm(true);
    } else {
      alert("No tiene permisos para borrar un recurso.");
    }
  };

  useEffect(() => {
    props.setActualizaRecurso(!props.actualizaRecurso);
  }, [estado]);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {/* <TableCell component="th" scope="row">
          {row.name}
        </TableCell> */}
        <TableCell align="left">
          {row.cuantificacion === "TIENE_CUANTIFICACION" ? (
            <strong>{row.codTarea}</strong>
          ) : (
            row.codTarea
          )}
        </TableCell>
        <TableCell align="left">{row.tarea}</TableCell>
        <TableCell align="left">{row.unidadDeLaTarea}</TableCell>
        <TableCell align="left">{row.categoria}</TableCell>
        <TableCell align="left" style={{ fontSize: "16px" }}>
          {"$ " +
            numberWithCommas(
              Math.round(row.precioM2 * 100) / 100
            ).toLocaleString()}
        </TableCell>
      </TableRow>
      {openModal ? (
        <ModalRecursos
          openModal={openModal}
          setOpenModal={setOpenModal}
          codTarea={codTarea}
          tipoDeRecurso={tipoDeRecurso}
          estado={estado}
          setEstado={setEstado}
          //   codMaterial={codMaterial}
          //   codManoDeObra={codManoDeObra}
          codRecurso={codRecurso}
          recurso={recurso}
          consumo={consumo}
          unidadDelRecurso={unidadDelRecurso}
          setUnidadDelRecurso={setUnidadDelRecurso}
        />
      ) : (
        ""
      )}

      {openModalConfirm ? (
        <ModalConfirm
          openModalConfirm={openModalConfirm}
          setOpenModalConfirm={setOpenModalConfirm}
          codTarea={codTarea}
          codRecurso={codRecurso}
          estado={estado}
          setEstado={setEstado}
        />
      ) : (
        ""
      )}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Detalle
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Cód. Recurso</TableCell>
                    <TableCell align="left">Recurso</TableCell>
                    <TableCell align="left">Unidad</TableCell>
                    <TableCell align="left">Consumo</TableCell>
                    <TableCell align="left">Costo</TableCell>
                    <TableCell align="left">Subtotal</TableCell>
                    <TableCell align="left">Modificar</TableCell>
                    <TableCell align="left">Eliminar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.detalle.map((detalleRow) => (
                    <TableRow key={detalleRow.codMaterial}>
                      <TableCell component="th" scope="row" align="left">
                        {detalleRow.codMaterial}
                      </TableCell>
                      <TableCell align="left">{detalleRow.material}</TableCell>
                      <TableCell align="left">
                        {detalleRow.unidadDelRecurso}
                      </TableCell>
                      <TableCell align="left">
                        {numberWithCommas(
                          Math.round(detalleRow.consumo * 100) / 100
                        ).toLocaleString()}
                      </TableCell>
                      <TableCell align="left">
                        {"$ " +
                          numberWithCommas(
                            Math.round(detalleRow.precio * 100) / 100
                          ).toLocaleString()}
                      </TableCell>
                      <TableCell align="left">
                        {detalleRow.consumo === "" || detalleRow.precio === "0"
                          ? ""
                          : "$ " +
                            numberWithCommas(
                              Math.round(detalleRow.subtotal * 100) / 100
                            ).toLocaleString()}

                        {/* {detalleRow.consumo === "" || detalleRow.precio === "0"
                          ? ""
                          : "$ " +
                            numberWithCommas(
                            Math.round(
                              detalleRow.consumo * detalleRow.precio * 100
                            ) /
                              100
                        ).toLocaleString()
                        } */}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton aria-label="delete" size="medium">
                          <EditOutlinedIcon
                            fontSize="inherit"
                            onClick={() =>
                              handleOpenModifMat(
                                row.codTarea,
                                detalleRow.codMaterial,
                                detalleRow.material,
                                detalleRow.consumo,
                                detalleRow.unidadDelRecurso
                              )
                            }
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell align="left">
                        <IconButton aria-label="delete" size="medium">
                          <HighlightOffOutlinedIcon
                            fontSize="inherit"
                            onClick={() =>
                              handleDelete(row.codTarea, detalleRow.codMaterial)
                            }
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}

                  <TableRow key={"MAT-" + row.codTarea} style={{ height: 50 }}>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      style={{ fontSize: "18px" }}
                    >
                      <IconButton aria-label="delete" size="large">
                        <AddCircleOutlineOutlinedIcon
                          fontSize="inherit"
                          onClick={() => handleOpenAltaMat(row.codTarea)}
                        />
                      </IconButton>
                      Material
                    </TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell
                      align="left"
                      style={{
                        verticalAlign: "top",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {"$ " +
                        numberWithCommas(
                          Math.round(row.subtotalMateriales * 100) / 100
                        ).toLocaleString()}
                    </TableCell>
                  </TableRow>

                  {row.detalleMO.map((detalleMORow) => (
                    <TableRow key={detalleMORow.codManoDeObra}>
                      <TableCell component="th" scope="row" align="left">
                        {detalleMORow.codManoDeObra}
                      </TableCell>
                      <TableCell align="left">
                        {detalleMORow.manoDeObra}
                      </TableCell>
                      <TableCell align="left">
                        {detalleMORow.unidadDelRecurso}
                      </TableCell>
                      <TableCell align="left">
                        {numberWithCommas(
                          Math.round(detalleMORow.consumo * 100) / 100
                        ).toLocaleString()}{" "}
                      </TableCell>
                      <TableCell align="left">
                        {"$ " +
                          numberWithCommas(
                            Math.round(detalleMORow.precio * 100) / 100
                          ).toLocaleString()}
                      </TableCell>
                      <TableCell align="left">
                        {detalleMORow.consumo === "" ||
                        detalleMORow.precio === "0"
                          ? ""
                          : "$ " +
                            numberWithCommas(
                              Math.round(detalleMORow.subtotal * 100) / 100
                            ).toLocaleString()}

                        {/* {detalleMORow.consumo === "" ||
                        detalleMORow.precio === "0"
                          ? ""
                          : "$ " +
                            numberWithCommas(
                              Math.round(
                                detalleMORow.consumo * detalleMORow.precio * 100
                              ) / 100
                            ).toLocaleString()} */}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton aria-label="delete" size="medium">
                          <EditOutlinedIcon
                            fontSize="inherit"
                            onClick={() =>
                              handleOpenModifMO(
                                row.codTarea,
                                detalleMORow.codManoDeObra,
                                detalleMORow.manoDeObra,
                                detalleMORow.consumo,
                                detalleMORow.unidadDelRecurso
                              )
                            }
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell align="left">
                        <IconButton aria-label="delete" size="medium">
                          <HighlightOffOutlinedIcon
                            fontSize="inherit"
                            onClick={() =>
                              handleDelete(
                                row.codTarea,
                                detalleMORow.codManoDeObra
                              )
                            }
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}

                  <TableRow key={"MO-" + row.codTarea} style={{ height: 50 }}>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      style={{ fontSize: "18px" }}
                    >
                      <IconButton aria-label="delete" size="large">
                        <AddCircleOutlineOutlinedIcon
                          fontSize="inherit"
                          onClick={() => handleOpenAltaMO(row.codTarea)}
                        />
                      </IconButton>
                      Mano de obra
                    </TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell
                      align="left"
                      style={{
                        verticalAlign: "top",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {"$ " +
                        numberWithCommas(
                          Math.round(row.subtotalManoDeObra * 100) / 100
                        ).toLocaleString()}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    codTarea: PropTypes.string.isRequired,
    tarea: PropTypes.string,
    categoria: PropTypes.string,
    precioM2: PropTypes.number.isRequired,
    detalle: PropTypes.arrayOf(
      PropTypes.shape({
        codMaterial: PropTypes.string.isRequired,
        material: PropTypes.string.isRequired,
        consumo: PropTypes.string.isRequired,
        precio: PropTypes.string.isRequired,
        subtotalMateriales: PropTypes.string,
      })
    ).isRequired,
    detalleMO: PropTypes.arrayOf(
      PropTypes.shape({
        codManoDeObra: PropTypes.string.isRequired,
        manoDeObra: PropTypes.string.isRequired,
        consumo: PropTypes.string.isRequired,
        precio: PropTypes.string.isRequired,
        subtotalManoDeObra: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
};

const TablaDeTareas = () => {
  const [lista, setLista] = useState([]);
  const [actualizaRecurso, setActualizaRecurso] = useState(false);
  const { obra, setObra } = useContext(ObraContext);

  const [mostrarProgress, setMostrarProgress] = useState(false);

  useEffect(() => {
    listar_tareas_con_recursos();
  }, [actualizaRecurso]);

  const listar_tareas_con_recursos = async () => {
    // console.log(lista);

    setMostrarProgress(true);

    const API_ENDPOINT = `https://backend.bimtracker.com/api_presupuestos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_TAREAS_CON_RECURSOS",
        idObra: obra.IDObra,
        //   lista: texto,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.listaTareas) {
      // console.log(respuesta.result.listaTareas);
      // console.log("PASA");
      setLista(respuesta.result.listaTareas);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN: listar_tareas_con_recursos()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  return lista.length > 1 ? (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">Cód. Tarea</TableCell>
            <TableCell align="left">Tarea</TableCell>
            <TableCell align="left">Unidad</TableCell>
            <TableCell align="left">Categoría</TableCell>
            <TableCell align="left">Precio x unidad</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lista.map((row, index) => (
            <Row
              key={index}
              row={row}
              actualizaRecurso={actualizaRecurso}
              setActualizaRecurso={setActualizaRecurso}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : mostrarProgress ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </Box>
  ) : (
    <CuadroImportarTareas />
  );
};

export default TablaDeTareas;
