import React, { useContext, useState, useEffect } from "react";
import { Box, Button, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import MensajesContext from "../../contextos/MensajesContext";

import UsuarioContext from "../../contextos/UsuarioContext";
import { desencriptar } from "../../funciones/Funciones";
import PrivilegioContext from "../../contextos/PrivilegiosContext";
import ObraContext from "../../contextos/ObraContext";
import InvitadosContext from "../../contextos/InvitadosContext";

const InvitadoConfig = (props) => {
  const [mostrarProgress, setMostrarProgress] = useState(false);

  const { obra, setObra } = useContext(ObraContext);

  const { usuario, setUsuario } = useContext(UsuarioContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } = useContext(
    MensajesContext
  );

  const {
    invitado,
    setInvitado,
    nombre,
    setNombre,
    email,
    setEmail,
    permitidos,
    setPermitidos,
  } = useContext(InvitadosContext);

  //   const {
  //     privilegios,
  //     setPrivilegios,
  //     mostrarAlert,
  //     setMostrarAlert,
  //   } = useContext(PrivilegioContext);

  useEffect(() => {
    if (obra.IDObra === undefined) {
      // console.log("PASA POR IDObra");
      let objobra = desencriptar(localStorage.getItem("objobra"));
      setObra(objobra);
      //   console.log(objobra);
      //   setPrivilegios(objobra.Privilegios);
    } else {
      //   setPrivilegios(obra.Privilegios);
    }

    // console.log(privilegios);

    if (usuario.IDUsuario === undefined) {
      // console.log("PASA POR IDObra");
      let objusu = desencriptar(localStorage.getItem("objusu"));
      setUsuario(objusu);
    }
  }, []);

  const validNombre = (event) => {
    let text = event.target.value;
    // console.log(text);
    if (text.length == 0) {
      // Clave vacía.
      setNombre({
        text: text,
        valid: false,
        error: "Complete el nombre",
      });
    } else if (text.length < 3) {
      // console.log("Clave muy corta.");
      setNombre({
        text: text,
        valid: false,
        error: "Nombre muy corto",
      });
    } else {
      // console.log("Clave OK.");
      setNombre({
        text: text,
        valid: true,
        error: "",
      });
    }
  };

  const validEmail = (event) => {
    let text = event.target.value;
    // console.log(text);

    // VALIDACIÓN DE USUARIO
    // if (text.length > 2) {
    //   setEmail({ text: text, valid: true, error: "" });
    // } else {
    //   setEmail({
    //     text: text,
    //     valid: false,
    //     error: "Email o usuario incorrecto",
    //   });
    // }

    // VALIDACIÓN DE MAIL
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      if (text.length == 0) {
        // Email vacío
        setEmail({ text: text, valid: false, error: "Complete el E-mail" });
      } else {
        setEmail({ text: text, valid: false, error: "E-mail incorrecto" });
      }

      return false;
    } else {
      // console.log(text.length);

      // console.log("Email is Correct");
      setEmail({ text: text, valid: true, error: "" });
    }
  };

  //   const handleSend = () => {
  //     if (nombre.valid && email.valid) {
  //       //   enviarInvitacion();
  //       setInvitado({
  //         nya: nombre.text,
  //         email: email.text,
  //         valid: true,
  //       });
  //     } else {
  //       setMensaje({ text: nombre.error || email.error, tipo: "error" });
  //       setMostrarMensaje(true);
  //       setInvitado({ nya: "", email: "", valid: false });
  //     }
  //   };

  //   const listar_secciones_permitidas = async () => {
  //     // console.log(lista);

  //     setMostrarProgress(true);

  //     console.log("IDObra: " + obra.IDObra);
  //     console.log("email: " + invitado.email);
  //     // console.log("IDUsuario: " + usuario.IDUsuario);

  //     const API_ENDPOINT = `https://backend.bimtracker.com/api_config.php`;

  //     const datos = await fetch(API_ENDPOINT, {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         operacion: "LISTAR_SECCIONES_PERMITIDAS",
  //         idObra: obra.IDObra,
  //         esInvitado: true,
  //         email: invitado.email,
  //         // idUsuario: props.invitadoElegido.IDUsuario,
  //       }),
  //     });
  //     const respuesta = await datos.json();

  //     // console.log(respuesta);

  //     if (respuesta.result.privilegios) {
  //       // console.log(respuesta.result.privilegios);
  //       // setAsignaciones(respuesta.result.asignaciones);

  //       let arrSeccionesPermitidas =
  //         respuesta.result.privilegios[0].Privilegios !== null
  //           ? respuesta.result.privilegios[0].Privilegios.split("|")
  //           : [];

  //       setPermitidos(arrSeccionesPermitidas);

  //       //   console.log("1: props.permitidos ******************");
  //       //   console.log(
  //       //     respuesta.result.privilegios[0].Privilegios.split("|").filter((fil) =>
  //       //       fil.includes("OBRAS")
  //       //     )
  //       //   );

  //       //   console.log("2: usuario.Privilegios ******************");
  //       //   console.log(usuario.Privilegios);

  //       //   console.log("3: obra.Privilegios ******************");
  //       //   console.log(obra.Privilegios);

  //       //   console.log("4: obra ******************");
  //       //   console.log(obra);

  //       // alert("Lista de precios actualizada.");
  //     } else {
  //       console.log("NO TRAE NADA EN: listar_secciones_permitidas()");
  //       // setState([]);
  //     }

  //     setMostrarProgress(false);
  //   };

  return (
    <div>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="standard-basic"
          label="Nombre y apellido"
          variant="standard"
          onChange={validNombre}
        />
        <TextField
          id="standard-basic"
          label="E-mail"
          variant="standard"
          onChange={validEmail}
        />
        {/* <Button
          variant="contained"
          size="small"
          style={{ width: "auto", marginTop: "25px" }}
          endIcon={<SendIcon />}
          onClick={() => handleSend()}
        >
          Send
        </Button> */}
      </Box>
    </div>
  );
};

export default InvitadoConfig;
