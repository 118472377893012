import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ObraContext from "../../contextos/ObraContext";

import Select from "react-select";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { formatearFecha } from "../../funciones/Funciones";
import { Checkbox, Grid } from "@mui/material";

import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import EstilosContext from "../../contextos/EstilosContext";

// const options = [
//   { value: "chocolate", label: "Chocolate" },
//   { value: "strawberry", label: "Strawberry" },
//   { value: "vanilla", label: "Vanilla" },
// ];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalDocumento = (props) => {
  const [startDate, setStartDate] = useState("");
  const [checkFV, setCheckFV] = useState(true);
  const [diasAntes, setDiasAntes] = useState("0");

  const { estilos } = useContext(EstilosContext);

  const handleClose = () => {
    props.setOpenModal(false);
  };

  //   const handleChange = (event) => {
  //     setCodTarea(event.target.value);
  //   };

  useEffect(() => {}, []);

  const handleGuardar = async () => {
    props.setRefresh(!props.refresh);
    // console.log("startDate: " + startDate);
    let fechaVencimiento =
      startDate != "" && startDate != null
        ? formatearFecha(startDate, "yyyy-MM-dd")
        : "";

    // console.log("check: " + props.check);
    // console.log("IDObra: " + props.obra.IDObra);
    // console.log("IDSubcontrato: " + props.subcontrato.IDSubcontrato);
    // console.log("IDDocumentacion: " + props.documento.IDDocumentacion);
    // console.log("startDate: " + fechaVencimiento);

    const API_ENDPOINT = `https://backend.bimtracker.com/api_hys.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ALTA_DOCUMENTO_EN_OBRA",
        idObra: props.obra.IDObra,
        idSubcontrato: props.subcontrato.IDSubcontrato,
        idDocumentacion: props.documento.IDDocumentacion,
        fechaImpreso: "",
        fechaVencimiento: fechaVencimiento,
        diasAntes: diasAntes,
      }),
    });
    const respuesta = await datos.json();

    // console.log("PASA POR 1");
    // console.log(respuesta.result.respuesta);
    // console.log("PASA POR 2");

    if (respuesta.result.respuesta) {
      // console.log(respuesta.result.asignaciones);
      //   setAsignaciones(respuesta.result.asignaciones);
      //   setSeleccionados(respuesta.result.asignaciones);
      // alert("Lista de precios actualizada.");

      handleClose();
    } else {
      //   setAsignaciones([]);
      //   setSeleccionados([]);
      console.log("NO TRAE NADA EN: handeChange()");
      // setState([]);
    }
  };

  const handleChangeFV = (event) => {
    setCheckFV(!checkFV);

    if (event.target.checked) {
      setStartDate("");
    } else {
      setStartDate(new Date());
    }
  };

  const validarDias = (nro) => {
    if (nro.target.value < 0 || nro.target.value == "") {
      document.getElementById("standard-number").value = "0";
      // console.log("PASA");
    }

    // console.log("nro: " + nro.target.value);
    setDiasAntes(document.getElementById("standard-number").value);
  };

  return (
    <Modal
      open={props.openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        component="form"
        sx={style}
        style={estilos.modalWidth}
        noValidate
        autoComplete="off"
      >
        <FormControl
          variant="standard"
          sx={{
            m: 1,
            width: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h5" component="h5">
              {props.documento.Documento}
            </Typography>
            <div>&nbsp;</div>

            <div>
              <Checkbox
                style={{ ariaLabel: "Checkbox demo" }}
                defaultChecked
                onChange={(event) => handleChangeFV(event)}
              />
              Sin fecha de vencimiento.
            </div>
            <div>&nbsp;</div>
            <div style={{ marginLeft: "10px" }}>
              Fecha de vencimiento:
              {checkFV ? (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  disabled
                />
              ) : (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              )}
            </div>
            <div>&nbsp;</div>

            <div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginLeft: "10px",
                }}
              >
                <NotificationsNoneOutlinedIcon
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                {/* <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} /> */}
                {/* <TextField id="input-with-sx" label="With sx" variant="standard" /> */}
                {checkFV ? (
                  <>
                    <TextField
                      id="standard-number"
                      label=""
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "60px" }}
                      variant="standard"
                      disabled
                    />
                    &nbsp;&nbsp;días antes
                  </>
                ) : (
                  <>
                    <TextField
                      id="standard-number"
                      label=""
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "60px" }}
                      variant="standard"
                      onChange={validarDias}
                    />
                    &nbsp;&nbsp;días antes
                  </>
                )}
              </Box>
            </div>
            <div>&nbsp;</div>
            <Button
              variant="contained"
              endIcon={<CheckOutlinedIcon />}
              onClick={handleGuardar}
            >
              Guardar
            </Button>
          </Grid>
        </FormControl>
      </Box>
    </Modal>
  );
};

export default ModalDocumento;
