import React, { useEffect } from "react";
import { createContext, useState } from "react";
import { desencriptar, encriptar } from "../funciones/Funciones";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const EstilosContext = createContext();

const EstilosProvider = ({ children }) => {
  function useScreenWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
      keys.reduce((output, key) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key));
        return !output && matches ? key : output;
      }, null) || "xs"
    );
  }
  const screenWidth = useScreenWidth();

  const stylesXS = {
    columnas: {
      cantidad: 12,
    },
    modalWidth: {
      width: "80%",
    },
    tituloEncabezado: {
      fontSize: "22px",
    },
    titulos: {
      fontSize: "16px",
    },
    textos: {
      fontSize: "14px",
    },
    posicionNumPad: {
      justifyContent: "center",
      alignItems: "center",
    },
  };

  const stylesSM = {
    columnas: {
      cantidad: 6,
    },
    modalWidth: {
      width: "80%",
    },
    tituloEncabezado: {
      fontSize: "26px",
    },
    titulos: {
      fontSize: "20px",
    },
    textos: {
      fontSize: "18px",
    },
    posicionNumPad: {
      justifyContent: "center",
      alignItems: "center",
    },
  };

  let estilos = "";

  switch (screenWidth) {
    case "xs":
      estilos = stylesXS;
      break;

    case "sm":
      estilos = stylesSM;
      break;

    default:
      break;
  }

  // console.log(estilos);

  const data = {
    estilos,
    screenWidth,
  };

  return (
    <EstilosContext.Provider value={data}>{children}</EstilosContext.Provider>
  );
};

export { EstilosProvider };
export default EstilosContext;
