import React, { useEffect } from "react";

const PageDashboard = (props) => {
  useEffect(() => {
    props.setTitulo("Dashboard");
    props.setPagina("");

    // console.log("A");
  }, [props.setTitulo]);

  return <div>PageDashboard</div>;
};

export default PageDashboard;
