import React from "react";
import { createContext, useState } from "react";

const PedidoContext = createContext();

const PedidoProvider = ({ children }) => {
  const initPedido = {
    codigoPedido: "",
    idUsuario: "",
    idObra: "",
    idProveedor: "",
    proveedor: "",
    detalle: [],
    fechaProgramada: "",
    horaProgramada: "",
  };

  const [pedido, setPedido] = useState(initPedido);
  const [codigoPedido, setCodigoPedido] = useState("");

  const data = { pedido, setPedido, codigoPedido, setCodigoPedido };

  return (
    <PedidoContext.Provider value={data}>{children}</PedidoContext.Provider>
  );
};

export { PedidoProvider };
export default PedidoContext;
