import React from "react";
import SendIcon from "@mui/icons-material/Send";
import { Button } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

const WhatsappEnviarMensaje = ({
  numeroTel,
  mensaje,
  pedido,
  setMostrarMensaje,
  setMensaje,
  tipo,
}) => {
  const [boton, setBoton] = useState({ icono: "SEND", texto: "Enviar" });

  // console.log("NUMERO TEL: " + numeroTel);
  // console.log("tipo: " + tipo);

  useEffect(() => {
    if (tipo === "PEDIDO_DE_VOLQUETE") {
      setBoton({ icono: "SEND", texto: "Enviar" });
    } else if (tipo === "PEDIDO_DE_MATERIALES") {
      setBoton({ icono: "SEND", texto: "Enviar" });
    } else if (tipo === "ENVIAR_PEDIDO") {
      // NO GRABO EL PEDIDO PORQUE YA ESTÁ GRABADO, SOLO LO ENVÍO POR WHATSAPP
      setBoton({ icono: "", texto: "Enviar pedido" });
    } else if (tipo === "REENVIAR_PEDIDO") {
      // NO GRABO EL PEDIDO PORQUE YA ESTÁ GRABADO, SOLO LO ENVÍO POR WHATSAPP
      setBoton({ icono: "", texto: "Reenviar pedido" });
    } else {
    }
  }, []);

  const URL = "https://wa.me";

  //   let num = "5491158482073";
  numeroTel = numeroTel.replace(/[^\w\s]/gi, "").replace(/ /g, "");

  let url = `${URL}/${numeroTel}`;

  //   let message = "HOLA MUNDO";

  if (mensaje) {
    url += `?text=${encodeURI(mensaje)}`;
  }

  const guardar_pedido_de_volquete = async () => {
    const API_ENDPOINT = `https://backend.bimtracker.com/api_volquetes.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ALTA_PEDIDO_DE_VOLQUETE",
        idUsuario: pedido.idUsuario,
        idObra: pedido.idObra,
        idProveedor: pedido.idProveedor,
        cantidad: pedido.cantidad,
        fechaProgramada: pedido.fechaProgramada,
        horaProgramada: pedido.horaProgramada,
        // operacion: "LISTAR_PRESUPUESTO",
        //  idObra: obra.IDObra,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.pedido) {
      setMensaje({ text: respuesta.result.pedido, tipo: "success" });
      setMostrarMensaje(true);
    } else {
      console.log("NO TRAE NADA EN guardar_pedido_de_volquete()");
    }
  };

  const guardar_pedido_de_materiales = async () => {
    const API_ENDPOINT = `https://backend.bimtracker.com/api_pedidos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ALTA_PEDIDO_DE_MATERIALES",
        idUsuario: pedido.idUsuario,
        idObra: pedido.idObra,
        idProveedor: pedido.idProveedor,
        detallePedido: pedido.detalle,
        fechaProgramada: pedido.fechaProgramada,
        horaProgramada: pedido.horaProgramada,
        codigoPedido: pedido.codigoPedido,
        // operacion: "LISTAR_PRESUPUESTO",
        //  idObra: obra.IDObra,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.pedido) {
      // setCodigoPedido(respuesta.result.codigoPedido);
      setMensaje({ text: respuesta.result.pedido, tipo: "success" });
      setMostrarMensaje(true);
    } else {
      console.log("NO TRAE NADA EN guardar_pedido_de_materiales()");
    }
  };

  const handleClick = () => {
    if (tipo === "PEDIDO_DE_VOLQUETE") {
      guardar_pedido_de_volquete();
    } else if (tipo === "PEDIDO_DE_MATERIALES") {
      guardar_pedido_de_materiales();
    } else if (tipo === "ENVIAR_PEDIDO") {
      // NO GRABO EL PEDIDO PORQUE YA ESTÁ GRABADO, SOLO LO ENVÍO POR WHATSAPP
    } else if (tipo === "REENVIO_DE_PEDIDO") {
      // NO GRABO EL PEDIDO PORQUE YA ESTÁ GRABADO, SOLO LO ENVÍO POR WHATSAPP
    } else {
    }

    window.open(url);
  };

  return (
    <Button
      // variant="contained"
      endIcon={boton.icono === "SEND" ? <SendIcon /> : ""}
      onClick={(e) => handleClick()}
    >
      {boton.texto}
    </Button>
  );
};

export default WhatsappEnviarMensaje;
