import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Titulo from "../../components/Titulo";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import Select from "react-select";
import ObraContext from "../../contextos/ObraContext";
import { desencriptar } from "../../funciones/Funciones";
import AlertValidForms from "../../components/AlertValidForms";
import MensajesContext from "../../contextos/MensajesContext";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "auto",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

const PageAltaSociedad = () => {
  // const initialSociedad = {
  //   razonSocial: "",
  //   cuit: "",
  //   email: "",
  //   rubro: "",
  // };

  // const [sociedad, setSociedad] = useState(initialSociedad);

  const { obra, setObra } = useContext(ObraContext);

  const [razonSocial, setRazonSocial] = useState({
    text: "",
    valid: false,
    error: "",
  });
  const [cuit, setCuit] = useState({ text: "", valid: false, error: "" });
  const [email, setEmail] = useState({ text: "", valid: false, error: "" });
  const [rubro, setRubro] = useState({
    text: "",
    codRubro: "",
    valid: false,
    error: "",
  });
  const [lista, setLista] = useState([]);
  // const [mensaje, setMensaje] = useState({ text: "", error: false });

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } = useContext(
    MensajesContext
  );

  const cargarLista = async () => {
    const API_ENDPOINT = `https://backend.bimtracker.com/api_hys.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_RUBROS_HYS",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.listaRubros) {
      // console.log(respuesta.result.listaRubros);
      setLista(respuesta.result.listaRubros);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN cargarLista()");
      // setState([]);
    }
  };

  useEffect(() => {
    let objobra = localStorage.getItem("objobra");

    setObra(desencriptar(objobra));
    // console.log(desencriptar(objobra));

    cargarLista();
  }, []);

  const validRazonSocial = (event) => {
    let text = event.target.value;

    setMensaje({ text: "", tipo: "" });

    if (text.length > 2) {
      setRazonSocial({ text: text, valid: true, error: "" });
    } else {
      setRazonSocial({
        text: text,
        valid: false,
        error: "Razón Social demasiado corta.",
      });
    }
  };

  const validCuit = (event) => {
    let text = event.target.value;

    setMensaje({ text: "", tipo: "" });

    if (text.length > 10) {
      setCuit({ text: text, valid: true, error: "" });
    } else {
      setCuit({
        text: text,
        valid: false,
        error: "Cuit demasiado corto.",
      });
    }
  };

  const validEmail = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;

    setMensaje({ text: "", tipo: "" });

    // console.log(text);

    // VALIDACIÓN DE USUARIO
    //   if (text.length > 2) {
    //     setEmail({ text: text, valid: true, error: "" });
    //   } else {
    //     setEmail({
    //       text: text,
    //       valid: false,
    //       error: "Email o usuario incorrecto",
    //     });
    //   }

    // VALIDACIÓN DE MAIL
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      if (text.length == 0) {
        // Email vacío

        setEmail({ text: text, valid: false, error: "" });
      } else {
        setEmail({ text: text, valid: false, error: "Email incorrecto" });
      }

      return false;
    } else {
      // console.log(text.length);

      // console.log("Email is Correct");
      setEmail({ text: text, valid: true, error: "" });
    }
  };

  const handleGuardar = async () => {
    if (razonSocial.valid && cuit.valid && email.valid && rubro.valid) {
      const API_ENDPOINT = `https://backend.bimtracker.com/api_hys.php`;

      let formSoc = {
        operacion: "AGREGAR_SOCIEDAD_EN_HYS",
        idObra: obra.IDObra,
        razonSocial: razonSocial.text,
        cuit: cuit.text,
        email: email.text,
        codRubro: rubro.codRubro,
        rubro: rubro.text,
      };

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // },
        body: JSON.stringify(formSoc),
      });
      const respuesta = await datos.json();

      // console.log(respuesta);

      if (respuesta.result.respuesta === "ALTA_OK") {
        // console.log(respuesta.result.respuesta);

        setRazonSocial({ text: "", valid: false, error: "" });
        setCuit({ text: "", valid: false, error: "" });
        setEmail({ text: "", valid: false, error: "" });
        setRubro({
          text: "",
          codRubro: "",
          valid: false,
          error: "",
        });

        setMensaje({
          text: "La sociedad ha sido agregada con éxito!",
          tipo: "success",
        });

        setMostrarMensaje(true);

        // setTimeout(() => {
        //   setMensaje({
        //     text: "",
        //     tipo: "",
        //   });
        // }, 3000);

        // alert("Lista de precios actualizada.");
      } else {
        setMensaje({ text: "Error al agregar la sociedad.", tipo: "error" });
        setMostrarMensaje(true);
        console.log("NO TRAE NADA EN handleGuardar()");
        // setState([]);
      }
    } else {
      setMensaje({ text: "Complete todos los campos.", tipo: "error" });
      setMostrarMensaje(true);
    }
  };

  const handleChange = (event) => {
    // console.log(event);
    setRubro({
      text: event.label,
      codRubro: event.value,
      valid: true,
      error: "",
    });

    setMensaje({ text: "", tipo: "" });
  };

  const styles = {
    container: {
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    contenedor: {
      // position: "absolute",
      // top: "50%",
      // left: "50%",
      // transform: "translate(-50%, -50%)",
      display: "flex",
      justifyContent: "center",
      width: "auto",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 2,
    },
    exito: {
      color: "#13b90b",
      marginBottom: 10,
      alignItems: "flex-start",
      width: "70%",
      // borderWidth: 1,
      // borderColor: '#FFFFFF',
    },
    error: {
      color: "#FF0000",
      marginBottom: 10,
      alignItems: "flex-start",
      width: "70%",
      // borderWidth: 1,
      // borderColor: '#FFFFFF',
    },
  };

  return (
    <>
      <AlertValidForms
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Titulo
            texto="Agregar sociedad / subcontrato"
            botones={{
              seccion: "",
              alta: false,
              baja: false,
              modif: false,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={8} lg={6} style={{ paddingTop: "35px" }}>
          <Box
            component="form"
            sx={styles.contenedor}
            noValidate
            autoComplete="off"
          >
            {/* <TextField
            id="standard-basic"
            label="Standard"
            helperText="hola"
            variant="standard"
          /> */}

            {/* <FormControl sx={{ m: 1, minWidth: 80 }}> */}
            <FormControl variant="standard" sx={{ m: 1, width: "auto" }}>
              <Typography variant="h5" component="h5">
                AGREGAR SOCIEDAD / SUBCONTRATO
              </Typography>

              <div>&nbsp;</div>

              {/* <Grid
                item
                xs={12}
                style={mensaje.error ? styles.error : styles.exito}
              >
                <Typography variant="h6" component="h6">
                  {mensaje.text}
                </Typography>
              </Grid> */}

              <div>&nbsp;</div>

              <Select
                // defaultValue={codTarea}
                onChange={handleChange}
                options={lista}
                placeholder="Seleccione el rubro"
                //   value={defaultValueEnSelect}
                //   value={lista.filter((option) => option.label === rubro.text)}
              />

              <div>&nbsp;</div>

              <TextField
                required
                id="standard-required"
                label="Razón Social"
                // defaultValue=""
                variant="standard"
                style={{ width: "80%" }}
                onChange={validRazonSocial}
                value={razonSocial.text}
              />
              <div style={styles.error}>{razonSocial.error}</div>

              <div>&nbsp;</div>

              <TextField
                required
                id="standard-required"
                label="CUIT"
                // defaultValue=""
                variant="standard"
                style={{ width: "80%" }}
                onChange={validCuit}
                value={cuit.text}
              />
              <div style={styles.error}>{cuit.error}</div>

              <div>&nbsp;</div>

              <TextField
                required
                id="standard-required"
                label="E-mail"
                // defaultValue=""
                variant="standard"
                style={{ width: "80%" }}
                onChange={validEmail}
                value={email.text}
              />
              <div style={styles.error}>{email.error}</div>

              <div>&nbsp;</div>

              {/* <Select
              // defaultValue={codTarea}
              onChange={handleChange}
              options={lista}
              value={defaultValueEnSelect}
              // value={lista.filter((option) => option.label === "00-DEM-020")}
            /> */}

              <div>&nbsp;</div>

              {/* <TextField
            id="costo"
            label="Costo"
            variant="standard"
            onChange={validCosto}
          />

          <div>&nbsp;</div> */}

              <Button
                variant="contained"
                endIcon={<CheckOutlinedIcon />}
                onClick={handleGuardar}
              >
                Guardar
              </Button>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12}></Grid>
      </Grid>
    </>
  );
};

export default PageAltaSociedad;
