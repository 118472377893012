import { Box, Button, Checkbox, Chip, Grid, Paper, Stack } from "@mui/material";
import React from "react";
import AlertValidConfig from "../../components/AlertValidConfig";
import EncabezadoItems from "../../components/EncabezadoItems";

import SendIcon from "@mui/icons-material/Send";
import { useContext } from "react";
import MensajesContext from "../../contextos/MensajesContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import styled from "@emotion/styled";
import { useState } from "react";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

const PageConfirmarPedido = (props) => {
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } = useContext(
    MensajesContext
  );

  let navigate = useNavigate();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    // display: "block",
    // overflow: "auto",
  }));

  const [pedido, setPedido] = useState([]);
  const [materialesSeleccionados, setMaterialesSeleccionados] = useState([]);

  useEffect(() => {
    traer_pedido();
  }, []);

  const traer_pedido = async () => {
    const API_ENDPOINT = `https://backend.bimtracker.com/api_pedidos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_UN_PEDIDO_PARA_CONFIRMAR",
        codigoPedido: props.codigoPedido,
        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.pedido) {
      console.log(respuesta.result.pedido);
      setPedido(respuesta.result.pedido);
    } else {
      console.log("NO TRAE NADA EN traer_pedido()");
      // setState([]);
    }
  };

  const handleCheck = (estado, mat) => {
    // console.log(estado);
    // console.log(mat);

    setMaterialesSeleccionados([
      ...materialesSeleccionados,
      {
        idMaterial: mat.IDMaterial,
        material: mat.Material,
        unidad: mat.Unidad,
        cantidad: mat.Cantidad,
        estado: estado,
      },
    ]);

    // materialesSeleccionados.filter(
    //   (material) => material.idMaterial === mat.IDMaterial
    // );

    // console.log(materialesSeleccionados);
  };

  return (
    <>
      <div>
        <AlertValidConfig
          mostrarMensaje={mostrarMensaje}
          mensaje={mensaje.text}
          tipo={mensaje.tipo}
        />
        <Box
          sx={{
            display: "flex",
            // flexWrap: "wrap",
            alignContent: "center",
            justifyContent: "center",
            p: 1,
            m: 1,
            mt: 2,
            // border: 1,
            bgcolor: "background.paper",
            // maxWidth: 380,
            // width: { xs: 300, md: 400 },
            height: "auto",
            borderRadius: 1,
          }}
        >
          <Item
            sx={{
              width: {
                xs: "99%",
                md: "80%",
                lg: "60%",
                xl: "50%",
                // height: "550px",
                justifyContent: "center",
                textAlign: "center",
              },
            }}
            style={{
              height: "auto",
            }}
          >
            <EncabezadoItems
              setMostrar={props.setMostrar}
              titulo="CONFIRMAR PEDIDO"
              pageBack="FORMULARIO_LOGIN"
            />
            <Box
              component="form"
              sx={{
                height: 40,
                width: "100%",
                // border: 1,
                marginTop: 2,
                justifyContent: "center",
                alignItems: "center",
                // border: "1px solid #FF0000",
              }}
            >
              {pedido.map((item, index) => (
                <div
                  key={item.IDPedidoMaterial}
                  style={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    // border: "1px solid #FF0000",
                  }}
                >
                  {
                    //  <Checkbox
                    //   id={item.IDMaterial}
                    //   key={item.IDMaterial}
                    //   icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
                    //   checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                    // // checked={seleccionados.some(
                    // //   (item) => item.IDDocumentacion == docs.IDDocumentacion
                    // // )}
                    //   onChange={(event) => handleChange(event, item)}
                    // />
                  }

                  <Item
                    // sx={{ width: { xs: 300, md: 500 } }}
                    sx={{ width: { xs: "90%", textAlign: "center" } }}
                    style={{
                      marginBottom: "15px",
                      padding: "15px",
                      //   paddingRight: "5px",

                      fontSize: "22px",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <Grid
                      container
                      style={{
                        // border: "1px solid #000000",
                        marginBottom: "10px",
                        justifyContent: "center",
                        // border: "1px solid #FF0000",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{
                          padding: "15px",
                          paddingRight: "5px",
                          fontSize: "22px",
                          textAlign: "left",
                        }}
                      >
                        {item.Cantidad +
                          " " +
                          item.Unidad +
                          " de " +
                          item.Material}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={10}
                        md={4}
                        lg={4}
                        xl={4}
                        style={{
                          padding: "15px",
                          paddingRight: "5px",
                        }}
                      >
                        {console.log(
                          materialesSeleccionados.filter(
                            (material) =>
                              material.idMaterial === item.IDMaterial
                          )
                        )}
                        <Chip
                          variant="outlined"
                          color="success"
                          icon={
                            materialesSeleccionados.filter(
                              (material) =>
                                material.idMaterial === item.IDMaterial
                            ).estado === "COMPLETA" ? (
                              <RadioButtonUncheckedIcon sx={{ fontSize: 35 }} />
                            ) : (
                              <CheckCircleOutlinedIcon sx={{ fontSize: 35 }} />
                            )
                          }
                          // iconChecked={
                          //   <CheckCircleOutlinedIcon sx={{ fontSize: 35 }} />
                          // }
                          label="Entrega completa"
                          onClick={() => handleCheck("COMPLETA", item)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                        style={{
                          padding: "15px",
                          paddingRight: "5px",
                        }}
                      >
                        <Chip
                          variant="outlined"
                          color="warning"
                          icon={
                            <RadioButtonUncheckedIcon sx={{ fontSize: 35 }} />
                          }
                          iconChecked={
                            <CheckCircleOutlinedIcon sx={{ fontSize: 35 }} />
                          }
                          label="Entrega parcial"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                        style={{
                          padding: "15px",
                          paddingRight: "5px",
                        }}
                      >
                        <Chip
                          variant="outlined"
                          color="error"
                          icon={
                            <RadioButtonUncheckedIcon sx={{ fontSize: 35 }} />
                          }
                          iconChecked={
                            <CheckCircleOutlinedIcon sx={{ fontSize: 35 }} />
                          }
                          label="Sin stock"
                        />
                      </Grid>
                    </Grid>
                  </Item>
                </div>

                // <Grid item xs={9} sm={9} md={9} lg={10}>
                //   <Stack spacing={1} sx={{ width: "95%" }}>
                //     {item.Material}
                //   </Stack>
                // </Grid>
              ))}

              <br />
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                // onClick={() => validarFormulario()}
              >
                Confirmar
              </Button>
            </Box>
          </Item>
        </Box>
      </div>
    </>
  );
};

export default PageConfirmarPedido;
