import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";

import MensajesContext from "../../contextos/MensajesContext";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";

import ModalInfo from "./ModalInfo";
import ModuloSeleccionable from "./ModuloSeleccionable";
import AlertValidConfig from "../../components/AlertValidConfig";
import { useNavigate } from "react-router-dom";
import MetodoDePago from "./MetodoDePago";
import { desencriptar } from "../../funciones/Funciones";
import EncabezadoItems from "../../components/EncabezadoItems";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "140px",
}));

const PagePago = (props) => {
  useEffect(() => {
    props.setTitulo("Pago");
    props.setPagina("");

    // console.log("A");
  }, [props.setTitulo]);

  const [user, setUser] = useState([]);
  const [modulosActivos, setModulosActivos] = useState([]);

  useEffect(() => {
    let objregusu = desencriptar(localStorage.getItem("objregusu"));
    setUser(objregusu);

    // console.log("PASA POR USEEFFECT");
    // console.log(objregusu);

    let arrmods = desencriptar(localStorage.getItem("arrmods"));
    setModulosActivos(arrmods);
  }, []);

  const initMercadoPago = {
    tipoSuscripcion: "1_LICENCIA_PAGO_MENSUAL",
    link:
      "https://www.mercadopago.com/mla/debits/new?preapproval_plan_id=2c93808476b365f00176b4dc0f60049d",
  };

  const [mercadoPago, setMercadoPago] = useState(initMercadoPago);

  const {
    mostrarMensaje,
    setMostrarMensaje,
    mensaje,
    setMensaje,
    mostrarModal,
    setMostrarModal,
  } = useContext(MensajesContext);

  let navigate = useNavigate();

  // const chequearCuponIngresado = async () => {
  //   try {
  //     if (modulosActivos.length > 0) {
  //       const API_ENDPOINT = `https://backend.bimtracker.com/api_usuarios.php`;

  //       // var base64 = require("base-64");

  //       // let headers = new Headers();
  //       // // headers.append('Content-Type', 'text/json');
  //       // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

  //       const datos = await fetch(API_ENDPOINT, {
  //         method: "POST",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           operacion: "CHEQUEAR_CUPON_INGRESADO",
  //           idUsuario: user.IDUsuario,
  //           codigoCupon: cupon.codigo,
  //           modulosActivos: modulosActivos,
  //         }),

  //         // headers: headers,
  //         //credentials: 'user:passwd'
  //       });

  //       const cupones = await datos.json();

  //       // console.log(usuarios.result.usuario);

  //       if (cupones.result.cuponValido) {
  //         setCupon({ ...cupon, valid: true });

  //         setMensaje({
  //           text: "Tu registro se completó con éxito.",
  //           tipo: "success",
  //         });
  //         setMostrarMensaje(true);

  //         localStorage.removeItem("objobra");
  //         localStorage.removeItem("arrmods");
  //         localStorage.removeItem("objregusu");

  //         setTimeout(() => {
  //           props.setMostrar("FORMULARIO_LOGIN");
  //           navigate("/");
  //         }, 4000);
  //       } else {
  //         setMensaje({ text: cupones.result.error, tipo: "error" });
  //         setMostrarMensaje(true);

  //         setCupon({ ...cupon, valid: false });
  //       }

  //       return true;
  //     } else {
  //       setMensaje({ text: "Seleccione al menos un módulo", tipo: "error" });
  //       setMostrarMensaje(true);
  //     }
  //   } catch (error) {
  //     setCupon({ ...cupon, valid: false });
  //     console.error(error);
  //   }

  //   return "ERROR";
  // };

  const pagoConMercadoPago = () => {
    // www.mercadopago.com.ar/developers/es/docs/checkout-pro/requirements#editor_2

    // try {
    // location.href = "https://www.mercadopago.com/mla/debits/new?preapproval_plan_id=2c93808476b365f00176b4dc0f60049d";

    // if (modulosActivos.length > 0) {
    //   const API_ENDPOINT = `https://backend.bimtracker.com/api_usuarios.php`;

    //   // var base64 = require("base-64");

    //   // let headers = new Headers();
    //   // // headers.append('Content-Type', 'text/json');
    //   // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

    //   const datos = await fetch(API_ENDPOINT, {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       operacion: "CHEQUEAR_CUPON_INGRESADO",
    //       idUsuario: user.IDUsuario,
    //       codigoCupon: cupon.codigo,
    //       modulosActivos: modulosActivos,
    //     }),

    //     // headers: headers,
    //     //credentials: 'user:passwd'
    //   });

    //   const cupones = await datos.json();

    //   // console.log(usuarios.result.usuario);

    //   if (cupones.result.cuponValido) {
    https: //     setCupon({ ...cupon, valid: true });

    //     setMensaje({
    //       text: "Tu registro se completó con éxito.",
    //       tipo: "success",
    //     });
    //     setMostrarMensaje(true);

    //     localStorage.removeItem("objobra");
    //     localStorage.removeItem("arrmods");
    //     localStorage.removeItem("objregusu");

    //     setTimeout(() => {
    //       props.setMostrar("FORMULARIO_LOGIN");
    //       navigate("/");
    //     }, 4000);
    //   } else {
    //     setMensaje({ text: cupones.result.error, tipo: "error" });
    //     setMostrarMensaje(true);

    //     setCupon({ ...cupon, valid: false });
    //   }

    //   return true;
    // } else {
    //   setMensaje({ text: "Seleccione al menos un módulo", tipo: "error" });
    //   setMostrarMensaje(true);
    // }
    // } catch (error) {
    //   setCupon({ ...cupon, valid: false });
    //   console.error(error);
    // }

    return "ERROR";
  };

  const handleBack = () => {
    props.setMostrar("FORMULARIO_SELECCIONAR_MODULOS");
    navigate("/seleccionarModulos");
  };

  return (
    <div>
      <ModalInfo
        mostrarModal={mostrarModal}
        setMostrarModal={setMostrarModal}
        // info={info}
        // setInfo={setInfo}
      />
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />

      <Box
        sx={{
          display: "flex",
          // flexWrap: "wrap",
          alignContent: "center",
          justifyContent: "center",
          p: 1,
          m: 1,
          mt: 2,
          // border: 1,
          bgcolor: "background.paper",
          // maxWidth: 380,
          // width: { xs: 300, md: 400 },
          height: "auto",
          borderRadius: 1,
        }}
      >
        <Item
          sx={{
            width: { xs: 350, md: 550, lg: 800 },
            height: { xs: 1300, md: 600 },
          }}
        >
          <EncabezadoItems
            setMostrar={props.setMostrar}
            titulo="PAGO"
            pageBack="FORMULARIO_SELECCIONAR_MODULOS"
          />
          <br />
          {/* <br />
          <div>
            <ButtonGroup
              disableElevation
              variant="contained"
              aria-label="Disabled elevation buttons"
            >
              <Button onClick={() => handlePago("ANUAL")}>Anual</Button>
              <Button onClick={() => handlePago("MENSUAL")}>Mensual</Button>
            </ButtonGroup>
          </div> */}
          <br />
          <Box
            component="form"
            sx={{
              height: 40,
              width: "100%",
              // border: 1,
              marginTop: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
                // sx={{
                //   border: 1,
                // }}
              >
                <MetodoDePago
                  origen="REGISTRO"
                  metodo="CUPON"
                  setMostrar={props.setMostrar}
                  setMostrarMensaje={setMostrarMensaje}
                  setMensaje={setMensaje}
                  // cupon={cupon}
                  // setCupon={setCupon}
                  // user={user}
                  // modulosActivos={modulosActivos}
                />
                {/* <MetodoDePago
                  metodo="MERCADO_PAGO"
                  mercadoPago={mercadoPago}
                  setMercadoPago={setMercadoPago}
                /> */}
                <MetodoDePago
                  origen="REGISTRO"
                  metodo="SOLICITAR_INVITACION"
                  setMostrar={props.setMostrar}
                  setMostrarMensaje={setMostrarMensaje}
                  setMensaje={setMensaje}
                  // user={user}
                  // modulosActivos={modulosActivos}

                  // cupon={cupon}
                  // setCupon={setCupon}
                />
                {/* <Button onClick={() => pagoConMercadoPago()} /> */}
              </Grid>
            </Box>
            {/* <br />
            <br />
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              onClick={() => chequearCuponIngresado()}
            >
              Confirmar
            </Button> */}
          </Box>
        </Item>
      </Box>
    </div>
  );
};

export default PagePago;
