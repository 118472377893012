import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import ObraContext from "../../contextos/ObraContext";
import { numberWithCommas } from "../../funciones/Funciones";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const BoxResumenPresupuesto = () => {
  const { obra, setObra } = useContext(ObraContext);

  const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    backgroundColor: "primary.main",
    color: "primary.contrastText",
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    // color: theme.palette.text.secondary,
  }));

  return (
    <Box
      sx={{
        width: "100%",
        // height: "auto",
        backgroundColor: "primary.dark",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        p: 2,
        color: "#FFFF",
        flexGrow: 1,
        display: "flex",

        // "&:hover": {
        //   backgroundColor: "primary.main",
        //   opacity: [0.9, 0.8, 0.7],
        // },
      }}
    >
      <Grid container spacing={2} sx={{ justifyContent: "flex-end" }}>
        <Grid
          item
          xs={2}
          //   m={1}
          //   p={2}

          mt={2}
          mr={2}
          pr={2}
          pb={2}
          sx={{
            bgcolor: "primary.main",
            color: "primary.contrastText",
          }}
        >
          {/* <Item> */}
          <Typography component="div">
            <Box
              sx={{
                fontWeight: "normal",
                fontSize: 24,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {numberWithCommas(
                "$ " + Math.round(obra.SubtotalMateriales * 100) / 100
              )}
            </Box>
            <Box
              sx={{ fontSize: 16, display: "flex", justifyContent: "center" }}
            >
              MATERIALES
            </Box>
          </Typography>
          {/* </Item> */}
        </Grid>
        <Grid
          item
          xs={2}
          //   m={1}
          //   p={2}

          mt={2}
          mr={2}
          pr={2}
          pb={2}
          sx={{
            bgcolor: "primary.main",
            color: "primary.contrastText",
          }}
        >
          {/* <Item> */}
          <Typography component="div">
            <Box
              sx={{
                fontWeight: "normal",
                fontSize: 24,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {numberWithCommas(
                "$ " + Math.round(obra.SubtotalTrabajadores * 100) / 100
              )}
            </Box>
            <Box
              sx={{ fontSize: 16, display: "flex", justifyContent: "center" }}
            >
              MANO DE OBRA
            </Box>
          </Typography>
          {/* </Item> */}
        </Grid>
        <Grid
          item
          xs={2}
          //   m={1}
          //   p={2}

          mt={2}
          mr={2}
          pr={2}
          pb={2}
          sx={{
            bgcolor: "primary.main",
            color: "primary.contrastText",
          }}
        >
          {/* <Item> */}
          <Typography component="div">
            <Box
              sx={{
                fontWeight: "normal",
                fontSize: 24,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {numberWithCommas("$ " + Math.round(obra.Total * 100) / 100)}
            </Box>
            <Box
              sx={{ fontSize: 16, display: "flex", justifyContent: "center" }}
            >
              TOTAL
            </Box>
          </Typography>
          {/* </Item> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BoxResumenPresupuesto;
