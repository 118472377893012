import React, { useContext, useEffect, useState } from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, ButtonGroup, Checkbox } from "@mui/material";
import HySContext from "../../contextos/HySContext";
import ObraContext from "../../contextos/ObraContext";
import Titulo from "../../components/Titulo";
import { chequearPermisos, desencriptar } from "../../funciones/Funciones";
import BotoneraABM from "../../components/BotoneraABM";
import PrivilegioContext from "../../contextos/PrivilegiosContext";

const PageSettingsSociedad = (props) => {
  const [mostrarProgress, setMostrarProgress] = useState(false);
  const [seleccionados, setSeleccionados] = useState([]);

  const { mostrarAlert, setMostrarAlert } = useContext(PrivilegioContext);

  const {
    documentos,
    setDocumentos,
    asignaciones,
    setAsignaciones,
    modificar,
    setModificar,
  } = useContext(HySContext);

  const { obra, setObra } = useContext(ObraContext);

  useEffect(() => {
    props.setTitulo("Sociedades y subcontratos");
    props.setPagina("");

    // console.log(obra.IDObra);
    // console.log(modificar.IDSubcontrato);

    if (documentos.IDDocumentacion === undefined) {
      // console.log("PASA POR IDDocumentacion");
      let objdocs = localStorage.getItem("objdocs");
      setDocumentos(desencriptar(objdocs));
    }

    if (obra.IDObra === undefined) {
      // console.log("PASA POR IDObra");
      let objobra = localStorage.getItem("objobra");
      setObra(desencriptar(objobra));
    }

    if (modificar.IDSubcontrato === undefined) {
      // console.log("PASA POR IDSubcontrato");
      let objsoc = localStorage.getItem("objsoc");
      setModificar(desencriptar(objsoc));
    }
  }, []);

  useEffect(() => {
    listar_asignaciones();
  }, [documentos, obra, modificar]);

  const listar_asignaciones = async () => {
    // console.log(lista);

    setMostrarProgress(true);

    // console.log("IDObra: " + obra.IDObra);
    // console.log("IDSubcontrato: " + modificar.IDSubcontrato);

    const API_ENDPOINT = `https://backend.bimtracker.com/api_hys.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_ASIGNACIONES",
        idObra: obra.IDObra,
        idSubcontrato: modificar.IDSubcontrato,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.asignaciones) {
      // console.log(respuesta.result.asignaciones);
      // setAsignaciones(respuesta.result.asignaciones);
      setSeleccionados(respuesta.result.asignaciones);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN: listar_asignaciones()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  const handleChange = async (event, item) => {
    // console.log(item);

    // console.log("IDObra: " + obra.IDObra);
    // console.log("IDSubcontrato: " + modificar.IDSubcontrato);
    // console.log("IDDocumentacion: " + item.IDDocumentacion);
    // console.log("checked: " + event.target.checked);

    if (
      chequearPermisos(obra.Privilegios, "DOCUMENTACION_ASIGN_DOCS_EN_OBRA") ===
      true
    ) {
      const API_ENDPOINT = `https://backend.bimtracker.com/api_hys.php`;

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // },
        body: JSON.stringify({
          operacion: event.target.checked
            ? "ALTA_ASIGNACION"
            : "BAJA_ASIGNACION",
          idObra: obra.IDObra,
          idSubcontrato: modificar.IDSubcontrato,
          idDocumentacion: item.IDDocumentacion,
          fechaImpreso: "",
          fechaVencimiento: "",
        }),
      });
      const respuesta = await datos.json();

      // console.log("PASA POR 1");
      // console.log(respuesta.result.asignaciones);
      // console.log("PASA POR 2");

      if (respuesta.result.asignaciones) {
        // console.log(respuesta.result.asignaciones);
        setAsignaciones(respuesta.result.asignaciones);
        setSeleccionados(respuesta.result.asignaciones);

        // alert("Lista de precios actualizada.");
      } else {
        setAsignaciones([]);
        setSeleccionados([]);
        console.log("NO TRAE NADA EN: handeChange()");
        // setState([]);
      }
    } else {
      setMostrarAlert(true);
    }
  };

  // console.log(documentos);
  // console.log(asignaciones);

  return (
    <div>
      <Titulo
        texto={modificar.RazonSocial}
        botones={{
          seccion: "SETTINGS-SOCIEDAD",
          alta: false,
          baja: true,
          modif: true,
        }}
      />

      <div style={{ marginBottom: "30px", fontSize: "20px" }}>
        Seleccione la documentación requerida:
      </div>

      {documentos.map((docs) => (
        <div key={docs.IDDocumentacion}>
          <Checkbox
            id={docs.IDDocumentacion}
            key={docs.IDDocumentacion}
            icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
            checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
            // checked={asignaciones.some(
            //   (check) => check.IDDocumentacion == docs.IDDocumentacion
            // )}

            checked={seleccionados.some(
              (item) => item.IDDocumentacion == docs.IDDocumentacion
            )}
            onChange={(event) => handleChange(event, docs)}
          />
          {docs.Documento.toUpperCase()}
        </div>
      ))}
    </div>
  );
};

export default PageSettingsSociedad;
