import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles"; // "@mui/styles";
import Botonera from "../../components/Botonera";
import { Grid, Typography } from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  styled,
  ThemeProvider,
} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ObraContext, { ObraProvider } from "../../contextos/ObraContext";
import { useContext } from "react";
import PresupuestoContext, {
  PresupuestoProvider,
} from "../../contextos/PresupuestosContext";
import {
  desencriptar,
  encriptar,
  numberWithCommas,
} from "../../funciones/Funciones";
import UsuarioContext from "../../contextos/UsuarioContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  linkStyle: {
    color: "white",
    textDecoration: "none",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const PagePresupuestos = (props) => {
  const classes = useStyles();

  const [listadoObras, setListadoObras] = useState([]);
  const { obra, setObra } = useContext(ObraContext);
  const [usuario, setUsuario] = useState(UsuarioContext);
  // const { presupuesto, setPresupuesto } = useContext(PresupuestoContext);

  useEffect(() => {
    props.setTitulo("Administración de presupuestos");
    props.setPagina("altaPresupuesto");

    listar_obras();
  }, [props.setTitulo, obra]);

  useEffect(() => {
    setObra({});
  }, []);

  useEffect(() => {
    if (usuario.IDUsuario === undefined) {
      // console.log("PASA POR IDObra");
      let objusu = desencriptar(localStorage.getItem("objusu"));
      setUsuario(objusu);
      // console.log(objusu);
    } else {
      // console.log(usuario);
    }
  }, []);

  // const obras = [
  //   { IDObra: 1, Obra: "Palestina 714" },
  //   { IDObra: 2, Obra: "Lavalleja 714" },
  // ];

  let theme = createTheme({
    typography: {
      h5: {
        fontWeight: 800,
      },
    },
  });
  theme = responsiveFontSizes(theme);

  let navigate = useNavigate();

  const handleEntrar = (obraClick) => {
    // let objobra = encriptar(obraClick);
    // localStorage.setItem("objobra", objobra);

    setObra(obraClick);

    // console.log(obra);
    // console.log(obraClick);

    let path = `/presupuestoActual`;
    navigate(path);
    // navigation.navigate("importarListaPrecios");
  };

  const listar_obras = async () => {
    // console.log(texto);
    console.log("PASA POR listar_obras()");
    const API_ENDPOINT = `https://backend.bimtracker.com/api_presupuestos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_OBRAS_CON_COSTO_TOTAL",
        idUsuario: usuario.IDUsuario,
        // operacion: "LISTAR_PRESUPUESTO",
        //  idObra: obra.IDObra,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.obras) {
      // console.log(respuesta.result.obras);

      setListadoObras(respuesta.result.obras);
    } else {
      setListadoObras([]);
      console.log("NO TRAE NADA EN listar_obras()");
      // setState([]);
    }
  };

  return (
    <div>
      {/* <Botonera />
      <br />
      <br /> */}
      <Box sx={{ flexGrow: 1 }}>
        <ThemeProvider theme={theme}>
          <Grid container spacing={2}>
            {listadoObras.map((obraRow) => (
              <Grid item xs={2} key={obraRow.IDObra}>
                <Item>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5">{obraRow.Obra}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        ${" "}
                        {numberWithCommas(
                          Math.round(obraRow.Total * 100) / 100
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleEntrar(obraRow)}
                      >
                        Entrar
                      </Button>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
            ))}
          </Grid>
        </ThemeProvider>
      </Box>
    </div>
  );
};

export default PagePresupuestos;
