import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { Button, createTheme, useTheme } from "@mui/material"; // "@mui/material/styles";
import Drawer from "@mui/material/Drawer"; // "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline"; //"@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar"; // "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar"; // "@mui/material/Toolbar";
import List from "@mui/material/List"; // "@mui/material/List";
import Typography from "@mui/material/Typography"; // "@mui/material/Typography";
import Divider from "@mui/material/Divider"; // "@mui/material/Divider";
import IconButton from "@mui/material/IconButton"; // "@mui/material/IconButton";
// import MenuIcon from "@mui/material/Menu"; // "@mui/icons-material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"; // "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight"; // @mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem"; // "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon"; // "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText"; // "@mui/material/ListItemText";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"; // "@mui/icons-material/HomeOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined"; // "@mui/icons-material/ShoppingCartOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined"; // "@mui/icons-material/LocalShippingOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"; // "@mui/icons-material/DescriptionOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined"; // "@mui/icons-material/ExitToAppOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";
import AddIcon from "@mui/icons-material/Add"; // "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import { makeStyles } from "@mui/styles"; // "@mui/styles";
import UsuarioContext from "../contextos/UsuarioContext";
import { chequearPermisos, desencriptar } from "../funciones/Funciones";

import { Routes, Route, Link, useNavigate } from "react-router-dom";

import PageDashboardMateriales from "../pages/materiales/PageDashboardMateriales";
import PageDashboard from "../pages/dashboard/PageDashboard";
import NoPage from "./NoPage";
import PageSupervisarPedido from "../pages/pedidos/PageSupervisarPedido";
import PageAltaPedido from "../pages/pedidos/PageAltaPedido";
import PageDashboardVolquetes from "../pages/volquetes/PageDashboardVolquetes";

import PagePresupuestos from "../pages/presupuestos/PagePresupuestos";
import PageImportarListaPrecios from "../pages/presupuestos/PageImportarListaPrecios";
import PageListadoDeTareas from "../pages/presupuestos/PageListadoDeTareas";
import PageImportarCuantificacion from "../pages/presupuestos/PageImportarCuantificacion";
import PageImportarTareas from "../pages/presupuestos/PageImportarTareas";
import PagePresupuestoActual from "../pages/presupuestos/PagePresupuestoActual";
import { PresupuestoProvider } from "../contextos/PresupuestosContext";
import PageListaPrecios from "../pages/presupuestos/PageListaPrecios";
import PageLogin from "../pages/login/PageLogin";
import { HySProvider } from "../contextos/HySContext";
import PageDashboardHyS from "../pages/dashboard/PageDashboardHyS";
import PageSociedad from "../pages/hys/PageSociedad";
import PageDocumento from "../pages/hys/PageDocumento";
import PageSelectObra from "../pages/general/PageSelectObra";
import PageSettingsSociedad from "../pages/hys/PageSettingsSociedad";
import PageSettingsDocumento from "../pages/hys/PageSettingsDocumento";
import PageAltaSociedad from "../pages/hys/PageAltaSociedad";
import PageModifSociedad from "../pages/hys/PageModifSociedad";
import PageAltaDocumento from "../pages/hys/PageAltaDocumento";
import PageModifDocumento from "../pages/hys/PageModifDocumento";
import PageDashboardPedidos from "../pages/pedidos/PageDashboardPedidos";
import PageObraConfig from "../pages/configuracion/PageObraConfig";
import ObraContext from "../contextos/ObraContext";
import PageDashboardGeneral from "../pages/dashboard/PageDashboardGeneral";
import PageDashboardPresupuestos from "../pages/dashboard/PageDashboardPresupuestos";
import PageRegistro from "../pages/general/PageRegistro";
import PageOlvideClave from "../pages/general/PageOlvideClave";
import PageRecuperarClave from "../pages/general/PageRecuperarClave";
import PageSeleccionarModulos from "../pages/general/PageSeleccionarModulos";
import PagePago from "../pages/general/PagePago";
import PageAltaObra from "../pages/obras/PageAltaObra";
import PageAltaVolquetes from "../pages/volquetes/PageAltaVolquetes";
import PedidoContext, { PedidoProvider } from "../contextos/PedidoContext";
import PageVerificarPedido from "../pages/pedidos/PageVerificarPedido";
import { OrdenesDeServicioProvider } from "../contextos/OrdenesDeServicioContext";
import PageDashboardOrdenesDeServicio from "../pages/ordenesDeServicio/PageDashboardOrdenesDeServicio";
import PageAltaOrdenDeServicio from "../pages/ordenesDeServicio/PageAltaOrdenDeServicio";
import WebcamPicture from "../pages/ordenesDeServicio/WebcamPicture";
import WebcamVideo from "../pages/ordenesDeServicio/WebcamVideo";
import PageAgregarDescripcion from "../pages/ordenesDeServicio/PageAgregarDescripcion";
import PageAgregarDestinatarios from "../pages/ordenesDeServicio/PageAgregarDestinatarios";
import PageAgregarPrioridad from "../pages/ordenesDeServicio/PageAgregarPrioridad";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  titulo: {
    flexGrow: 1,
  },
  texto: {
    color: "#323232",
  },
  textoSeleccionado: {
    color: "#5052ff",
  },
  noDecoration: {
    textDecoration: "none",
  },
}));

const iconoApropiado = (text) => {
  switch (text) {
    case "Inicio":
      return (
        <Link to="/dashboard">
          <HomeOutlinedIcon />
        </Link>
      );

    case "Pedidos":
      return (
        <Link to="/dashboardPedidos">
          <HomeOutlinedIcon />
        </Link>
      );

    case "Materiales":
      return <ShoppingCartOutlinedIcon />;

    case "Volquetes":
      return <LocalShippingOutlinedIcon />;

    case "Documentación":
      return <DescriptionOutlinedIcon />;

    default:
      break;
  }
};

// const useStyles = createTheme((theme) => ({
//   titulo: {
//     flexGrow: 1,
//   },
//   texto: {
//     color: "#323232",
//   },
//   textoSeleccionado: {
//     color: "#5052ff",
//   },
//   noDecoration: {
//     textDecoration: "none",
//   },
// }));

export default function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const { obra, setObra } = useContext(ObraContext);
  const { usuario, setUsuario, tokenValido, setTokenValido } = useContext(
    UsuarioContext
  );

  const [privilegios, setPrivilegios] = useState(usuario.Privilegios);

  useEffect(() => {
    let objobra = desencriptar(localStorage.getItem("objobra"));

    setObra(objobra);
    alert(usuario.Privilegios);
    // var esta = chequearPermisos("PRESUPUESTOS");

    // privilegios.map((x) => x.privilegios === "MATERIALES") !== -1
    //   ? alert("ESTÁ")
    //   : alert("NO ESTÁ");
  }, []);

  // const chequearPermisos = (permiso) => {
  //   var filterData = privilegios.split("|");

  //   var encontrado = false;

  //   filterData.map((item) => {
  //     console.log("|" + item + "|");
  //     if (item === permiso) {
  //       encontrado = true;
  //     }
  //   });

  //   return encontrado;
  // };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAltaPedido = () => {
    alert("ALTA PEDIDO");
  };

  const cerrarSesion = () => {
    setUsuario({ Logueado: "NO" });
    setTokenValido(false);

    // localStorage.removeItem("tok1");
    // localStorage.removeItem("tok2");

    // if (window.ReactNativeWebView) {
    //   // SI ESTOY CON LA APP DESDE EL WEBVIEW
    // } else {
    localStorage.removeItem("objusu");
    localStorage.removeItem("objobra");
    localStorage.removeItem("objsoc");
    localStorage.removeItem("objdocs");
    // }

    // localStorage.removeItem("fechaTok");

    navigate("/login");
  };

  const cambiarObra = () => {
    // if (window.ReactNativeWebView) {
    //   // SI ESTOY CON LA APP DESDE EL WEBVIEW
    // } else {
    localStorage.removeItem("objobra");
    // }

    setObra("");

    navigate("/dashboardObras");
  };

  let navigate = useNavigate();

  const [titulo, setTitulo] = useState("");
  const [pagina, setPagina] = useState("");

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <IconButton aria-label="volver" size="large">
            <ArrowBackIosNewOutlinedIcon
              fontSize="inherit"
              sx={{ color: "#FFFFFF" }}
              onClick={() => navigate(-1)}
            />
          </IconButton>
          &nbsp;
          <Typography variant="h6" className={classes.titulo} noWrap>
            {/* Administración de pedidos */}
            {titulo}
          </Typography>
          <IconButton
            color="inherit"
            // onClick={handleAltaPedido}
            aria-label="open alta"
            size="large"
          >
            {/* {pagina !== "" ? (
              pagina === "altaObra" &&
              chequearPermisos(privilegios, "DOCUMENTACION_ALTA_OBRA") ===
                true ? (
                <AddIcon
                  fontSize="large"
                  onClick={() => navigate("/" + pagina)}
                />
              ) : (
                ""
              )
            ) : (
              ""
            )} */}

            {pagina !== "" ? (
              <AddIcon
                fontSize="large"
                onClick={() => navigate("/" + pagina)}
              />
            ) : (
              ""
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          {obra.Obra !== undefined ? (
            <div style={{ fontWeight: "bold", flex: 1 }}>
              {obra.Obra.toUpperCase()}{" "}
              {usuario.IDUsuario !== undefined ? (
                <div
                  style={{
                    justifyContent: "center",
                    fontSize: "12px",
                    flex: 1,
                    fontWeight: "normal",
                  }}
                >
                  {usuario.Nombre.toUpperCase() +
                    " " +
                    usuario.Apellido.toUpperCase()}{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : usuario.IDUsuario !== undefined ? (
            <div
              style={{
                justifyContent: "center",
                fontSize: "12px",
                flex: 1,
                fontWeight: "normal",
              }}
            >
              {usuario.Nombre.toUpperCase() +
                " " +
                usuario.Apellido.toUpperCase()}{" "}
            </div>
          ) : (
            ""
          )}
          <IconButton onClick={handleDrawerClose} size="large">
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>

        <Divider />

        {obra.Privilegios !== undefined ? (
          <>
            <List>
              {chequearPermisos(privilegios, "DASHBOARD") === true ? (
                <ListItem button key="dashboard">
                  <Link to="/dashboard" className={classes.noDecoration}>
                    <ListItemIcon>
                      <HomeOutlinedIcon />
                    </ListItemIcon>
                  </Link>
                  <Link to="/dashboard" className={classes.noDecoration}>
                    <ListItemText
                      primary={
                        <Typography style={{ color: "#323232" }}>
                          Inicio
                        </Typography>
                      }
                    />
                  </Link>
                </ListItem>
              ) : (
                ""
              )}

              {chequearPermisos(privilegios, "ORDENES_DE_SERVICIO") === true ? (
                <ListItem button key="dashboardOrdenesDeServicio">
                  <Link
                    to="/dashboardOrdenesDeServicio"
                    className={classes.noDecoration}
                  >
                    <ListItemIcon>
                      <EngineeringOutlinedIcon />
                    </ListItemIcon>
                  </Link>
                  <Link
                    to="/dashboardOrdenesDeServicio"
                    className={classes.noDecoration}
                  >
                    <ListItemText
                      primary={
                        <Typography style={{ color: "#323232" }}>
                          Ordenes de Servicio
                        </Typography>
                      }
                    />
                  </Link>
                </ListItem>
              ) : (
                ""
              )}

              {chequearPermisos(privilegios, "PEDIDOS") === true ? (
                <ListItem button key="dashboardPedidos">
                  <Link to="/dashboardPedidos" className={classes.noDecoration}>
                    <ListItemIcon>
                      <ShoppingCartOutlinedIcon />
                    </ListItemIcon>
                  </Link>
                  <Link to="/dashboardPedidos" className={classes.noDecoration}>
                    <ListItemText
                      primary={
                        <Typography style={{ color: "#323232" }}>
                          Pedidos
                        </Typography>
                      }
                    />
                  </Link>
                </ListItem>
              ) : (
                ""
              )}

              {chequearPermisos(privilegios, "MATERIALES") === true ? (
                <ListItem button key="materiales">
                  <Link to="/materiales" className={classes.noDecoration}>
                    <ListItemIcon>
                      <BubbleChartOutlinedIcon />
                    </ListItemIcon>
                  </Link>
                  <Link to="/materiales" className={classes.noDecoration}>
                    <ListItemText
                      primary={
                        <Typography style={{ color: "#323232" }}>
                          Materiales
                        </Typography>
                      }
                    />
                  </Link>
                </ListItem>
              ) : (
                ""
              )}

              {chequearPermisos(privilegios, "VOLQUETES") === true ? (
                <ListItem
                  button
                  key="volquetes"
                  className={classes.noDecoration}
                >
                  <Link
                    to="/dashboardVolquetes"
                    className={classes.noDecoration}
                  >
                    <ListItemIcon>
                      <LocalShippingOutlinedIcon />
                    </ListItemIcon>
                  </Link>
                  <Link
                    to="/dashboardVolquetes"
                    className={classes.noDecoration}
                  >
                    <ListItemText
                      primary={
                        <Typography style={{ color: "#323232" }}>
                          Volquetes
                        </Typography>
                      }
                    />
                  </Link>
                </ListItem>
              ) : (
                ""
              )}

              {chequearPermisos(privilegios, "PRESUPUESTOS") === true ? (
                <ListItem
                  button
                  key="presupuestos"
                  className={classes.noDecoration}
                >
                  <Link to="/presupuestos" className={classes.noDecoration}>
                    <ListItemIcon>
                      <QueryStatsOutlinedIcon />
                    </ListItemIcon>
                  </Link>
                  <Link to="/presupuestos" className={classes.noDecoration}>
                    <ListItemText
                      primary={
                        <Typography style={{ color: "#323232" }}>
                          Presupuestos
                        </Typography>
                      }
                    />
                  </Link>
                </ListItem>
              ) : (
                ""
              )}

              {chequearPermisos(privilegios, "DOCUMENTACION") === true ? (
                <ListItem button key="documentacion">
                  <Link to="/documentacion" className={classes.noDecoration}>
                    <ListItemIcon>
                      <DescriptionOutlinedIcon />
                    </ListItemIcon>
                  </Link>
                  <Link to="/documentacion" className={classes.noDecoration}>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography style={{ color: "#323232" }}>
                          Documentación
                        </Typography>
                      }
                    />
                  </Link>
                </ListItem>
              ) : (
                ""
              )}

              {chequearPermisos(privilegios, "CONFIGURACION") === true ? (
                <ListItem button key="configuracion">
                  <Link to="/config" className={classes.noDecoration}>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                  </Link>
                  <Link to="/config" className={classes.noDecoration}>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography style={{ color: "#323232" }}>
                          Configuración
                        </Typography>
                      }
                    />
                  </Link>
                </ListItem>
              ) : (
                ""
              )}
              <br />
              {chequearPermisos(privilegios, "OBRAS") === true ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => cambiarObra()}
                  >
                    Cambiar obra
                  </Button>
                </div>
              ) : (
                ""
              )}

              {/* {["Pedidos", "Materiales", "Volquetes", "Documentación"].map(
              (text, index) => (
                <ListItem button key={text}>
                  <ListItemIcon>{iconoApropiado(text)}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              )
            )} */}
            </List>
            <Divider />
          </>
        ) : (
          ""
        )}
        <List>
          <ListItem button>
            <Link
              to="/"
              className={classes.noDecoration}
              onClick={() => cerrarSesion()}
            >
              <ListItemIcon>
                <ExitToAppOutlinedIcon />
              </ListItemIcon>
            </Link>
            {/* <ListItemText primary="Cerrar sesión" /> */}
            <Link
              to="/"
              className={classes.noDecoration}
              onClick={() => cerrarSesion()}
            >
              <ListItemText
                disableTypography
                primary={
                  <Typography style={{ color: "#323232" }}>
                    Cerrar sesión
                  </Typography>
                }
              />
            </Link>
          </ListItem>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Routes>
          <Route
            index
            path="/materiales"
            element={
              <PageDashboardMateriales
                setTitulo={setTitulo}
                setPagina={setPagina}
              />
            }
          />
          <Route
            path="/dashboard"
            element={
              <PageDashboardGeneral
                setTitulo={setTitulo}
                setPagina={setPagina}
              />
            }
          />
          <Route
            path="/dashboardObras"
            element={
              <PageSelectObra setTitulo={setTitulo} setPagina={setPagina} />
            }
          />
          <Route
            path="/dashboardOrdenesDeServicio"
            element={
              <OrdenesDeServicioProvider>
                <PageDashboardOrdenesDeServicio
                  setTitulo={setTitulo}
                  setPagina={setPagina}
                />
              </OrdenesDeServicioProvider>
            }
          />
          <Route
            path="/altaOrdenDeServicio"
            element={
              <OrdenesDeServicioProvider>
                <PageAltaOrdenDeServicio
                  setTitulo={setTitulo}
                  setPagina={setPagina}
                />
              </OrdenesDeServicioProvider>
            }
          />

          <Route
            path="/agregarArchivos"
            element={
              <OrdenesDeServicioProvider>
                <WebcamPicture
                  setTitulo={setTitulo}
                  setPagina={setPagina}
                  nroPaso={1}
                />
              </OrdenesDeServicioProvider>
            }
          />

          <Route
            path="/grabarVideo"
            element={
              <OrdenesDeServicioProvider>
                <WebcamVideo
                  setTitulo={setTitulo}
                  setPagina={setPagina}
                  nroPaso={1}
                />
              </OrdenesDeServicioProvider>
            }
          />

          <Route
            path="/agregarDescripcion"
            element={
              <OrdenesDeServicioProvider>
                <PageAgregarDescripcion
                  setTitulo={setTitulo}
                  setPagina={setPagina}
                  nroPaso={2}
                />
              </OrdenesDeServicioProvider>
            }
          />

          <Route
            path="/agregarDestinatarios"
            element={
              <OrdenesDeServicioProvider>
                <PageAgregarDestinatarios
                  setTitulo={setTitulo}
                  setPagina={setPagina}
                  nroPaso={3}
                />
              </OrdenesDeServicioProvider>
            }
          />

          <Route
            path="/agregarPrioridad"
            element={
              <OrdenesDeServicioProvider>
                <PageAgregarPrioridad
                  setTitulo={setTitulo}
                  setPagina={setPagina}
                  nroPaso={4}
                />
              </OrdenesDeServicioProvider>
            }
          />

          <Route
            path="/dashboardPedidos"
            element={
              <PedidoProvider>
                <PageDashboardPedidos
                  setTitulo={setTitulo}
                  setPagina={setPagina}
                />
              </PedidoProvider>
            }
          />
          <Route
            path="/verificarPedido"
            element={
              <PedidoProvider>
                <PageVerificarPedido
                  setTitulo={setTitulo}
                  setPagina={setPagina}
                />
              </PedidoProvider>
            }
          />
          <Route
            path="/altaPedido"
            element={
              <PedidoProvider>
                <PageAltaPedido setTitulo={setTitulo} setPagina={setPagina} />
              </PedidoProvider>
            }
          />

          <Route
            path="/dashboardVolquetes"
            element={
              <PageDashboardVolquetes
                setTitulo={setTitulo}
                setPagina={setPagina}
              />
            }
          />
          <Route
            path="/altaVolquete"
            element={
              <PageAltaVolquetes setTitulo={setTitulo} setPagina={setPagina} />
            }
          />

          <Route
            path="/presupuestoActual"
            element={
              <PagePresupuestoActual
                setTitulo={setTitulo}
                setPagina={setPagina}
              />
            }
          />
          <Route
            path="/importarTareas"
            element={
              <PageImportarTareas setTitulo={setTitulo} setPagina={setPagina} />
            }
          />
          <Route
            path="/importarListaPrecios"
            element={
              <PageImportarListaPrecios
                setTitulo={setTitulo}
                setPagina={setPagina}
              />
            }
          />
          <Route
            path="/importarCuantificacion"
            element={
              <PageImportarCuantificacion
                setTitulo={setTitulo}
                setPagina={setPagina}
              />
            }
          />
          <Route
            path="/listadoDeTareas"
            element={
              <PageListadoDeTareas
                setTitulo={setTitulo}
                setPagina={setPagina}
              />
            }
          />
          <Route
            path="/listaPrecios"
            element={
              <PageListaPrecios setTitulo={setTitulo} setPagina={setPagina} />
            }
          />
          <Route path="/login" element={<PageLogin />} />
          <Route
            path="/presupuestos"
            element={
              <PresupuestoProvider>
                {/* <PagePresupuestos setTitulo={setTitulo} setPagina={setPagina} /> */}

                <PageDashboardPresupuestos
                  setTitulo={setTitulo}
                  setPagina={setPagina}
                />
              </PresupuestoProvider>
            }
          />
          <Route
            path="/config"
            element={
              <HySProvider>
                <PageObraConfig setTitulo={setTitulo} setPagina={setPagina} />
              </HySProvider>
            }
          />
          <Route
            path="/selectObra"
            element={
              <HySProvider>
                <PageSelectObra setTitulo={setTitulo} setPagina={setPagina} />
              </HySProvider>
            }
          />
          <Route
            path="/altaObra"
            element={
              <PageAltaObra setTitulo={setTitulo} setPagina={setPagina} />
            }
          />
          <Route
            path="/documentacion"
            element={
              <HySProvider>
                <PageDashboardHyS setTitulo={setTitulo} setPagina={setPagina} />
              </HySProvider>
            }
          />
          <Route
            path="/settingsSociedad"
            element={
              <HySProvider>
                <PageSettingsSociedad
                  setTitulo={setTitulo}
                  setPagina={setPagina}
                />
              </HySProvider>
            }
          />
          <Route
            path="/settingsDocumento"
            element={
              <HySProvider>
                <PageSettingsDocumento
                  setTitulo={setTitulo}
                  setPagina={setPagina}
                />
              </HySProvider>
            }
          />
          <Route
            path="/altaSociedad"
            element={
              <HySProvider>
                <PageAltaSociedad setTitulo={setTitulo} setPagina={setPagina} />
              </HySProvider>
            }
          />
          <Route
            path="/modificarSociedad"
            element={
              <HySProvider>
                <PageModifSociedad
                  setTitulo={setTitulo}
                  setPagina={setPagina}
                />
              </HySProvider>
            }
          />
          <Route
            path="/modificarDocumentosSociedad"
            element={
              <HySProvider>
                <PageSociedad setTitulo={setTitulo} setPagina={setPagina} />
              </HySProvider>
            }
          />
          <Route
            path="/altaDocumento"
            element={
              <HySProvider>
                <PageAltaDocumento
                  setTitulo={setTitulo}
                  setPagina={setPagina}
                />
              </HySProvider>
            }
          />
          <Route
            path="/modificarDocumento"
            element={
              <HySProvider>
                <PageModifDocumento
                  setTitulo={setTitulo}
                  setPagina={setPagina}
                />
              </HySProvider>
            }
          />

          <Route
            path="/registro"
            element={
              <PageRegistro setTitulo={setTitulo} setPagina={setPagina} />
            }
          />

          <Route
            path="/seleccionarModulos"
            element={
              <PageSeleccionarModulos
                setTitulo={setTitulo}
                setPagina={setPagina}
              />
            }
          />

          <Route
            path="/pago"
            element={<PagePago setTitulo={setTitulo} setPagina={setPagina} />}
          />
          <Route
            path="/olvideClave"
            element={
              <PageOlvideClave setTitulo={setTitulo} setPagina={setPagina} />
            }
          />

          <Route
            path="/recuperarClave"
            element={
              <PageRecuperarClave setTitulo={setTitulo} setPagina={setPagina} />
            }
          />

          {obra.Privilegios !== undefined ? (
            <Route
              path="/"
              element={
                <PageDashboard setTitulo={setTitulo} setPagina={setPagina} />
              }
            />
          ) : (
            <Route
              path="/"
              element={
                <PageSelectObra setTitulo={setTitulo} setPagina={setPagina} />
              }
            />
            // <Route
            //   path="/"
            //   element={
            //     // <HySProvider>
            //     <PageSelectObra setTitulo={setTitulo} setPagina={setPagina} />
            //     // </HySProvider>
            //   }
            // />
          )}

          <Route
            path="*"
            element={<NoPage setTitulo={setTitulo} setPagina={setPagina} />}
          />
        </Routes>
      </main>
    </div>
  );
}
